import Enums from "./enums/enums";
import ServerErrors from "./errors/server-errors";

export default {
  "translations": {
    "$vuetify": {
      "noDataText": "Nincs adat",
      "dataFooter": {
        "itemsPerPageAll": "Mutasd az összeset",
        "pageText": "Hajózik:"
      },
      "dataTable": {
        "sortBy": "Rendezés",
        "ariaLabel": {
          "sortNone": "sortNincs"
        },
        "itemsPerPageText": "Oldalanként:"
      },
      "timepicker": {
        "am": "am",
        "pm": "délután"
      },
      "dataIterator": {
        "loadingText": "Adatok betöltése"
      },
      "badge": "jelvény",
      "datePicker": {
        "prevMonthAriaLabel": "Előző hónap",
        "nextMonthAriaLabel": "Következő hónap"
      }
    },
    "view": {
      "login": {
        "footer": {
          "z1": "Szervezeti szabályzat",
          "z2": "Elektronikus szolgáltatások nyújtásának szabályzata",
          "z3": "A Neurosphera adatvédelmi szabályzata",
          "z4": "A neuroterminál adatvédelmi szabályzata"
        },
        "login": {
          "loginTitle": "Bejelentkezés és időpont egyeztetés",
          "registerLinkMsg": "Regisztráció",
          "loginBtnLbl": "Jelentkezzen be a Neurospherába",
          "resetPassMsg": "Elfelejtetted a jelszavad?",
          "passwordFieldLabel": "Jelszó",
          "loginEmailLabel": "Email",
          "registerMsg": "Még nincs fiókod?"
        },
        "reset": {
          "title": "Elfelejtetted a jelszavad?",
          "subTitle": "Az alább megadott címre e-mailt küldünk az új jelszó beállításához szükséges linkkel",
          "content1": "A jelszó megváltoztatásához szükséges linket elküldtük a következő címre:",
          "content2": "Ellenőrizze e-mail postafiókját, és kattintson a linkre a jelszó esélyéért",
          "form": {
            "email": "Email",
            "send": "megerősít"
          },
          "changePassword": "Jelszó módosítása",
          "recoverPassword": "Jelszó visszaállítása",
          "response1": "Üzenetet küldtünk a megadott e-mail címre a visszaállítást megerősítő linkkel.",
          "response2": "Kérjük, zárja be ezt a böngészőablakot, és kattintson az e-mailben kapott linkre.",
          "sendAgainPrefix": "Nem kapott e-mailt:",
          "sendAgain": "Küldd újra!",
          "contactReception": "vagy forduljon a recepcióhoz:",
          "resendBlocked": "Ha nem kapott e-mailt a jelszó megváltoztatásához szükséges linkkel, kérjük, forduljon az ügyfélszolgálathoz."
        },
        "resetConfirm": {
          "title": "Erősítse meg a jelszó módosítását",
          "form": {
            "password": "Új jelszó",
            "passwordConfirm": "Jelszó megerősítése",
            "send": "megerősít"
          },
          "response": "A jelszó megváltozott.",
          "loginBtnLbl": "Belépés"
        },
        "register": {
          "title": "Üdvözöljük a Neuroterminálban",
          "subtitle1": "Hozzon létre egy fiókot, és kérjen időpontot",
          "firstName": "Keresztnév",
          "lastName": "Vezetéknév",
          "email": "Email",
          "password": "Jelszó",
          "passwordConfirm": "Jelszó megerősítése",
          "selectAll": "Mindet kiválaszt",
          "isApprove1": "pontjában foglalt személyes adataim kezelésének szabályait elolvastam",
          "isApprove2": "Neurosphera sp. z o.o. Adatvédelmi irányelvek",
          "isApprove3": "és",
          "isApprove4": "A Neuroterminal S.A. adatvédelmi szabályzata",
          "maptitle": "Hogyan működik a neuroterminál?",
          "mapSubtitle": "Ez nagyon egyszerű",
          "cards": [
            "Fill out the registration form to create account in Neurotermianl aplication.",
            "Receive an activation email to confirm your credentials",
            "Log into Neuroterminal and fill out the information in the patient profile.",
            "Choose the doctor and date of the first visit (visit ZERO)",
            "You will pay for it with ease using PayU (bank transfer, BLIK or credit card)",
            "For your convenience we will remind you about your visits by sending you SMS",
            "Each subsequent visit, no matter if it's remote or stationary, you will do it directly from Neuroterminal."
          ],
          "passwordRequirement": {
            "length": "8 jel",
            "upperCase": "nagybetűs",
            "lowerCase": "kisbetűs",
            "digit": "számjegy",
            "sign": "speciális jel ( ! @ $ \\ - _ . * )"
          },
          "resendMessage": "Az aktiváló linket ismét elküldtük"
        },
        "confirm": {
          "registerSuccessMsg": "A regisztráció sikeresen befejeződött",
          "passwordChangeSuccessMsg": "A jelszó sikeresen megváltozott",
          "goToLoginMsg": "Kattintson az alábbi gombra, hogy bejelentkezzen a Neuroterminalra, a Neurosphera betegek portáljára",
          "outcomeFailureMsg": "Probléma lépett fel a regisztráció megerősítésével",
          "outcomeFailureDetMsg": "Kérjük, próbálja újra az e-mailben található linkre kattintva, vagy lépjen kapcsolatba a Neuroterminál csapatával",
          "gotTologinBtn": "Menjen a bejelentkezési oldalra",
          "passwordChangeFailedMsg": "A jelszó beállítására szolgáló link lejárt"
        }
      },
      "admin": {
        "settings": {
          "ewusPasswordChanged": "Az EWUŚ jelszó megváltozott",
          "save": "Megment",
          "ewusPasswordTitle": "EWUŚ jelszó módosítása",
          "ewusPasswordLabel": "Új jelszó",
          "removeAccount": "Fiók törlése",
          "registrationDate": "Regisztráció dátuma",
          "registrationSource": "Regisztrálta:",
          "registeredBy": {
            "PATIENT": "Beteg",
            "DOCPLANNER": "Znany lekarz",
            "APPOINTING_WIDGET": "Widget"
          },
          "deleteAccount": "Fiók törlése",
          "accountNotForDeletion": "Ez a fiók nem távolítható el",
          "accountDeletionConfirmation": "Biztosan eltávolítja ezt a fiókot?",
          "yes": "Igen",
          "no": "Nem",
          "accountRemovedMsg": "A páciens fiókját eltávolítottuk"
        },
        "notes": {
          "title": "Megjegyzések",
          "current": "Jelenlegi",
          "archive": "Archívum",
          "mine": "Enyém"
        },
        "noteDetails": {
          "added": "A teremtés ideje",
          "patient": "Beteg",
          "pesel": "Pesel",
          "address": "Cím",
          "phone": "Telefon",
          "content": "Tartalom",
          "sharedBy": "Megosztotta",
          "moveToArchive": "Áthelyezés az archívumba",
          "save": "Megment",
          "reply": "Válasz",
          "initialNote": "Orvosi megjegyzés",
          "yes": "Igen",
          "no": "Nem",
          "addedFiles": "Hozzáadott fájlok"
        },
        "reassignment": {
          "noReplacementAvailable": "Nincs alternatív orvos ugyanazon az áron",
          "backToVisitToMoveTable": "Vissza a helyettesítendő találkozók listájához",
          "visitNotFoundRedirection": "Nie znaleziono wizyty, powrócisz do strony głównej",
          "reassignmentSummary": "Összegzés",
          "visitNotToMove": "A látogatás nem helyezhető át, mert az állapota már frissült.",
          "title": "Orvosváltás",
          "currentVisitDate": "Látogatás dátuma",
          "currentVisitHeader": "Változás látogatásra",
          "additionalInfo": "További információ",
          "upcomingVisit": "Közelgő beteglátogatás",
          "patient": "Beteg",
          "doctor": "Orvos",
          "currentVisitFromPackageHeader": "Változás látogatásra a csomagból",
          "cannotReassignDueToRelatedVisit": "A látogatás nem rendelhető át egy másik, már megtett látogatáshoz kapcsolódóan"
        },
        "patients": {
          "selectPatient": "Válassza ki a pácienst",
          "name": "Név",
          "email": "Email",
          "pesel": "Pesel"
        },
        "certificates": {
          "issuingDoctor": "Az igazolást kiállító orvos",
          "changeLbl": "Változás",
          "selectDoctorLbl": "Válasszon orvost",
          "hide": "Elrejt"
        }
      },
      "visits": {
        "DoctorVisits": {
          "titleLbl": "A látogatásaim",
          "returnLbl": "Visszatérés",
          "noDataLbl": "Nincs látogatás",
          "noResultsLbl": "Nem található látogatás",
          "filters": {
            "visitTime": {
              "all": "Minden",
              "future": "Jövő",
              "past": "Múlt"
            },
            "searchPatientLbl": "Beteg keresése",
            "advancedFiltersLbl": "Kiterjedtebb keresés",
            "advancedFilters": {
              "from": "Dátum innen:",
              "betweenDatesLbl": "csináld",
              "till": "Dátum ig",
              "type": "Látogatás típusa",
              "typeAll": "Minden",
              "clearBtn": "egyértelmű",
              "searchBtn": "keresés"
            }
          },
          "table": {
            "headers": {
              "state": "Állapot",
              "lastName": "Vezetéknév",
              "firstName": "Keresztnév",
              "pesel": "PESEL",
              "age": "Kor",
              "visitType": "Látogatás típusa",
              "visitCategory": "Kategória",
              "visitStatus": "Állapot",
              "dateFrom": "Látogatás dátuma",
              "sinceLast": "Utolsó óta"
            },
            "actions": {
              "summary": "Látogassa meg az összefoglalót",
              "start": "Látogatás"
            }
          }
        }
      },
      "patients": {
        "DoctorPatients": {
          "titleLbl": "Betegek",
          "returnLbl": "Visszatérés",
          "noDataLbl": "Nincsenek betegek",
          "noResultsLbl": "Nem található látogatás",
          "filters": {
            "scope": {
              "all": "Minden beteg",
              "my": "A pácienseim"
            },
            "search": "Keresés",
            "advancedFiltersLbl": "Kiterjedtebb keresés",
            "from": "tól től",
            "birthDate": "Születési dátum",
            "till": "nak nek",
            "clearBtn": "egyértelmű",
            "searchBtn": "keresés",
            "tabs": {
              "personal": "Személyes adatok",
              "address": "Címadatok",
              "biomedic": "Orvosbiológiai adatok",
              "medic": "Orvosi adatok"
            }
          },
          "table": {
            "headers": {
              "lastName": "Vezetéknév",
              "firstName": "Keresztnév",
              "medicalHistory": "Kórtörténet",
              "pesel": "PESEL",
              "email": "Email",
              "age": "Kor",
              "sex": "Szex",
              "leadDoctor": "Vezető orvos",
              "personalData": "Személyes adatok",
              "phone": "Telefonszám",
              "city": "Város",
              "zipCode": "Irányítószám",
              "country": "Ország",
              "bloodType": "Vércsoport",
              "rh": "Rh",
              "weight": "Súly",
              "height": "Magasság",
              "hasEpilepsy": "Epilepsziája van?",
              "mainIcd10": "Fő diagnózis",
              "comorbidities": "Társbetegségek",
              "vns": "Beültetett VNS",
              "activeSubstance": "Hatóanyag",
              "product": "Gyógyszer",
              "therapyType": "A terápia típusa"
            }
          }
        }
      },
      "leadDoctor": {
        "noDoctor": "A betegnek nincs vezető orvosa",
        "change": "változás",
        "choose": "Válassz orvost",
        "dialogTitle": "Válassza ki a beteg vezető orvosát",
        "genericError": "Hiba történt",
        "assign": "Rendeljen orvost",
        "patientsNumber": "Betegek száma",
        "assignNoteTitle": "Ön {XXXX} személyt választott a páciens vezető orvosának. A kiválasztott orvos a következő értesítést kapja",
        "assignNoteContent": "Orvos {name} az Ön gondozásába rendelte a beteget. Tekintse át a beteg kórtörténetét.",
        "assignNoteComment": "Ha szeretné, további üzenetet is hozzáadhat. Ha hozzáadja, a jegyzet az adminisztrátorral is meg lesz osztva",
        "assignNoteDialogTitle": "Megjegyzés a kiválasztott orvoshoz"
      },
      "visit": {
        "Visit": {
          "titleLbl": "Látogatás",
          "returnLbl": "Visszatérés",
          "printLbl": "nyomtatás",
          "survey": "0. felmérés",
          "documentation": "Látogassa meg a dokumentációt",
          "visitDocumentation": "Dokumentáció a látogatásról",
          "calendar": "Eseménynaptár",
          "eDiagnosisBtn": "e-diagnózis",
          "eTherapyBtn": "e-terápia",
          "medHistoryBtn": "kórtörténet",
          "messHistoryBtn": "üzenettörténet",
          "notesBtn": "jegyzetek",
          "observations": "Észrevételek",
          "additionalObservations": "További megfigyelések",
          "notMandatoryLbl": "nem kötelező mező",
          "observationsPlaceholder": "A megfigyelések automatikusan kitöltődnek a látogatás rovat kitöltésekor",
          "additionalObservationsPlaceholder": "Töltse ki a további észrevételeket",
          "addObservationLbl": "Mentse el a megfigyeléseket",
          "diagnosis": "diagnózis (társbetegség)",
          "seizureDiagnosis": "diagnózis (görcsrohamok)",
          "medication": "gyógyszer",
          "recommendations": "ajánlásokat",
          "prescriptionBtn": "recept",
          "certificateBtn": "bizonyítvány",
          "referralBtn": "beutaló",
          "changeTherapyBtn": "változásterápia",
          "cancel": "megszünteti",
          "save": "megment",
          "end": "Befejez",
          "leadDoctorHeader": "Vezető orvos",
          "visitLeaveConfirmMsg": "Biztosan elhagyja a látogatást?",
          "visitSaveConfirmMsg": "Biztosan menteni akarja és kilép a látogatásból?",
          "visitEndConfirmMsg": "Be szeretné fejezni a látogatást? A leállítás után további módosítások nem lehetségesek",
          "dataSavedMsg": "A látogatási adatok mentve",
          "visitEndedMsg": "A látogatás véget ért",
          "dataSaveErrorMsg": "Hiba történt a látogatási adatok mentése közben",
          "messageHistoryEmpty": "Nincsenek üzenetek ezzel a pácienssel",
          "drugsImported": "A gyógyszeres terápiákat elindították",
          "drugTherapiesInEditionMode": "A gyógyszeres terápiák kiadási módban vannak. A látogatás nem menthető.",
          "emptyRecommendations": "Nincsenek ajánlások. A látogatást nem lehet befejezni.",
          "absentConsultationInfo": "A hiányzó konzultáció a beteg részvétele nélkül történik",
          "referrals": {
            "title": "utalások",
            "removeDialogTitle": "Biztosan eltávolítja ezt a hivatkozást?",
            "addReferral": "Beutaló",
            "addDialogTitle": "Új ajánlás",
            "noAvailableServices": "Ennek a páciensnek nem állnak rendelkezésre szolgáltatások",
            "consultationService": "A konzultáció típusa",
            "doctor": "Orvos",
            "description": "Leírás",
            "cancel": "Megszünteti",
            "empty": "Erre a látogatásra nem adtak ki beutalót",
            "scheduled1Month": "1 hónap",
            "scheduled2Months": "2 hónap",
            "scheduled3Months": "3 hónap",
            "scheduled6Months": "6 hónap",
            "scheduled1Year": "1 év",
            "scheduled1month": "1 hónap",
            "scheduled2month": "2 hónap",
            "scheduled3month": "3 hónap",
            "scheduled6month": "6 hónap",
            "scheduled12month": "1 év",
            "scheduledDate": "Tervezett időpontja",
            "availableFree": {
              "title": "Nem adott ki beutalót ingyenes látogatásra",
              "subtitle": "Ezt a látogatást követően a beteg 1 ingyenes látogatásra jogosult az egyik szolgáltatásért",
              "subtitle2": "Térjen vissza gondozási tervéhez, és adja ki a megfelelő beutalót!",
              "back": "Térjen vissza a látogatásra, és küldjön beutalót",
              "endWithoutReferral": "Beutaló nélkül fejezd be",
              "visitDescription": "Ezt követően a beteg az alábbi szolgáltatások ingyenes látogatására jogosult",
              "visitAddTitle": "beutaló ingyenes látogatásra"
            },
            "doctorPackage": "Orvos csomag",
            "doctorPackageQuestion": "Beutaló orvos csomaghoz?"
          },
          "prescriptions": {
            "title": "receptek",
            "addPrescription": "Recept",
            "empty": "Erre a látogatásra nem adtak fel receptet"
          },
          "certificates": {
            "title": "tanúsítványokat",
            "addCertificate": "Bizonyítvány",
            "empty": "Erről a látogatásról nem adtak ki tanúsítványt"
          },
          "ended": {
            "titleLbl": "A telekonferencia véget ért",
            "questionMsg": "Szeretné befejezni ezt a látogatást, és visszatérni a főképernyőre?",
            "confirmationMsg": "Köszönjük, hogy részt vett a látogatáson"
          },
          "saving": {
            "warningMsg": "A látogatás {no} percen belül automatikusan mentésre kerül",
            "autoSaveMsg": "A látogatás automatikusan mentésre került. Kérjük, azonnal fejezze be a látogatást"
          },
          "icd10": {
            "title": "diagnózis"
          },
          "leaveDialog": {
            "leave": "Menjen el mentés nélkül",
            "saveAndLeave": "Mentse el és hagyja el",
            "endAndLeave": "Vége és távozni"
          },
          "wrongDataDialog": {
            "title": "Az Ön adatai jelenleg nem menthetők.",
            "description": "Kérjük, forduljon a Neurosphera Epilepsy Therapy Center ügyfélszolgálatához."
          },
          "documentationTabs": {
            "visitSurvey": "Látogassa meg a felmérést",
            "therapyArrangement": "Terápia elrendezése",
            "healthCare": "Gondozási terv",
            "recommendations": "Ajánlások",
            "certificates": "Tanúsítványok",
            "appoint": "kinevez",
            "skip": "ne nevezzen ki",
            "referralsSaved": "A hivatkozásokat elmentettük",
            "carePlan": {
              "addNext": "Következő hozzáadása",
              "remove": "Távolítsa el",
              "loading": "Adatok betöltése...",
              "referralListEmpty": "Az ajánlólista üres",
              "epilepsy": {
                "name": "Epilepsziás vizit",
                "title": "következő epilepsziás vizit – időpont egyeztetés",
                "date": "Látogatás dátuma"
              },
              "eeg": {
                "name": "EEG vizsgálat",
                "title": "EEG vizsgálat - időpont egyeztetés",
                "date": "Eeg dátum",
                "service": "Eeg típus"
              },
              "consultation": {
                "name": "Konzultáció",
                "title": "NSPH konzultáció - időpont egyeztetés",
                "date": "A konzultáció időpontja",
                "service": "A konzultáció típusa"
              },
              "other": {
                "name": "Egyéb",
                "title": "Külső utalások",
                "emptyList": "A látogatáshoz kiadott külső hivatkozások listája üres",
                "addNextEReferral": "E-ajánló hozzáadása",
                "isEReferral": "E-beutaló",
                "referralCode": "Hivatkozási kód",
                "noDataLbl": "Az ajánlólista üres"
              },
              "message": {
                "name": "Üzenet az orvosnak",
                "title": "üzenet látogatás - időpont egyeztetés",
                "date": "Látogatás dátuma"
              }
            }
          },
          "recommendationTemplate": {
            "title": "ajánló sablon",
            "examinationNames": "Vizsganevek",
            "template": {
              "message": "Kérjük, írjon üzenetet a kezelőorvosnak az egészségi állapotáról",
              "therapy": "Gyógyszerek a gyógyszeres terápia sémája szerint és a beteg számláján a Neuroterminális rendszerben a terápia lapon",
              "urgentContact": "Sürgős orvoshoz fordulás vagy előre nem látható helyzet esetén hívja a recepciót a +48 609 463 191 telefonszámon.",
              "noTherapy": "Gyógyszeres kezelés nélkül",
              "calendar": "Kérjük, tartson eseménynaptárt a betegportálon a Neuroterminál rendszerében",
              "headMr": "Kérem, végezzen MRI-t a fejről",
              "medicalExaminations": "Kérjük, végezzen további vizsgálatokat, és csatolja az eredményeket az orvosi dokumentációhoz a Neuroterminál rendszerben"
            }
          },
          "epicrisis": "Epicrisis",
          "noteZeroObservations": "Megjegyzés 0 észrevétel",
          "editNoteZeroObservations": "Megjegyzés 0 megjegyzés szerkesztése",
          "saveObservationLbl": "Jegyzet mentése 0 megfigyelés",
          "downloadTestRecommendations": "Töltse le a próbaidőszaki ajánlásokat",
          "mainDoctor": "Főorvos",
          "supervisors": "Felügyelők"
        },
        "ictal": {
          "diagnosis": {
            "title": "Tisztelt Hölgyem, Tisztelt Uram",
            "suggestedDiagnoseMsg": "Lehetséges diagnózis a számára",
            "fillSurveyMsg": "Még nincs meghatározva roham. Töltse ki a kérdőívet 0",
            "ictalDiagnose": "Diagnosztizáljon az Ictall segítségével",
            "ictalDiagnoseOneMoreTime": "Diagnosztizáljon még egyszer",
            "patientSeizureType": "Kiválasztott rohamtípus (a páciens határozza meg)",
            "subtitle1": "Ne feledje, hogy a mi",
            "subtitle2": "AI robot – Ictal®",
            "subtitle3": "- egy innovatív eszköz, amely segít, és folyamatosan megtanulja, hogy még jobban segítsen, de",
            "subtitle4": "ez nem orvosi eszköz, és nem használható páciensei valós klinikai állapotának felmérésére.",
            "subtitle5": "A teljes epilepszia ontológia könyvtárat úgy fejlesztették ki, hogy pontosan felismerje az epilepsziás roham típusát a legújabb MLPP osztályozás szerint, így az eszköz felhasználható a hipotetikus klinikai állapotok felmérésére oktatási vagy képzési célokra."
          },
          "outcome": {
            "approveLbl": "Egyetértek",
            "rejectLbl": "Nem értek egyet",
            "saveLbl": "Mentse el a diagnózist",
            "fixCritical": "Javítsa ki a kritikus kérdéseket",
            "reasonLbl": "A diagnózis kiválasztásának oka",
            "selectDiagnosisMsg": "Válassza ki a diagnózist",
            "calcDiagnosisMsg": "Diagnózis generálása..",
            "otherDiagnosis1Lbl": "Egyéb",
            "otherDiagnosis2Lbl": "diagnózis",
            "updatedByLbl": "A diagnózis szerint"
          },
          "therapy": {
            "title": "Tisztelt Hölgyem, Tisztelt Uram",
            "ictalCheck": "Jelölje be",
            "seizureType": "A roham típusa",
            "proposal": "Lehetséges terápiás lehetőségek",
            "type": {
              "0": "ICTAL javaslat",
              "1": "I Monoterápia",
              "2": "II Monoterápia",
              "3": "III Monoterápia",
              "4": "I Politerápia",
              "5": "II Politerápia",
              "6": "III Politerápia",
              "7": "További politerápia:"
            },
            "drug": {
              "description": {
                "lastUsed": "Utoljára használt:",
                "color": {
                  "undefined": "A gyógyszert korábban nem használták.",
                  "orange": "A gyógyszert korábban is használták: nincs hatás / gyenge hatás.",
                  "red": "A gyógyszert korábban is használták: negatív hatás, a beteg állapota romlott.",
                  "green": "A gyógyszert korábban használták: kielégítő hatás."
                }
              }
            },
            "filter": {
              "availableInPL": "A gyógyszer Lengyelországban kapható",
              "refundedInPL": "Lengyelországban kompenzált gyógyszer",
              "reproductiveAgeOptional": "Reproduktív kor"
            },
            "notFound": "Az ICTAL nem tudta a betegre szabott kezeléseket.",
            "apply": "Alkalmazzon terápiát",
            "subtitle1": "Ne feledje, hogy a miénk",
            "subtitle2": "AI robot – Ictal®",
            "subtitle3": "- egy innovatív eszköz, amely segít, és folyamatosan megtanulja, hogy még jobban segítsen, de",
            "subtitle4": "ez nem orvosi eszköz, és nem használható páciensei valós klinikai állapotának felmérésére.",
            "subtitle5": "A teljes epilepsziaterápiás folyamatot egy szakértői csapat dolgozta ki, és az epilepsziával foglalkozó vezető orvostudományi társaságok irányelvei alapján dolgozta ki, így az eszköz felhasználható a hipotetikus klinikai állapotok felmérésére oktatási vagy képzési célokra."
          }
        },
        "doctorNotes": {
          "title": "Megjegyzések",
          "subtitle": "Ez egy olyan hely, ahol tárolhatja a pácienssel kapcsolatos privát feljegyzéseit",
          "addNote": "Megjegyzés hozzáadása",
          "save": "Változtatások mentése",
          "saveSuccess": "Jegyzet elmentve",
          "modified": "Utoljára módosítva",
          "editCancel": "Módosítások elvetése",
          "edit": "Jegyzet szerkesztése",
          "delete": "Jegyzet törlése",
          "askDelete": "Biztosan törli ezt a jegyzetet?",
          "deleteSuccess": "Jegyzet törölve",
          "publicVisible": "Minden orvos számára látható",
          "limitedShare": "Kiválasztott orvosok számára látható",
          "sharedBy": "Megosztotta",
          "forAdmin": "Látható az admin számára",
          "repliedTo": "Válaszul",
          "sharedByPatient": "A páciens megosztotta a konzultáció hiánya miatt",
          "absentConsultation": "Hiányzó konzultáció",
          "addFiles": "Fájl hozzáadása"
        },
        "diagnosis": {
          "confirmTitle": "A diagnózis megváltoztatása",
          "confirmText": "Biztos benne, hogy nem akarja megváltoztatni a diagnózist?",
          "updatedMsg": "Frissített diagnózis ehhez a rohamtípushoz"
        },
        "patientMessageHistory": {
          "title": "A páciens üzeneteinek előzményei",
          "emptyList": "A betegüzenetek előzményei üresek"
        }
      },
      "landing": {
        "common": {
          "menu": {
            "observe": "Figyelj meg minket",
            "link": {
              "database": "NT - Adatbázis",
              "doctors": "Az orvosoknak",
              "contact": "Kapcsolatba lépni",
              "login": "Belépés",
              "register": "Regisztráció"
            },
            "terms": {
              "title": "Használati feltételek",
              "z1": "Szervezeti szabályzat",
              "z2": "Elektronikus szolgáltatások nyújtásának szabályzata",
              "z3": "A Neurosphera adatvédelmi szabályzata",
              "z4": "A neuroterminál adatvédelmi szabályzata",
              "payuInstallments": "PayU részletfizetés – GYIK"
            }
          },
          "contact": {
            "title": "Lépjen kapcsolatba velünk",
            "media": {
              "visit": "Látogass meg minket"
            },
            "dataTitle": "Lépjen kapcsolatba velünk",
            "formTitle": "Kapcsolatfelvételi űrlap",
            "form": {
              "email": "Küldj emailt",
              "phone": "Hívjon minket",
              "message": "Üzenet",
              "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in",
              "send": "Üzenet küldése",
              "ApproveDataProcessing": "Önként hozzájárulok ahhoz, hogy személyes adataimat a Neuroterminal S.A. kezelje a jelentkezésem kiszolgálása érdekében. A személyes adatok védelmének alapelveiről és az Ön jogairól részletes tájékoztatást találhat",
              "policy": "Adatvédelmi irányelvek",
              "feedback": {
                "header": "Köszönjük, hogy elküldte nekünk üzenetét!",
                "content": "Hamarosan felvesszük Önnel a kapcsolatot\ntelefonon vagy e-mailben"
              }
            }
          },
          "help": {
            "title": "Kérdései vannak?"
          }
        },
        "main": {
          "title1": "Modern epilepszia",
          "title2": "kezelési rendszer",
          "bullet1": "Kényelmes távorvosi tanácsadás\n99 PLN-től",
          "bullet2": "Hozzáférés a legjobbhoz\nepileptológusok",
          "bullet3": "Átfogó orvosi ellátás\nanélkül, hogy elhagyná otthonát",
          "arrangeConsultation": "Időpontot foglal",
          "features": {
            "consultations": {
              "title": "Telemedicina\nkonzultációk",
              "description": "Online látogatások lebonyolítása\nvideokonferencia útján"
            },
            "emergency": {
              "title": "Vészhelyzet\nesetek",
              "description": "24 órás segélyhívás\norvosokkal"
            },
            "panel": {
              "title": "Beteg\npanel",
              "description": "Kórtörténet,\norvosi dokumentumok\nés ajánlásokat\negy helyen"
            },
            "edocuments": {
              "title": "Elektronikus receptek,\nbeteglevelek",
              "description": "és ajánlások\nanélkül, hogy elhagyná otthonát"
            },
            "doctors": {
              "title": "\nOrvosok",
              "description": "Hozzáférés a legjobb epileptológusokhoz\nsorok nélkül"
            },
            "quickAccess": {
              "title": "Gyors\nhozzáférés",
              "description": "Konzultációk az orvosokkal\nsorok nélkül"
            }
          },
          "subHeaderBullet1": "Maradjon kapcsolatban orvosával,\naki a mesterséges intelligencia modult használja\nhogy figyelemmel kísérje a kezelés hatékonyságát.",
          "subHeaderBullet2": "Hozzáférés a legjobb neurológusokhoz Lengyelországban\nanélkül, hogy elhagyná otthonát.",
          "subHeaderBullet3": "Csatlakozzon ingyenesen a Neuroterminálhoz\nés kezdj el innovatív megoldásokat használni\nepilepszia kezelésére!",
          "howItWorks": "Hogyan működik?",
          "steps": {
            "choose": {
              "title": "A páciens válasszon\norvos",
              "description": "szakemberek adatbázisából"
            },
            "appoint": {
              "title": "Készít\negy találkozót",
              "description": "távorvosi látogatásra\nmegfelelő időpontban"
            },
            "diagnose": {
              "title": "Az orvos diagnosztizál",
              "description": "és a terápiát választja.\nAz iCtal mesterséges intelligencia rendszer\nsegít neki ebben"
            },
            "documents": {
              "title": "Hozzáadja az orvosi\ndokumentumokat",
              "description": ""
            },
            "contact": {
              "title": "Az orvos marad\nérintésben",
              "description": "türelmesen,\nrendszeresen figyelemmel kíséri a fejlődést\na végrehajtott kezelésről"
            }
          },
          "opinions": "A felhasználók véleménye",
          "register": "Hozzon létre egy fiókot az időpont egyeztetéséhez"
        },
        "database": {
          "epilepsy": {
            "title": "Mi az epilepszia?",
            "subheader1": "Az epilepszia egészségügyi állapot\nvisszatérő rohamok jellemzik.",
            "subheader2": "Roham az eredmény\negy ideiglenesen megváltozott\nagyműködés\nokozta\nrendellenes vagy túlzott\nelektromos kisülések\naz agysejtek belsejében."
          },
          "seizure": {
            "title": "Hogyan történik a roham\nhasonló?",
            "subheader": "A rohamok tünetei nagyon változatosak lehetnek.",
            "bullet1": "Néhány epilepsziás ember\negyszerűen \"elveszítsd el a kapcsolatot\"\nnéhány másodpercig roham alatt",
            "bullet2": "Mások a padlóra esnek\na végtagok görcseivel",
            "footer": "Nagyon fontos a roham típusának osztályozása\nés segít az orvosnak megtervezni a szükséges kezelést.\nEgyetlen roham nem jelenti azt, hogy epilepsziája van."
          },
          "factors": {
            "title": "Provokáló tényezők",
            "subheader": "Görcsrohamok is előidézhetők\nismert és visszafordítható tényezők hatására,\nmint például",
            "bullet1": "Nincs alvás",
            "bullet2": "Alkohol\nvisszavonás",
            "bullet3": "Alacsony cukor",
            "seizureRecognition": "Legalább két provokálatlan roham\náltalában szükségesek az epilepszia diagnosztizálásához.",
            "seizuresRelatedTo": "A görcsrohamok összefüggésbe hozhatók",
            "relatedToBullet1": "Agykárosodás",
            "relatedToBullet2": "Családi hajlam",
            "relatedToBullet3": "Gyakran az okuk\nteljesen ismeretlen"
          },
          "whoCanSick": {
            "title": "Ki betegedhet meg?",
            "subheader": "Az epilepszia bárkit érinthet,\nnemtől és kortól függetlenül.",
            "bullet1": "A legtöbb roham gyermekeknél fordul elő\n1 éves korig,\nmíg az idősebbeknél általában növekedés tapasztalható\nelőfordulási gyakorisága 65 év után.",
            "bullet2": "Becslések szerint az emberek akár 1%-a\nepilepsziában szenved (Lengyelországban körülbelül 400 000 ember)."
          },
          "treatment": {
            "title": "Hogyan kezelik az epilepsziát?",
            "bullet1": "A kábítószerek hatékonyan szabályozhatják a rohamokat\nkörülbelül 70%-ban\nepilepsziás betegeknél",
            "bullet1Header": "Gyógyszerek",
            "bullet2": "Nem kontrollált epilepsziában szenvedő betegek számára,\naz idegsebészet nyújthat \"gyógymódot\"\na forrás megszüntetésével\ngörcsrohamok és epilepszia esetén.",
            "bullet2Header": "Sebészet",
            "footer": "Gyógyszeres kezelés vagy néha műtét\nképes kontrollálni a rohamokat a legtöbb epilepsziás embernél.\nNéhány embernek élethosszig tartó kezelésre van szüksége a rohamok megfékezésére,\nde mások végül abbahagyják. Néhány epilepsziás gyermek\na kor előrehaladtával kinőhet a rohamokból."
          },
          "impact": {
            "title": "Hogyan hat az epilepszia\nmindennapi élet?",
            "subheader": "A görcsrohamok és az epilepszia befolyásolhatja",
            "bullet1": "Biztonság",
            "bullet2": "Kapcsolatok",
            "bullet3": "Munka",
            "bullet4": "Autót vezet",
            "bullet5": "És még sok minden más",
            "footer": "Az epilepsziában szenvedők közfelfogása és kezelése\ngyakran nagyobb problémákat okoznak, mint a tényleges rohamok."
          },
          "help": {
            "title": "A neuroterminális akarat\nsegít az epilepszia gyógyításában",
            "bullet1Header": "Kényelem",
            "bullet1": "Majd intézed\norvossal folytatott konzultáció\nanélkül, hogy elhagyná otthonát.",
            "bullet2Header": "Innováció",
            "bullet2": "Mesterségest használunk\nhírszerző rendszerek\nés modern elemző eszközök\nepilepszia kezelésére.",
            "bullet3Header": "Biztonság",
            "bullet3": "Hozzáférést kapsz\n24 órás neurológiai műszakra\namely biztosítja Önnek\nazonnali segítséget.",
            "makeAnAppointment": "Időpontot foglal"
          }
        },
        "doctors": {
          "title1": "Legyél orvos",
          "titleIn": "ban ben",
          "title2": "NEUROTERMINÁLIS",
          "subtitle": "Konzultáljon a betegekkel online\na legújabb felhasználásával\ntechnológiákat.",
          "register": "Regisztrálj",
          "features": {
            "consultations": {
              "title": "Telemedicina\nkonzultációk",
              "description": "Online látogatások végrehajtása\nvideokonferencián keresztül\nvagy csevegni"
            },
            "digitalOffice": {
              "title": "Digitális\nhivatal",
              "description": "Minden látogatás és orvosi adat\nbetegei közül\negy helyen"
            },
            "eDocuments": {
              "title": "\nE-dokumentumok",
              "description": "Készítsen recepteket,\nbetegszabadság és beutaló\nonline"
            },
            "flexibleSchedule": {
              "title": "Rugalmas\nmenetrend",
              "description": "Konzultációk ütemezése\naz Ön számára megfelelő időpontokban\nheti 7 nap"
            },
            "system": {
              "title": "Rendszer\nNeuroterminális",
              "description": "Használjon innovatív\nmesterséges intelligencia rendszer\nbetegek diagnosztizálására"
            },
            "patients": {
              "title": "\nBetegek",
              "description": "Vezesse betegeit\nés gyorsan szerezz újakat"
            }
          },
          "steps": {
            "register": {
              "title": "Regisztrálj",
              "description": ""
            },
            "training": {
              "title": "Végezzen edzést",
              "description": "hogyan kell használni a neuroterminált\nplatform és magatartás\ntávorvosi konzultációk"
            },
            "schedule": {
              "title": "Állítsa be a konzultációkat\nmenetrend",
              "description": "szabadon tervez\na te elérhetőséged"
            },
            "pricing": {
              "title": "Adja meg az árlistát",
              "description": "a fizetésed\nkonzultációra"
            },
            "consultations": {
              "title": "Konzultáljon",
              "description": "telemedicina online keresztül\na Neuroterminál platform\nvideón vagy chaten keresztül"
            },
            "monitor": {
              "title": "Monitor\nbetegek",
              "description": "és kezelésük előrehaladása"
            }
          },
          "firstSteps": "Első lépések"
        },
        "patients": {},
        "removePatient": {
          "title": "Erősítse meg a fiók eltávolítását",
          "subTitle1": "Számla regisztrálva a következő címen:",
          "subTitle2": "eltávolításra kerül",
          "why": "Miért szeretné eltávolítani a fiókot?",
          "whyPrompt": "Válasza nagyon fontos számunkra. Segítségünk lesz szolgáltatásunk fejlesztésében.",
          "removeBtn": "Távolítsa el fiókját a Neuroterminalról",
          "accountRemoved": "Fiókját eltávolítottuk",
          "noAccount": "A fiók nem létezik, vagy nem távolítható el",
          "accountRemovedDescription": "Ne feledje, hogy bármikor regisztrálhat a Neuroterminálon és foglalhat látogatást a Neurosphera-ban."
        }
      },
      "ictal": {
        "lab": {
          "title": "IctalLab",
          "subtitle": "Írja be a vektort vagy töltse be a pácienst az automatikus diagnózis elindításához.",
          "diagnose": "Diagnózis",
          "clear": "Egyértelmű",
          "inputVector": "Bemeneti vektor",
          "loadPatient": "Válassza ki a pácienst",
          "changePatient": "Cserélj beteget",
          "selectedPatient": "Kiválasztott beteg",
          "survey": "Felmérés",
          "diagnosis": "Diagnózis",
          "factorsConfiguration": "Tényezők konfigurációja",
          "sum": "Összeg",
          "therapy": "Terápia",
          "check": "Jelölje be",
          "selectTherapy": "Válassza ki a terápia típusát",
          "selectedTherapy": "A kiválasztott terápia típusa",
          "selectSeizureType": "Válassza ki a rohamtípusokat",
          "selectedSeizureType": "Válogatott rohamtípusok",
          "drugAvailableInPl": "A gyógyszer Lengyelországban kapható",
          "drugRefunded": "Gyógyszer visszatérített",
          "patientIsPregnant": "A beteg terhes",
          "patientWithSomaticDiseases": "Szomatikus betegségben szenvedő beteg",
          "patientInOlderAge": "Beteg idősebb korban",
          "patientOfChildbearingAge": "Fogamzóképes korú beteg",
          "patientAge": "Beteg életkora",
          "recommendedTherapy": "Ajánlott terápia",
          "errors": {
            "factorsError": "Az együtthatók összege 100 legyen",
            "vectorLengthError": "Helytelen bemeneti vektor hossza",
            "vectorInvalidError": "Helytelen bemeneti vektor"
          },
          "factors": {
            "nn": "NN",
            "knn": "KNN",
            "jacc": "Jaccard",
            "jaro": "Jaro",
            "sodi": "Sørensen–Dice",
            "over": "Átfedés",
            "tver": "Tversky: α=1, β=1"
          },
          "surveyHeaders": {
            "name": "Kérdés",
            "always": "Mindig",
            "veryOften": "Nagyon gyakran",
            "often": "Gyakran",
            "sometimes": "Néha",
            "rarely": "Ritkán",
            "veryRarely": "Nagyon ritkán",
            "never": "Soha",
            "veryLong": "Nagyon hosszú",
            "long": "Hosszú",
            "bitLong": "Kicsit hosszú",
            "variable": "Változó",
            "short": "Rövid",
            "veryShort": "Nagyon rövid",
            "ultraShort": "Ultra rövid"
          },
          "seizuresTableHeaders": {
            "seizureType": "A roham típusa",
            "probability": "Valószínűség [ % ]"
          },
          "selectUserDialog": {
            "title": "Válassza ki a pácienst",
            "noPatientsFound": "Nem találtak betegeket."
          },
          "configuration": {
            "subtitle": "Konfiguráció",
            "drugCatalog": {
              "title": "Gyógyszerkatalógus",
              "add": "Adjon hozzá új gyógyszert"
            },
            "factors": {
              "save": "Tényezők mentése",
              "coefModifiedMsg": "Az együtthatók mentve"
            }
          },
          "drugs": {
            "deleteConfirmationDialogText": "A gyógyszer törlésével az Ictal motor edzési adatai is törlődnek. A gyógyszerhez kapcsolódó összes adat törlődik. Szeretnéd folytatni?",
            "updateDrugDialogTitle": "Frissítse a gyógyszert",
            "createDrugDialogTitle": "Adjon hozzá gyógyszert",
            "save": "Megment",
            "cancel": "Megszünteti",
            "validation": {
              "minAgeRequired": "Minimális életkor kötelező",
              "minAgeRange": "A minimális életkornak 0 és 120 közötti számnak kell lennie",
              "weightRequired": "Súly szükséges",
              "weightRange": "A súlynak -3 és 3 közötti számnak kell lennie"
            },
            "legend": {
              "title": "Drog - legenda",
              "column": "Oszlop",
              "value": "Érték",
              "thrAbove": "Elérte az életkori küszöböt; életkor a beállított küszöb felett",
              "thrBelow": "az életkori küszöböt nem érte el; életkor a meghatározott küszöb alatt van",
              "drugAvailable": "A gyógyszer PL-ben kapható",
              "drugNotAvailable": "A gyógyszer nem kapható PL-ben",
              "drugRefunded": "A gyógyszert visszatérítik Lengyelországban",
              "drugNotRefunded": "A gyógyszert nem térítik vissza Lengyelországban",
              "usageForbidden": "Tiltott",
              "usageVeryRare": "Nagyon ritkán használt",
              "usageRare": "Ritkán használt",
              "usageAmbivalent": "Ambivalens",
              "usageSometimes": "Néha használt",
              "usageOften": "Gyakran használt",
              "usageRecommended": "Ajánlott"
            }
          },
          "usedDrugs": "Kiszűrendő drogok (beleértve – rendkívüli állapotromlás)",
          "drugProposal": "Gyógyszerjavaslat",
          "wageSum": "Bérösszeg (magasabb - jobb)",
          "patientSex": {
            "label": "Szex",
            "type": {
              "MALE": "Férfi",
              "FEMALE": "Női"
            }
          },
          "experts": {
            "titleLbl": "Szakértő orvosok",
            "addExpertMsg": "Szakértő hozzáadása",
            "editExpertMsg": "Szakértő szerkesztése",
            "deleteExpertTitleMsg": "Távolítsa el a szakértőt",
            "deleteExpertDescMsg": "A szakértő eltávolítása a szakértőhöz kapcsolódó összes Ictal motoroktatási adat eltávolítását eredményezi. Akarod folytatni?",
            "saveMsg": "A szakértői adatok módosultak",
            "save": "Megment",
            "cancel": "Megszünteti",
            "validation": {
              "weightRange": "A súlynak 0 és 1 közötti számnak kell lennie"
            }
          },
          "recommendations": {
            "titleLbl": "A szakértők által javasolt gyógyszerek",
            "addRecommendationLbl": "Adjon hozzá ajánlást",
            "saveMsg": "Gyógyszerajánló vektor mentve",
            "save": "Megment",
            "cancel": "Megszünteti",
            "dialog": {
              "titleLbl": "Ajánlott gyógyszerek",
              "expertLbl": "Szakértő",
              "therapyLbl": "Terápia"
            }
          },
          "epilepsies": {
            "titleLbl": "Az epilepszia típusai",
            "addMsg": "Adja hozzá az epilepszia típusát",
            "editMsg": "Szerkessze az epilepszia típusát",
            "deleteTitleMsg": "Távolítsa el az epilepszia típusát",
            "deleteDescMsg": "Az epilepszia típusának eltávolítása az összes, ehhez az epilepszia típushoz kapcsolódó Ictal motor edzési adatának törlését eredményezi. Akarod folytatni?",
            "saveMsg": "Az epilepszia típusok adatai módosultak",
            "save": "Megment",
            "cancel": "Megszünteti"
          },
          "ediagnosis": {
            "titleLbl": "E-diagnózis",
            "headers": {
              "eventsTrailer": "Jóval a roham előtt (óra, nap)",
              "eventsBefore": "Közvetlenül a roham előtt (másodpercek)",
              "eventsStart": "A roham kezdete - első tünet",
              "eventsNext": "A következő roham tünetei",
              "eventsEnd": "A roham vége",
              "eventsAfter": "A roham után",
              "eventsFeature": "Általános rohamjellemző"
            },
            "dialog": {
              "titleLbl": "E-diagnózis vektor",
              "epilepsyLbl": "Epilepszia típus",
              "save": "Megment",
              "cancel": "Megszünteti",
              "saveMsg": "E-Diagnosis vektor módosítva"
            },
            "events": {
              "trailerPrecise": "Pontos előrejelzés (végén)",
              "trailerUnspecified": "Előrejelzés meghatározatlan (végén)",
              "beforeAura": "Aura (előtte)",
              "beforeSpecificStimuli": "Specifikus ingerek (előtte)",
              "beforeNystagmus": "Nystagmus (előtte)",
              "beforeImmobility": "Mozdulatlanság (előtte)",
              "startFaint": "ájulás (kezdés)",
              "startLossConsciousness": "Eszméletvesztés (kezdés)",
              "startBodyConvulsions": "Testi görcsök (kezdet)",
              "startBodyPartConvulsions": "Testrész görcsök (kezdet)",
              "startFaceConvulsions": "Arcgörcsök (kezdet)",
              "startBodyStiffness": "A test merevsége (kezdet)",
              "startLimbEjection": "Végtag kilökése (kezdés)",
              "startEyeMovementDisorders": "Szemmozgási zavarok (kezdet)",
              "startEyeTurnPernament": "Állandó szemforgatás (kezdés)",
              "startBodyTurn": "A karosszéria elfordulása (indítás)",
              "startGroundFall": "Földi esés (rajt)",
              "startBitTongue": "Harapós nyelv (kezdet)",
              "startShout": "Kiabálás (kezdés)",
              "startSounds": "Hangok (kezdés)",
              "startMotorAutomatisms": "Motor automatizmus (indítás)",
              "startImmobility": "Mozdulatlanság (indulás)",
              "startLaugh": "Nevetés (kezdés)",
              "startChuck": "Tokmányozás (kezdés)",
              "startCry": "Sírás (kezd)",
              "startEpigastricSymptoms": "Epigasztrikus tünetek (kezdet)",
              "startDejaVu": "Deja vu (kezdés)",
              "startVisualHallucinations": "Vizuális hallucinációk (kezdet)",
              "startAuditoryHallucinations": "Auditív hallucinációk (kezdet)",
              "startEyelidsMyoclonus": "Szemhéj myoclonus (kezdet)",
              "startMyoclonus": "Myoclonus (kezdet)",
              "nextFaint": "Ájulás (következő)",
              "nextLossConsciousness": "Eszméletvesztés (következő)",
              "nextBodyConvulsions": "Testi görcsök (következő)",
              "nextBodyPartConvulsions": "Testrész görcsök (következő)",
              "nextFaceConvulsions": "Arcgörcsök (következő)",
              "nextBodyStiffness": "A test merevsége (következő)",
              "nextEyeMovementDisorders": "Szemmozgási zavarok (következő)",
              "nextEyeTurnPernament": "Állandó szemforgatás (következő)",
              "nextBodyTurn": "Testfordulat (következő)",
              "nextGroundFall": "Földesés (következő)",
              "nextBitTongue": "Harapós nyelv (következő)",
              "nextUrinating": "Vizeletürítés (következő)",
              "nextDefecation": "Székletürítés (következő)",
              "nextShout": "Kiabálás (következő)",
              "nextSounds": "Hangok (következő)",
              "nextAggression": "Agresszió (következő)",
              "nextMotorAutomatisms": "Motor automatizmus (következő)",
              "nextImmobility": "Mozdulatlanság (következő)",
              "nextLaugh": "Nevet (következő)",
              "nextChuck": "Chucking (következő)",
              "nextCry": "Sírás (következő)",
              "nextEpigastricSymptoms": "Epigasztrikus tünetek (következő)",
              "nextDejaVu": "Deja vu (következő)",
              "nextVisualHallucinations": "Vizuális hallucinációk (következő)",
              "nextAuditoryHallucinations": "Auditív hallucinációk (következő)",
              "nextEyelidsMyoclonus": "Szemhéj myoclonus (következő)",
              "endAnotherSeizure": "Újabb roham (vége)",
              "endBodyConvulsions": "Testi görcsök (vége)",
              "endBodyPartConvulsions": "Testrész görcsök (vége)",
              "endFaceConvulsions": "Arcgörcsök (vége)",
              "endEyeMovementDisorders": "Szemmozgási zavarok (vége)",
              "endEyeTurnPernament": "Állandó szemforgatás (vége)",
              "endBodyTurn": "Testfordulat (vége)",
              "endGroundFall": "Földi esés (vége)",
              "endUrinating": "Vizeletürítés (vége)",
              "endDefecation": "Székletürítés (vége)",
              "endSounds": "Hangok (vége)",
              "endAggression": "Agresszió (vége)",
              "endMotorAutomatisms": "Motor automatizmus (vége)",
              "endImmobility": "Mozdulatlanság (vége)",
              "endLaugh": "Nevetés (vége)",
              "endChuck": "Befejezés (vége)",
              "endCry": "Sírás (vége)",
              "endVomit": "Hányás (vége)",
              "afterEyeMovementDisorders": "Szemmozgási zavarok (után)",
              "afterEyeTurnPernament": "Állandó szemforgatás (után)",
              "afterUrinating": "Vizeletürítés (után)",
              "afterDefecation": "Székletürítés (után)",
              "afterConfusion": "Zavar (utána)",
              "afterSleep": "Alvás (utána)",
              "afterAggression": "Agresszió (utána)",
              "afterMotorAutomatisms": "Motor automatizmus (utána)",
              "afterVomit": "Hányás (után)",
              "afterHighTemperature": "Magas hőmérséklet (utána)",
              "featureMany": "Sok (funkció)",
              "featureDayOccurrence": "Napi előfordulás (funkció)",
              "featureNightOccurrence": "Éjszakai esemény (funkció)",
              "featureAwakeningOccurrence": "Ébredési esemény (funkció)",
              "featureMorningOccurrence": "Reggeli esemény (funkció)",
              "featureDuration": "Időtartam (funkció)"
            }
          },
          "model": {
            "search": "Keresés",
            "manage": "Kezelése",
            "drug": {
              "general": "Általános adatok",
              "weights": "Súlyok",
              "shortName": "Rövid név",
              "name": "Név",
              "minAge": "Min. életkor",
              "plAvailability": "PL-ben kapható",
              "plRefunded": "Visszatérítés PL-ben",
              "reproductiveAgeWeight": "Reproduktív kor",
              "pregnantWeight": "Terhes",
              "oldAgeWeight": "Öreg kor",
              "somaticDiseaseWeight": "Szomatikus betegség"
            },
            "expert": {
              "description": "Szakértő",
              "factor": "Tényező"
            },
            "epilepsy": {
              "name": "Név"
            }
          },
          "algorithmSteps": {
            "filterName": "Szűrő",
            "order": "Rendelés",
            "usedFilters": "Használt szűrők",
            "filters": {
              "SeizureDrugFilter": "Durg szelekció rohamokkal",
              "UsedDrugFilter": "Használt gyógyszerek eltávolítása",
              "TherapyDrugFilter": "A kábítószer-rendelés a szakértők ajánlása szerint (súly)",
              "WeightDrugFilter": "Súlyszámítás"
            },
            "tableModel": {
              "drugName": "Név",
              "drugShortName": "Rövid név",
              "minAge": "Min. életkor",
              "oldAgeWeight": "Idősebb kor",
              "pregnantWeight": "Terhesség",
              "reproductiveAgeWeight": "Reproduktív kor",
              "somaticDiseaseWeight": "Szomatikus betegségek",
              "weightSum": "Súlyösszeg"
            }
          },
          "furtherPoliOffset": "További Politerápia szám (0-tól kezdődik)"
        }
      },
      "duties": {
        "Duties": {
          "duty": "Tervezett ügyelet",
          "availableDuty": "Rendelkezésre álló vám",
          "notAvailableDuty": "A kötelesség teljesíthetetlen",
          "selectedDuty": "Kiválasztott",
          "blockedDuty": "Nem elérhető",
          "cancelledDuty": "Lemondásra kiválasztva",
          "dutyToChange": "Kérés a szolgálat átvételére",
          "changes": {
            "approve": {
              "text": "Változtatások mentése",
              "tooltipInstruction": "Válasszon feladatokat a rendelkezésre állók közül, és mentse el a gombra kattintva"
            },
            "discard": {
              "text": "Módosítások elvetése"
            }
          }
        },
        "confirmationDialog": {
          "text": "A módosítások nem kerülnek mentésre. El akarja dobni őket?"
        },
        "youAreNotAllowedDialog": {
          "title": "Engedélyek szükségesek",
          "text": "Nincs engedélye a feladatok regisztrálására. További információért forduljon hozzánk."
        },
        "obligatoryDutyDialog": {
          "title": "A művelet nem engedélyezett",
          "subtitle": "A szerződési feltételek szerint a kiválasztott ügyelet nem mondható le.",
          "text": "További információkért tekintse meg oldalunkat",
          "termsAndConditions": "Feladatok Általános Szerződési Feltételek",
          "close": "Bezárás"
        },
        "dutiesLimitAchievedDialog": {
          "title": "A művelet nem engedélyezett",
          "subtitle": "Elérte az e heti szolgálati korlátot.",
          "text": "További információkért tekintse meg oldalunkat",
          "termsAndConditions": "Feladatok Általános Szerződési Feltételek",
          "close": "Bezárás"
        }
      },
      "medicalHistory": {
        "MedicalHistory": {
          "title": "Kórtörténet",
          "return": "Visszatérés",
          "show": "Előadás",
          "psychomotorDevelopment": "Pszichomotoros fejlődés",
          "comorbidities": "Társbetegségek",
          "notDiagnosedLbl": "Nem diagnosztizálták",
          "bloodType": "Vércsoport",
          "height": "Magasság",
          "weight": "Súly",
          "bmi": "BMI",
          "tabs": {
            "psychomotorDevelopment": "Pszichomotoros fejlődés",
            "seizuresHistory": "Rohamtörténet",
            "seizuresCharacteristics": "A rohamok jellemzői",
            "comorbidities": "Társbetegségek",
            "treatment": "Kezelés"
          },
          "treatment": {
            "title": "Kezelési és látogatási előzmények",
            "currentTherapy": "Jelenlegi terápia",
            "biomedicalData": "Orvosbiológiai adatok",
            "comorbiditiesData": "Társbetegségek adatai",
            "previousTherapies": "A terápiák története",
            "number": "Nem",
            "medicine": "Gyógyszer",
            "dosage": "Adagolás",
            "effect": "Hatás",
            "noCurrentTherapy": "Nincs jelenlegi terápia",
            "start": "Rajt",
            "end": "Vége",
            "noPreviousTherapies": "A terápiák története üres",
            "treat": "kezelés",
            "visits": "látogatások"
          },
          "survey": {
            "title": "0. felmérés"
          },
          "calendar": {
            "title": "Eseménynaptár",
            "visitCalendarLabel": "Látogatások naptár"
          },
          "visitCalendar": {
            "title": "Az orvos és a beteg véleménye",
            "opinionTypeHeader": "Vélemény típus",
            "seizureEvaluation": "A rohamok súlyossága",
            "wellBeing": "Jólét",
            "treatmentRating": "A kezelés hatékonysága",
            "sideEffects": "Mellékhatások",
            "treatmentSafety": "A kezelés biztonsága",
            "patientOpinion": "A páciens véleménye",
            "doctorOpinion": "Orvos véleménye",
            "noData": "Nincs adat a felmérésekből",
            "noVisits": "Nincs látogatás"
          },
          "prescriptions": {
            "title": "E-receptek"
          },
          "consultations": {
            "title": "Látogatások",
            "noConsultations": "Nincs konzultáció",
            "observations": "Észrevételek",
            "recomendation": "Ajánlást",
            "doctor": "Orvos",
            "date": "Dátum",
            "type": "Typ",
            "category": "Kategória",
            "none": "Egyik sem"
          },
          "icd10": {
            "title": "Diagnózis",
            "current": "Jelenlegi diagnózis",
            "history": "Történelem",
            "from": "Tól től",
            "added": "Hozzáadva",
            "removed": "Eltávolítva",
            "noCurrentIcd10": "Jelenleg nincs diagnózis",
            "noIcd10History": "A diagnózis előzményei üresek"
          },
          "bioMedInfo": {
            "edit": "Szerkesztés"
          },
          "epicrisis": {
            "title": "Epicrisis"
          }
        },
        "healthSurvey": {
          "title": "0. felmérés",
          "readOnlyAccess": "Csak olvasható előnézet. A kérdőívet az orvos az első látogatás alkalmával töltheti ki",
          "noAnswer": "Nincs válasz",
          "allDisabled": "A 0. felmérés az első látogatás után nem módosítható",
          "partCompleted": "Ez a felmérés rész elkészült",
          "currentTabDisabled": "Az állapotfelmérés ezen része befejeződött, és az első látogatás után nem módosítható",
          "emptyFilterSet": "A listán csak olyan kérdések vannak, amelyekre nincs válasz.",
          "surveyUpdatedByDoctor": "Az orvos nemrég frissített egy felmérést a „{sectionName}” részben",
          "filters": {
            "show": "Előadás",
            "all": "Minden",
            "empty": "Üres"
          },
          "hintPopup": {
            "title": "Nem tudja, hogyan válaszoljon?",
            "content": "Ne aggódj! Hagyj megválaszolatlanul egy nehéz kérdést. Az orvos az első konferenciahívás során segít a kérdőív hiányzó részeinek kitöltésében."
          },
          "SeizureHistory": {
            "introTitle": "Szeretnénk tudni, mennyi ideig és milyen rohamai vannak.",
            "introSubtitle": "Ez nagyon fontos. Figyelem: ez az Ön kórtörténete, amit csak így lehet megállapítani, másképp nem lehet leírni, ezért igyekezzen pontos tényeket, dátumokat szerezni!",
            "next": "Következő",
            "edit": "Szerkesztés",
            "back": "Előző",
            "historyCanBeModified": "A rohamelőzményeket már inicializálták. Az első látogatás előtt módosíthatja.",
            "historyCannotBeModified": "A rohamelőzményei az első látogatás után nem módosíthatók.",
            "return": "Visszatérés",
            "outroTitle": "Gratulálunk, minden, a rohamtörténetével kapcsolatos kérdésre válaszolt.",
            "outroSubtitle": "Köszönöm!",
            "save": "Megment",
            "generatingInProgress": "A rohamelőzmények generálása folyamatban van. Kérlek várj...",
            "saveSuccess": "A rohamelőzmények mentése sikeresen megtörtént",
            "generateSuccess": "A rohamelőzmények sikeresen generálva",
            "stages": {
              "firstSeizure": "Első roham",
              "quantityAndRegularity": "Mennyiség és rendszeresség",
              "frequency": "Frekvencia",
              "breaks": "Szünetek"
            },
            "seizureTypesForm": {
              "title": "Határozza meg rohamai típusainak számát, és nevezze el őket (maximum 3)",
              "modifyWarning": "Figyelem! A rohamtípusok módosítása után a rohamtörténetet és a rohamjellemzőket újra kell definiálni",
              "modifyDisabled": "A rohamtípusok nem módosíthatók a rohamok miatt",
              "typeName": "A roham típusának neve",
              "addType": "Adja hozzá a roham típusát"
            },
            "firstSeizureForm": {
              "title": "Adja meg az első lefoglalás dátumát",
              "subtitle1": "Menjen vissza, és emlékezzen minden első rohamra.",
              "subtitle2": "Határozza meg az első roham időpontját a lehető legpontosabban.",
              "subtitle3": "Ezt rohamnak nevezzük {name} (a típusát később határozzuk meg vezető orvosával együtt).",
              "sickSince": "Rendben, ezért beteg vagy:"
            },
            "quantityAndRegularity": {
              "title": "Az elsőhöz hasonló rohamok a mai napig tartanak, jelezze, hogy:",
              "knowsAllDates": "Tudja az összes roham dátumát?",
              "quantityYes": "Ettől az első rohamtól a mai napig megjelentek",
              "quantityNo": "Az elsőhöz hasonló roham többé nem fordult elő",
              "quantityUndefined": "Olyan sok volt, hogy nem lehet pontos számot adni",
              "seizuresQuantity": "A rohamok száma",
              "yes": "Igen",
              "no": "Nem",
              "regularOccurrence": "Rendszeresen előfordulnak epizódok?"
            },
            "knowsAllDates": {
              "subtitle": "Töltse ki a következő rohamok dátumát",
              "date": "Dátum",
              "remove": "Távolítsa el",
              "add": "Dátum hozzáadása"
            },
            "frequency": {
              "title": "Adja meg a gyakoriságot – adja meg, hogy egy adott időszakban általában hány roham fordul elő:",
              "in": "Ban ben"
            },
            "breaks": {
              "title": "Voltak-e szünetek, amikor nem volt roham?",
              "subtitle": "Határozza meg azokat a szüneteket, amikor nem volt roham",
              "date": "Dátum",
              "to": "nak nek",
              "remove": "Távolítsa el",
              "add": "Időszak hozzáadása"
            }
          },
          "Intro": {
            "title1": "Az itt megadott információkat megosztjuk orvosával. Segítenek helyesen diagnosztizálni és kezelni.",
            "fillSurvey": "Töltse ki az egészségügyi felmérést",
            "inVisit": {
              "title1": "A távkonzultáció folyamatban van.",
              "title2": "Ideje orvosával együtt kitölteni a kérdőívet.",
              "title3": "Kezelőorvosa segít megválaszolni a tisztázatlan kérdéseket.",
              "next": "Következő"
            }
          },
          "PsychomotorDevelopment": {
            "introTitle": "Pszichomotoros fejlődés",
            "introSubtitle1": "Ezen a ponton szeretnénk meghatározni, hogy milyen az általános pszicho-fizikai állapota.",
            "introSubtitle2": "Válaszainak köszönhetően az orvos kiválasztja az optimális terápiát.",
            "introSubtitle3": "Ne feledje, ha bármely kérdés nem világos, válaszolhat rá orvosával az első konferenciahívás során.",
            "stages": {
              "psychoPhysicalState": "Fizikai és lelki állapot",
              "senses": "Érzékek",
              "drugs": "Kábítószer"
            },
            "psychoPhysicalState": {
              "overall": "Határozza meg a pszichomotoros fejlődést",
              "retardation": "A pszichomotoros retardáció (fogyatékosság) meghatározása",
              "iq": "Határozza meg a páciens IQ-ját",
              "notTested": "Nem tesztelt",
              "tested": "Tesztelve",
              "iqValue": "Adja meg az IQ értéket",
              "speech": "Aktív és passzív beszéd. (Beszéd és beszéd megértése)",
              "mentalDisorders": "Pszichiátriai betegségek története",
              "neurologicalSyndrome": "Megerősített neurológiai szindróma",
              "walk": "Séta",
              "sex": "Szex"
            },
            "senses": {
              "nystagmus": "Van-e nystagmusa a betegnek?",
              "vision": "Látomás",
              "hearing": "Meghallgatás"
            },
            "drugs": {
              "smoking": "Dohányzó",
              "alcohol": "Alkohol",
              "narcotics": "Kábítószer"
            },
            "mentalDisorders": {
              "psychosis": "Pszichózis jelenleg vagy a múltban",
              "depression": "Depresszió jelenleg vagy a múltban",
              "autism": "Autizmust diagnosztizáltak",
              "adhd": "ADHD és hasonlók",
              "mentalDisordersNotKnown": "Hiányzik vagy nem ismert"
            },
            "neurologicalSyndrome": {
              "piramidalDeficit": "Piramis hiány",
              "brainDeficit": "Agy",
              "sensoryDisturbance": "Érzékszervi zavar",
              "peripharalParesis": "Perifharalis parézis",
              "noNeurologicalSyndrome": "Nem vagy nem ismert"
            }
          },
          "SeizuresCharacteristics": {
            "introTitle": "A roham jellemzői",
            "introSubtitle1": "Ne feledje, hogy Ön egy tipikus rohamot vagy rohamot ír le. Egy roham leírásakor pontosan megadhatja, mi történt. Több roham esetén egyes rohamok kissé eltérhetnek egymástól, ezért felsorolja véletlenszerű jellemzőit. Itt leírja a lefoglalás előtti állapotokat: trailerek, azonnali események és a roham szakaszai (kezdet, folytatás, befejezés és a roham utáni esemény). Általános információkat is ad a rohamról.",
            "introSubtitle2": "Minden információ fontos, és hatással lehet a diagnózis orvos általi helyettesítésére, próbáljon meg minél pontosabb és pontosabb lenni.",
            "question": "Kérdés",
            "seizure": "A roham típusa",
            "emptySeizureTypesInfo": "Először meg kell határoznia a rohamtípusokat a „Rohamtörténet” lapon",
            "stages": {
              "trailers": "Roham\npróféciák",
              "before": "Lefoglalás előtt\nfázis",
              "start": "Első roham\ntünet",
              "next": "Következő roham\ntünet",
              "end": "Utolsó tünet\negy rohamról",
              "after": "Után\nroham",
              "feature": "Roham\njellemzők",
              "premonition": "Előjel"
            },
            "trailers": {
              "trailerPrecise": "Léteznek-e (vagy előfordultak-e, ha csak 1 roham volt) egy roham konkrét előfutára?",
              "trailerPreciseDescription": "Az előrejelzés a roham többé-kevésbé pontos előérzete. Explicit trailer alatt egy konkrét, leírható érzést értünk: szorongás, szomorúság, izgalom stb. Néha a trailer nem definiálható, a beteg nehezen tudja leírni. Hosszú jelenség, órákkal (ritkábban napokkal) a roham előtt jelentkezik, ami megkülönbözteti a rohamot közvetlenül megelőző aurától. Egyes betegeknél csak bizonyos idő elteltével fejlődik ki a roham érzékelésének képessége.",
              "trailerUnspecified": "Vannak (vagy csak 1 roham volt) nem meghatározott pótkocsik egy lefoglaláshoz?",
              "trailerUnspecifiedDescription": ""
            },
            "before": {
              "beforeAura": "Roham előtti aura",
              "beforeAuraDescription": "Közvetlenül előtte kezdődő roham érzése (általában másodpercekkel)",
              "beforeNystagmus": "Nystagmus/kóros szemmozgások",
              "beforeNystagmusDescription": "Volt nystagmus vagy szemgolyó mozgási zavar közvetlenül a roham előtt?",
              "beforeImmobility": "Részleges mozdulatlanság vagy mozdulatlanság",
              "beforeImmobilityDescription": "Volt-e részleges mozdulatlanság (vagy mozgáskorlátozottság) vagy teljes mozdulatlanság közvetlenül a roham előtt?"
            },
            "start": {
              "startFaint": "Eszméletvesztés",
              "startFaintDescription": "Az eszméletvesztés volt a roham első jele?",
              "startLossConsciousness": "A tudatosság teljes elvesztése",
              "startLossConsciousnessDescription": "A teljes tudatvesztés volt a roham első jele?",
              "startLossConsciousnessPartially": "A tudatosság részleges elvesztése",
              "startLossConsciousnessPartiallyDescription": "A részleges tudatvesztés volt a roham első jele? Megzavarta a tudatosság bármely területét",
              "startBodyConvulsions": "Egész testes görcsök (általánosított)",
              "startBodyConvulsionsDescription": "Az egész testet érintő görcs volt a roham első jele?",
              "startBodyPartConvulsions": "A testrészek görcsei (gócos)",
              "startBodyPartConvulsionsDescription": "A testrészek görcsei voltak a rohamok első jelei?",
              "startFaceConvulsions": "Arcrángások",
              "startFaceConvulsionsDescription": "Az arcrángások (általános vagy fokális) voltak a roham első tünete?",
              "startRhythmicConvulsions": "Ritmikus görcsök",
              "startRhythmicConvulsionsDescription": "Ezek a görcsök a roham első tüneteként ritmikusak voltak?",
              "startBodyStiffness": "Egész testre kiterjedő merevítés",
              "startBodyStiffnessDescription": "A roham első tünete az egész test tónusos megmerevedése volt?",
              "startLimbEjection": "Epilepsziás görcs",
              "startLimbEjectionDescription": "A roham első tünete nyugati típusú epilepsziás roham volt?",
              "startHyperkineticSymptoms": "Hiperkinetikus tünetek",
              "startHyperkineticSymptomsDescription": "A roham első tünete a nagyobb hiperkinetikus tünetek megjelenése volt: pedálozás, többirányú kismedencei lökés és hasonlók?",
              "startEyeMovementDisorders": "Nystagmus vagy kóros szemmozgások",
              "startEyeMovementDisordersDescription": "A roham első tünete nystagmus vagy kóros szemmozgás volt?",
              "startEyeTurnPernament": "A szemgolyók eltérése/forgása",
              "startEyeTurnPernamentDescription": "A roham első tünete az volt, hogy a szemgolyó a 4 oldal egyikére fordult?",
              "startBodyTurn": "A test és/vagy a fej oldalra fordítása",
              "startBodyTurnDescription": "A roham első tünete a test és/vagy a fej oldalra fordulása volt?",
              "startGroundFall": "Esik",
              "startGroundFallDescription": "A roham első tünete a földre zuhanás vagy részleges földre zuhanás volt (pl. térdre)?",
              "startAtony": "Atónia vagy asztatikus roham a test egészén vagy egy részén",
              "startAtonyDescription": "A roham első tünete izmos atonia vagy az egész testet vagy annak egy részét (pl. csak a fejet) érintő asztatikus roham volt?",
              "startBitTongue": "A nyelv vagy a száj elemeinek harapása",
              "startBitTongueDescription": "A görcsroham első tünete a nyelv vagy a száj egyes elemeinek harapása?",
              "startShout": "Sikoly",
              "startShoutDescription": "A sikoltozás volt a roham első tünete?",
              "startSounds": "Verbális és non-verbális hangosítások",
              "startSoundsDescription": "A roham első tünete volt verbális vagy non-verbális hangoskodás?",
              "startSpeechStop": "Letartóztatott beszéd",
              "startSpeechStopDescription": "A letartóztatott beszéd volt a roham első tünete?",
              "startMotorAutomatisms": "Arc- és szájüregi automatizmusok",
              "startMotorAutomatismsDescription": "A roham első tünete az arc- és szájüregi automatizmusok előfordulása: rágás, nyelés, kattogás, pislogás?",
              "startOtherAutomatisms": "Egyéb automatizmusok",
              "startOtherAutomatismsDescription": "A roham első tünete más automatizmusok voltak: egész test, végtagok, vokális perseverációk, szexuális automatizmusok, egyéb összetett automatizmusok?",
              "startImmobility": "A mobilitás vagy mozdulatlanság jelentős csökkenése",
              "startImmobilityDescription": "A roham első tünete a mobilitás jelentős csökkenése/gátlása vagy teljes mozdulatlanság volt?",
              "startLaugh": "Paroxizmális nevetés",
              "startLaughDescription": "A roham első tünete paroxizmális nevetés (zselés roham)?",
              "startChuck": "Csörögve és slamposan",
              "startChuckDescription": "Az első tünet csattanó és csapkodó?",
              "startCry": "Paroxizmális sírás",
              "startCryDescription": "A roham első tünete paroxizmális sírás volt?",
              "startEpigastricSymptoms": "Paroxizmális epigasztrikus tünetek, nyálfolyás, ízváltozás",
              "startEpigastricSymptomsDescription": "A roham első tünete az epigasztrikus tünetek, a nyálfolyás vagy az ízérzés megváltozása volt?",
              "startHeartSymptoms": "Szív autonóm tünetek: szívdobogás/tachy/brady/asystole",
              "startHeartSymptomsDescription": "A roham első tünete cardiovascularis autonóm tünetek voltak: szívdobogás, tachycardia vagy bradycardia, vagy asystole?",
              "startAutonomicSymptoms": "További autonóm tünetek: sápadtság, kipirulás, légzési elégtelenség, kóros pupillamozgás, vizeletürítési/székletüreg",
              "startAutonomicSymptomsDescription": "A roham első tünete volt-e további autonóm tünetek: arckifejezés, kipirulás, légzési nehézség, pupillamozgászavar, vizelési inger/széklet?",
              "startLanguageIssues": "Diszfázia/afázia, diszlexia/alexia és/vagy a beszéd/nyelv megértésének hiánya",
              "startLanguageIssuesDescription": "A roham első tünetei beszédzavarok voltak, mint például diszfázia/afázia, diszlexia/alexia és/vagy a beszéd/nyelv meg nemértése?",
              "startMemoryDisorders": "Memóriazavarok roham alatt (egyéb érzékszervek normálisak)",
              "startMemoryDisordersDescription": "A roham első tünete memóriazavar volt a roham alatt, a többi érzékszerv teljes megőrzése mellett?",
              "startDejaVu": "Tévképzetek: deja vu, deja vecu és hasonlók",
              "startDejaVuDescription": "A rohamtévesztés első tünete volt, mint a déjà vu, déjà vecu vagy hasonlók?",
              "startVisualHallucinations": "Vizuális hallucinációk: sokszínű formák (világos körök, villanások, a látómező egy részének elvesztése vagy vakság (amaurosis) és/vagy kétoldali elsötétülés vagy kifehéredés a látótér elvesztésével.",
              "startVisualHallucinationsDescription": "Vajon a roham első tünete vizuális hallucináció volt-e: sokszínű formák (világos körök, villanások, a látómező egy részének elvesztése vagy vakság (amaurosis)? És/vagy kétoldali elsötétülés vagy kifehéredés a látótér elvesztésével?",
              "startAuditoryHallucinations": "Hallucinációk és vagy hasonló hallási tünetek",
              "startAuditoryHallucinationsDescription": "A roham első tünetei hallucinációk és/vagy hasonló hallási tünetek voltak?",
              "startFeelingFearOrAnxiety": "Paroxizmális szorongás vagy félelem",
              "startFeelingFearOrAnxietyDescription": "A roham első tünete paroxizmális szorongás vagy félelem volt?",
              "startSomatosensorySymptoms": "Fokális szomatoszenzoros tünetek, leggyakrabban bizsergő és/vagy zsibbadás, szúró, csiklandozó, kúszás vagy áramütés érzéssel járó paresztéziák.",
              "startSomatosensorySymptomsDescription": "A roham első tünetei fokális szomatoszenzoros tünetek voltak, leggyakrabban bizsergő és/vagy zsibbadás, csípős, csiklandozó, kúszás vagy áramütés érzéssel járó paresztézia?",
              "startBodyDistortion": "Torz testkép mozgásérzéssel (pl. lebegés) vagy megváltozott testtartással (pl. csavaró mozgás) álló végtagban",
              "startBodyDistortionDescription": "A roham első tünete torz testkép volt mozgásérzéssel (pl. lebegés) vagy megváltozott testtartás (pl. csavaró mozgás) egy álló végtagban?",
              "startEyelidsMyoclonus": "Szemhéj myoklonusos rohamok",
              "startEyelidsMyoclonusDescription": "A roham első tünetei a szemhéj mioklónusos rohamai voltak?",
              "startMyoclonus": "Generalizált myoclonus rohamok",
              "startMyoclonusDescription": "A roham első tünetei generalizált myoklonusos rohamok voltak?"
            },
            "next": {
              "nextFaint": "Eszméletvesztés",
              "nextFaintDescription": "Az eszméletvesztés volt a roham következő jele?",
              "nextLossConsciousness": "A tudatosság teljes elvesztése",
              "nextLossConsciousnessDescription": "A teljes tudatvesztés volt a roham következő jele?",
              "nextLossConsciousnessPartially": "A tudatosság részleges elvesztése",
              "nextLossConsciousnessPartiallyDescription": "A részleges tudatvesztés volt a roham következő jele? Megzavarta a tudatosság bármely területét",
              "nextBodyConvulsions": "Egész testes görcsök (általánosított)",
              "nextBodyConvulsionsDescription": "Az egész testre kiterjedő görcs volt a roham következő jele?",
              "nextBodyPartConvulsions": "A testrészek görcsei (gócos)",
              "nextBodyPartConvulsionsDescription": "A testrészek görcsei voltak a roham következő jelei?",
              "nextFaceConvulsions": "Arcrángások",
              "nextFaceConvulsionsDescription": "Az arcrángások (általános vagy fokális) voltak a roham következő tünete?",
              "nextRhythmicConvulsions": "Ritmikus görcsök",
              "nextRhythmicConvulsionsDescription": "Ezek a görcsök a roham következő tüneteként ritmikusak voltak?",
              "nextBodyStiffness": "Egész testre kiterjedő merevítés",
              "nextBodyStiffnessDescription": "A roham következő tünete az egész test tónusos megmerevedése volt?",
              "nextHyperkineticSymptoms": "Hiperkinetikus tünetek",
              "nextHyperkineticSymptomsDescription": "A roham következő tünete a nagyobb hiperkinetikus tünetek megjelenése volt: pedálozás, többirányú kismedencei lökés és hasonlók?",
              "nextEyeMovementDisorders": "Nystagmus vagy kóros szemmozgások",
              "nextEyeMovementDisordersDescription": "A roham következő tünete nystagmus vagy kóros szemmozgás volt?",
              "nextEyeTurnPernament": "A szemgolyók eltérése/forgása",
              "nextEyeTurnPernamentDescription": "A roham következő tünete az volt, hogy a szemgolyó a 4 oldal egyikére fordult?",
              "nextBodyTurn": "A test és/vagy a fej oldalra fordítása",
              "nextBodyTurnDescription": "A roham következő tünete a test és/vagy a fej oldalra fordulása volt?",
              "nextGroundFall": "Esik",
              "nextGroundFallDescription": "A roham másik tünete volt a földre zuhanás vagy részleges földre zuhanás (pl. térdre)? Ha az esés volt az 1. tünet, a beteg az esés után is tovább feküdt?",
              "nextBitTongue": "A nyelv vagy a száj egyes elemeinek harapása",
              "nextBitTongueDescription": "A görcsroham következő tünete a nyelv vagy a száj egyes elemeinek harapása?",
              "nextUrinating": "Vizelési inger vagy vizelés",
              "nextUrinatingDescription": "A roham következő tünete vágy vagy vizelés volt?",
              "nextShout": "Sikoly",
              "nextShoutDescription": "A sikoltozás volt a roham következő tünete?",
              "nextSounds": "Verbális és non-verbális hangosítások",
              "nextSoundsDescription": "A roham következő tünete volt valamilyen verbális vagy non-verbális hangoskodás?",
              "nextMotorAutomatisms": "Arc- és szájüregi automatizmusok",
              "nextMotorAutomatismsDescription": "A roham következő tünete az arc- és szájüregi automatizmusok előfordulása: rágás, nyelés, kattogás, pislogás?",
              "nextOtherAutomatisms": "Egyéb automatizmusok",
              "nextOtherAutomatismsDescription": "A roham következő tünete más automatizmusok voltak: egész test, végtagok, vokális perseverációk, szexuális automatizmusok, egyéb összetett automatizmusok?",
              "nextImmobility": "A mobilitás vagy mozdulatlanság jelentős csökkenése",
              "nextImmobilityDescription": "A roham következő tünete a mobilitás jelentős csökkenése/gátlása vagy teljes mozdulatlanság volt?",
              "nextChuck": "Csörögve és slamposan",
              "nextChuckDescription": "A következő tünet csattanós és csapkodó?",
              "nextEpigastricSymptoms": "Paroxizmális epigasztrikus tünetek, nyálfolyás, ízváltozás",
              "nextEpigastricSymptomsDescription": "A roham következő tünete az epigasztrikus tünetek, a nyálfolyás vagy az ízérzés megváltozása volt?",
              "nextHeartSymptoms": "Szív autonóm tünetek: szívdobogás/tachy/brady/asystole",
              "nextHeartSymptomsDescription": "A roham következő tünete kardiovaszkuláris autonóm tünetek voltak: szívdobogás, tachycardia vagy bradycardia, vagy asystole?",
              "nextAutonomicSymptoms": "További autonóm tünetek: sápadtság, kipirulás, légzési elégtelenség, kóros pupillamozgás, vizeletürítési/székletüreg",
              "nextAutonomicSymptomsDescription": "A roham következő tünete volt-e további autonóm tünetek: arckivágás, kipirulás, légzési nehézség, pupillamozgási zavar, vizelési inger/széklet?",
              "nextLanguageIssues": "Diszfázia/afázia, diszlexia/alexia és/vagy a beszéd/nyelv megértésének hiánya",
              "nextLanguageIssuesDescription": "A roham következő tünetei beszédzavarok voltak, mint például diszfázia/afázia, diszlexia/alexia és/vagy a beszéd/nyelv meg nemértése?",
              "nextMemoryDisorders": "Memóriazavarok roham alatt (egyéb érzékszervek normálisak)",
              "nextMemoryDisordersDescription": "A roham következő tünete memóriazavar volt a roham alatt, a többi érzékszerv teljes megőrzése mellett?",
              "nextDejaVu": "Tévképzetek: deja vu, deja vecu és hasonlók",
              "nextDejaVuDescription": "A rohamtévesztés következő tünete volt, mint a déjà vu, déjà vecu vagy hasonlók?",
              "nextVisualHallucinations": "Vizuális hallucinációk: sokszínű formák (világos körök, villanások, a látómező egy részének elvesztése vagy vakság (amaurosis). És/vagy kétoldali elsötétülés vagy kifehéredés a látómezőben.",
              "nextVisualHallucinationsDescription": "Vajon a roham következő tünete vizuális hallucináció volt-e: sokszínű formák (világos körök, villanások, a látómező egy részének elvesztése vagy vakság (amaurosis)? És/vagy kétoldali elsötétülés vagy fehéredés miatti látómező elvesztése?",
              "nextAuditoryHallucinations": "Hallucinációk és vagy hasonló hallási tünetek",
              "nextAuditoryHallucinationsDescription": "A roham következő tünetei hallucinációk és/vagy hasonló hallási tünetek voltak?",
              "nextSomatosensorySymptoms": "Fokális szomatoszenzoros tünetek, leggyakrabban bizsergés és/vagy zsibbadás, szúró, csiklandozó, kúszás vagy áramütés érzésével járó paresztézia.",
              "nextSomatosensorySymptomsDescription": "A roham következő tünetei fokális szomatoszenzoros tünetek voltak, leggyakrabban bizsergés és/vagy zsibbadás, szúrás, csiklandozás, kúszás vagy áramütés érzése?",
              "nextBodyDistortion": "Torz testkép mozgásérzéssel (pl. lebegés) vagy megváltozott testtartással (pl. csavaró mozgás) álló végtagban",
              "nextBodyDistortionDescription": "A roham következő tünete torz testkép volt mozgásérzéssel (pl. lebegés) vagy megváltozott testtartással (pl. csavaró mozgás) egy álló végtagban?"
            },
            "end": {
              "endAnotherSeizure": "Átváltozhat-e ez a roham egy másik rohammá",
              "endAnotherSeizureDescription": "Ez a roham a végén átválthat egy másik rohammá? (Nem egy másik azonos típusú, hanem más típusú).",
              "endBodyConvulsions": "Egész testes görcsök (általánosított)",
              "endBodyConvulsionsDescription": "Az egész testet érintő görcs volt a roham utolsó jele?",
              "endBodyPartConvulsions": "A testrészek görcsei (gócos)",
              "endBodyPartConvulsionsDescription": "A testrészek görcsössége volt a roham utolsó jele?",
              "endFaceConvulsions": "Arcrángások",
              "endFaceConvulsionsDescription": "Az arcrángások (általános vagy fokális) voltak a roham utolsó tünete?",
              "endEyeTurnPernament": "A szemgolyók eltérése/forgása",
              "endEyeTurnPernamentDescription": "A roham utolsó tünete az volt, hogy a szemgolyó a 4 oldal egyikére fordult?",
              "endBodyTurn": "A test és/vagy a fej oldalra fordítása",
              "endBodyTurnDescription": "A roham utolsó tünete a test és/vagy a fej oldalra fordulása volt?",
              "endGroundFall": "Esik",
              "endGroundFallDescription": "A roham utolsó tünete a földre zuhanás vagy részleges földreesés volt (pl. térdre)? Vagy ha a beteg idáig feküdt, akkor is fekszik?",
              "endUrinating": "Vizelési inger vagy vizelés",
              "endUrinatingDescription": "A roham utolsó tünete vágy vagy vizelés volt?",
              "endSounds": "Verbális és non-verbális hangosítások",
              "endSoundsDescription": "A roham utolsó tünete volt verbális vagy non-verbális hangoskodás?",
              "endImmobility": "A mobilitás vagy mozdulatlanság jelentős csökkenése",
              "endImmobilityDescription": "A roham utolsó tünete a mobilitás jelentős csökkenése/gátlása vagy teljes mozdulatlanság volt?",
              "endChuck": "Csörögve és slamposan",
              "endChuckDescription": "Az utolsó tünet csattanós és csapkodó?",
              "endVomit": "Hányás",
              "endVomitDescription": "A hányás volt a roham utolsó tünete?"
            },
            "after": {
              "afterParesisOrParalysis": "Postiktális parézis/bénulás",
              "afterParesisOrParalysisDescription": "Volt-e Todd-típusú postictalis parézis vagy bénulás a roham után?",
              "afterUrinating": "Vizelési inger vagy vizelés",
              "afterUrinatingDescription": "Volt-e vizelési inger vagy vizelési inger a roham után?",
              "afterConfusion": "Postiktális zavartság és/vagy retrográd amnézia",
              "afterConfusionDescription": "Volt-e roham utáni zavartság és/vagy retrográd amnézia a roham után?",
              "afterSleep": "Postiktális alvás",
              "afterSleepDescription": "Volt posztiktális alvás a roham után?",
              "afterSalivaOrBloodySalivaFoam": "Hab az ajkakon / véres hab",
              "afterSalivaOrBloodySalivaFoamDescription": "Volt-e hab vagy véres hab az ajkakon a roham után?",
              "afterHyperventilation": "Hiperventiláció",
              "afterHyperventilationDescription": "Előfordult-e hiperventiláció a roham után?",
              "afterVomit": "Hányás",
              "afterVomitDescription": "Hányás jelentkezett a roham után?"
            },
            "feature": {
              "featureDuration": "A roham időtartama",
              "featureDurationDescription": "Mennyi ideig tart átlagosan egy ilyen támadás?",
              "featureMorningOccurrence": "Reggeli rohamok",
              "featureMorningOccurrenceDescription": "Előfordulnak görcsrohamok a reggeli órákban?",
              "featureAwakeningOccurrence": "Görcsrohamok ébredés előtt/után",
              "featureAwakeningOccurrenceDescription": "A rohamok közvetlenül ébredés előtt vagy közvetlenül után jelentkeznek?",
              "featureNightOccurrence": "Éjszakai rohamok",
              "featureNightOccurrenceDescription": "Előfordulnak-e görcsrohamok éjszakai alvás közben?",
              "featureDayOccurrence": "Nappali rohamok",
              "featureDayOccurrenceDescription": "Előfordulnak rohamok napközben?",
              "featureMany": "Cluster rohamok",
              "featureManyDescription": "Sorozatosan jelentkeznek a rohamok? Sorozat megszakítással vagy anélkül (csoportos rohamok vagy status epilepticus)",
              "beforeSpecificStimuli": "Egy adott inger által kiváltott roham",
              "beforeSpecificStimuliDescription": "Kiválthatja-e a rohamot meghatározott inger vagy állapot (FS, hangok, alváshiány, érzelmek, stressz stb.)?",
              "formChanges": "Változó és polimorf roham",
              "formChangesDescription": "Változik-e ennek a rohamnak a formája és időtartama? Nem tévesztendő össze más típusú rohamokkal, ha azok egyidejűek!"
            },
            "fixCriticals": {
              "title": "A roham vektorának javítása",
              "subtitle": "Megerősített roham",
              "description1": "Az alábbiakban a kiválasztott roham-referenciavektortól nagymértékben eltérő betegek válaszai láthatók.",
              "description2": "Óvatosan kérdezze meg a betegtől a választ, ha nem egyezik a felmérés válaszával 0 javítsa ki.",
              "oldAnswer": "Felmérés 0 válasz",
              "newAnswer": "Új válasz",
              "saveConfirm": "Biztosan felül akarja írni a 0. felmérés ezen részét?",
              "saveSuccess": "A módosítások mentése sikeresen megtörtént",
              "vectorChangedBy": "A vektort megváltoztatta"
            },
            "premonition": {
              "trailer": "Roham előérzet",
              "trailerDescription": "A rohamot megelőzi-e előérzet – a roham előtti percekben/órákban a közelgő roham érzése? Érzés/kijelentés tudatos vagy tudattalan."
            }
          },
          "Comorbidities": {
            "introTitle": "Társbetegségek",
            "introSubtitle1": "Itt szeretnénk meghatározni, hogy vannak-e társbetegségei.",
            "introSubtitle2": "Ne feledje, hogy a betegségek befolyásolhatják az epilepszia lefolyását.",
            "dataSavedMsg": "A társbetegségekkel kapcsolatos információk mentésre kerültek",
            "hasHeadaches": "Fejfájás",
            "answerNo": "Egyik sem",
            "answerYes": "Igen, vannak",
            "answerYesInt": "Igen",
            "disease": "Betegség",
            "typeDisease": "Írja be a betegség kódját vagy nevét",
            "hasPregnancies": "Terhesség",
            "pregnancies": "Terhesség száma",
            "hasChildbirths": "Szülések",
            "childbirths": "Szülések száma",
            "hasPsychiatricDiseases": "Pszichiátriai betegségek",
            "hasNeurologicalDiseases": "Neurológiai betegségek",
            "hasInfectiousDiseases": "Gyulladásos és fertőző betegségek",
            "hasCancerDiseases": "Rák betegségek",
            "hasImmuneDiseases": "Vér- és immunbetegségek",
            "hasEndocrineDiseases": "Endokrin és anyagcsere betegségek",
            "hasEyeDiseases": "Szembetegségek",
            "hasLaryngologicalDiseases": "Gégészeti betegségek",
            "hasHeartDiseases": "A szív és a keringési rendszer betegségei",
            "hasRespiratoryDiseases": "Légzőszervi megbetegedések",
            "hasDigestiveDiseases": "Emésztőrendszeri betegségek",
            "hasSkinDiseases": "Bőrbetegségek",
            "hasBoneDiseases": "Csontbetegségek",
            "hasUrogenitalDiseases": "Genitourináris betegségek",
            "hasDevelopmentalDefects": "Fejlődési, születési rendellenességek és kromoszóma-rendellenességek",
            "hasInjuriesOrPoisoning": "Sérülések és mérgezések",
            "somaticDiseases": "A betegnek olyan betegsége van, amely befolyásolja az epilepszia kezelését",
            "headaches": "A betegnek fejfájása van"
          },
          "DrugsHistory": {
            "introTitle": "Kezelés",
            "introSubtitle1": "Ez a kezelésének története a mai napig.",
            "introSubtitle2": "Írja be a korábban használt gyógyszereket és azok hatását. Minden gyógyszer tartalmazza a hatóanyag nevét teljes és rövidített formában. Ha nem emlékszik rá, ne aggódjon.",
            "introSubtitle3": "Fontos, hogy a megfelelő gyógyszernevet válassza ki.",
            "drug": "Gyógyszer",
            "drugEffects": "A gyógyszer szedésének hatásai",
            "addDrug": "Adjon hozzá gyógyszert",
            "removeDrug": "Távolítsa el a gyógyszert",
            "typeDrug": "Adja meg a gyógyszer nevét",
            "dataSavedMsg": "A kezelési előzmények mentésre kerültek"
          }
        },
        "visitSurvey": {
          "stages": {
            "patientState": "A beteg állapota",
            "patientOpinion": "A páciens véleménye",
            "doctorOpinion": "Orvos véleménye"
          },
          "patientState": {
            "lastSeizureDate": "A látogatás előtti utolsó roham dátuma",
            "wellBeing": "Általános közérzet az utolsó látogatás óta",
            "allSeizureRegistered": "Az utolsó látogatásom óta beírtam/nem írtam be a rohamokat az Eseménynaptárba",
            "additionalIncidents": "A legutóbbi látogatás óta a következő események történtek - az Eseménynaptárban szereplő eseményeken kívül"
          },
          "patientOpinion": {
            "seizureCountByPatient": "Szubjektív módon a rohamok száma azonos volt?",
            "seizureEvaluationByPatient": "Szubjektíven milyen rosszak voltak a rohamok",
            "currentTreatment": "Jelenlegi kezelésem az",
            "sideEffects": "A jelenlegi kezelés mellékhatásai"
          },
          "doctorOpinion": {
            "seizureCountByDoctor": "A rohamok száma az orvos szerint",
            "seizureEvaluationByDoctor": "A rohamok minősége az orvos véleménye szerint",
            "treatmentEfficiency": "A kezelés hatékonysága az orvos véleménye szerint",
            "treatmentSafety": "A kezelés biztonsága az orvos véleménye szerint"
          },
          "date": "Dátum",
          "fromDate": "Tól től",
          "toDate": "Nak nek",
          "seizure": "Roham",
          "save": "Megment",
          "addSeizure": "Add hozzá a rohamot",
          "noEpisodeAdded": "Lefoglalást nem regisztráltak",
          "episodeTypeLbl": "A rohamok típusai",
          "incidentDescription": "A roham leírása",
          "noSeizure": "Nincs roham",
          "next": "Következő",
          "howManySeizures": "Hány roham az utolsó látogatás óta",
          "countableLbl": "Ott volt",
          "unCountableLbl": "Olyan sok volt, hogy nem lehet pontos számot mondani",
          "seizuresMandatoryError": "Adja hozzá a lefoglalási dátumokat",
          "add": "Hozzáadás",
          "close": "Bezárás",
          "seizuresNotAddedSummary": "A legutóbbi látogatás óta nem jelentkeztek rohamok",
          "seizuresDates": "A rohamok időpontjai",
          "seizuresSinceLastVisit": "A rohamok száma az utolsó látogatás óta",
          "per": "per",
          "addNewSeizureType": "Új rohamtípus hozzáadása",
          "addSeizureTypeDialog": {
            "title": "Új rohamtípus hozzáadása",
            "name": "A roham neve",
            "success": "Új rohamtípus került hozzáadásra"
          },
          "seizurePeriod": "Adja meg az időszakot",
          "addPeriodicSeizure": "Időszak hozzáadása",
          "deletePeriodicSeizure": "Távolítsa el az időszakot",
          "until": "Amíg",
          "seizuresInPeriod": "Az epizódok számítanak"
        },
        "visitCertificates": {
          "create": "Hozzon létre tanúsítványt",
          "doNotCreate": "Ne hozzon létre tanúsítványt",
          "certificateType": "Tanúsítvány típusa",
          "additionalRemarks": "További megjegyzések",
          "additionalRemarksPlaceholder": "Írjon be további megjegyzéseket",
          "generate": "Teremt",
          "certificateContent": "Tanúsítvány tartalma:",
          "pickDate": "Válassz egy dátumot",
          "threeMonths": "3 hónap",
          "sixMonths": "6 hónap",
          "oneYear": "1 év",
          "date": "Dátum",
          "enterProcedureName": "Adja meg az eljárás nevét",
          "enterActivityName": "Adja meg a tevékenység nevét",
          "validationError": "Kötelezően kitöltendő mező",
          "addNext": "Következő tanúsítvány hozzáadása",
          "otherDescriptionLbl": "Írja be a tanúsítvány leírását",
          "endOfYear": "Év vége",
          "addCertificate": "Tanúsítvány hozzáadása",
          "noCertificates": "A tanúsítványok listája üres"
        },
        "sickLeaves": {
          "title": "Beteg levelek",
          "number": "Szám",
          "dateFrom": "Tól től",
          "dateTo": "Nak nek",
          "type": "típus",
          "status": "Állapot",
          "add": "Add hozzá a betegszabadságot",
          "addPopup": {
            "title": "Új betegszabadság kiadása",
            "addBtn": "Betegszabadság kiadása",
            "codes": {
              "a": "a keresőképtelenség 60 napot meg nem haladó szünet után következett be - ugyanaz a betegség okozta, amely a munkaképtelenséget a szünet előtt okozta",
              "b": "terhesség alatti munkaképtelenség",
              "c": "alkoholfogyasztás miatti keresőképtelenség",
              "d": "tuberkulózis miatti keresőképtelenség",
              "e": "a betegbiztosítás megszűnésétől számított legkésőbb 3 hónapon belül keletkezett betegség miatti keresőképtelenség - fertőző betegség esetén, amelynek lappangási ideje meghaladja a 14 napot, vagy olyan betegség esetén, amelynek tünetei a betegség kezdetétől számított 14 napnál hosszabb idő után jelentkeznek"
            },
            "insuredPersonTitle": "Annak a biztosítottnak az adatai, akinek a ZUS ZLA-t kiállítják",
            "type": "A képtelenség oka",
            "pesel": "Pesel",
            "fetchData": "Adatok beszerzése",
            "insuredIn": "Biztosítás helye",
            "change": "változás",
            "payersTitle": "A fizetők kiválasztása, akik számára a tanúsítványokat elkészítik",
            "sickLeavesTitle": "A biztosított korábbi ZUS ZLA-jának listája",
            "detailsTitle": "Információ a keresőképtelenségről",
            "inabilityFrom": "Képtelenség től",
            "inabilityTo": "Képtelenség",
            "daysNumber": "Napok száma",
            "reason": "Ok",
            "careForData": "A gondozott személy adatai",
            "relationship": "Rokonság",
            "relationship1": "baba",
            "relationship2": "házastárs, szülők, mostohaapa, a gyermek szülője, nagyszülők, sógorok, unokák, mostohaanya",
            "relationship3": "más személy",
            "letterCode": "Betűkód",
            "indication": "Orvosi indikáció",
            "addressesTitle": "A biztosított tartózkodási címe a keresőképtelenség ideje alatt",
            "payerName": "Név",
            "payerNip": "KORTY",
            "hasPue": "Van PUE-profilja?",
            "address": "Cím",
            "inabilityRange": "A képtelenség időszaka",
            "disease": "Betegség",
            "number": "Sorozat és szám",
            "status": "Állapot",
            "successToast": "Betegszabadságot kiadtak",
            "addPayerTitle": "Új fizető hozzáadása",
            "search": "Keresés",
            "payerNotFound": "Fizető nem található",
            "addNewPayer": "Új fizető hozzáadása",
            "confirmIssueSickLeave": "Biztos, hogy betegszabadságot akar kiadni?",
            "addNewAddress": "Új cím hozzáadása",
            "workPlace": "Munkahely",
            "icd10Note": "Jegyzet! A fő icd 10 kódot átadják a ZUS-nak"
          },
          "cancelPopup": {
            "title": "Betegszabadság lemondása",
            "reason": "Lemondás oka",
            "confirmBtn": "Törölje a betegszabadságot",
            "confirmCancelSickLeave": "Biztosan lemondja ezt a betegszabadságot?",
            "successToast": "A betegszabadságot törölték"
          }
        }
      },
      "patientVisits": {
        "nextVisit": {
          "title": "Látogatások",
          "nextTitle": "Későbbi látogatások",
          "changeDate": "Dátum módosítása",
          "join": "Kezdje el a látogatást",
          "forego": "Megszünteti",
          "payFor": "Fizessen a látogatásért"
        },
        "lastRecommendation": {
          "title": "Ajánlás az előző látogatásból",
          "pdf": "Összefoglaló letöltése",
          "drug": "Drog",
          "dosages": "Adagolások",
          "careLbl": "Vigyázz magadra",
          "from": "tól től",
          "to": "nak nek"
        },
        "visitList": {
          "title": "Látogatja az archívumot",
          "subtitle": "korábbi látogatások",
          "pdf": "PDF letöltése",
          "date": "Dátum",
          "type": "típus",
          "category": "Kategória",
          "doctor": "Orvos",
          "summary": "Ajánlások",
          "invoice": "Számla"
        }
      },
      "password": {
        "reset": {
          "title": "Jelszó visszaállítás",
          "subtitle1": "Menj vissza",
          "subtitle2": "bejelentkezési képernyő",
          "form": {
            "email": "Email cím",
            "send": "megerősít"
          },
          "response1": "Üzenetet küldtünk a megadott e-mail címre a visszaállítást megerősítő linkkel.",
          "response2": "Kérjük, zárja be ezt a böngészőablakot, és kattintson az elküldött linkre.",
          "resendMessage": "A jelszómódosítási linket ismét elküldtük"
        },
        "resetConfirm": {
          "title": "Erősítse meg a jelszó módosítását",
          "form": {
            "password": "Új jelszó",
            "passwordConfirm": "Jelszó megerősítése",
            "send": "megerősít"
          },
          "response": "A jelszó megváltozott.",
          "loginBtnLbl": "Belépés"
        }
      },
      "patient": {
        "account": {
          "register": {
            "confirm": {
              "outcomeSuccesMsg": "A regisztráció sikeresen befejeződött",
              "outcomeSuccesDetMsg": "Kattintson az alábbi gombra a bejelentkezési oldal eléréséhez",
              "outcomeFailureMsg": "Hiba történt a regisztráció megerősítése közben",
              "outcomeFailureDetMsg": "Kérjük, próbálja újra az e-mailben küldött linkre kattintva, vagy lépjen kapcsolatba a Neuroterminál csapatával",
              "saveBtn": "Belépés"
            }
          },
          "settings": {
            "title": "Beállítások",
            "saveLbl": "Megment",
            "succesMsg": "A beállításokat elmentettük"
          }
        },
        "therapy": {
          "prescriptions": {
            "newLbl": "új receptek",
            "archiveLbl": "vénytárat",
            "dateFromLbl": "Kiadva:",
            "dateToLbl": "Kiállítva",
            "noDataLbl": "Nincsenek receptek",
            "previewLbl": "Előnézet",
            "prescriptionLbl": "Recept",
            "headers": {
              "dateLbl": "Dátum",
              "doctorLbl": "Orvos",
              "numberLbl": "Vénykód",
              "actionsLbl": "Recept"
            },
            "dialog": {
              "downloadLbl": "Letöltés",
              "closeLbl": "Bezárás"
            }
          }
        }
      },
      "doctor": {
        "account": {
          "activation": {
            "title": "Fiók aktiválása a rendszerben",
            "alreadySent": "A dokumentumokat már elküldték",
            "alreadyFinished": "A fiók aktiválása megtörtént",
            "fillInFormMsg": "Kérjük, töltse ki az alábbi adatokat, és kattintson a \"Küldés\" gombra",
            "formAgreementScanLbl": "Megállapodás szkennelés",
            "formInsuranceScanLbl": "Biztosítási szkennelés",
            "acceptTerms1": "elfogadom",
            "acceptTerms2": "használati feltételek",
            "acceptPolicy1": "elfogadom",
            "acceptPolicy2": "Adatvédelmi irányelvek",
            "sendLbl": "Küld",
            "submitMsg1": "Köszönjük, adatait ellenőrizzük.",
            "submitMsg2": "E-mailben visszaigazolást küldünk."
          },
          "settings": {
            "title": "Beállítások",
            "saveLbl": "Megment",
            "successMsg": "A beállítások sikeresen elmentve"
          }
        }
      },
      "prescriptions": {
        "home": {
          "titleLbl": "E-receptek",
          "text1Lbl": "Vénykérés",
          "text2Lbl": "Nincsenek receptkérések"
        },
        "list": {
          "currentLbl": "Jelenlegi",
          "archiveLbl": "Archivált",
          "showLbl": "Előadás",
          "sortNewestLbl": "Legújabb",
          "sortDeadlingLbl": "Legsűrgősebb"
        },
        "details": {
          "medHistLbl": "Kórtörténet",
          "pleadLbl": "receptet kér",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Pesel másolása",
          "checkEwusLbl": "Jelentkezzen be az EWUŚ-ban",
          "addressLbl": "Cím",
          "phoneLbl": "Telefonszám",
          "issueDateLbl": "A recept utolsó kiadásának dátuma",
          "viewLbl": "Vényköteles előnézet",
          "drugsLbl": "Kért gyógyszerek",
          "commentLbl": "Kommentár az orvoshoz",
          "openLbl": "Nyissa meg az e-receptkészítőt",
          "denyInfoLbl": "Ha úgy dönt, hogy ez a kérelem érvénytelen, megtagadhatja az e-recept kiadását",
          "denyLbl": "Tagadni",
          "prescriptionLbl": "Recept",
          "confirmInfo1Lbl": "A felíráskérés elutasítása",
          "confirmInfo2Lbl": "Biztosan megtagadja ennek a receptnek a kiadását?",
          "confirmYesLl": "Igen, tagadom",
          "confirmNoLbl": "Nem",
          "rejectInfoLbl": "Vénykérelem elutasítva",
          "forwardInfoLbl": "Az információkat elküldtük az adminisztrátornak",
          "rejectBtn": "Kérelem elutasítása",
          "rejectDlgTitle": "Miért akarja elutasítani a kérést?",
          "rejectReasonPlaceholder": "Elutasítás oka",
          "forwardPrescription": "Ha nem tud e-receptet kiállítani a betegnek, és szeretné, hogy a recepció intézze az ügyet",
          "forwardBtn": "Az e-recept elutasítása",
          "forwardDlgTitle": "Írjon be egy megjegyzést, amelyet a recepcióra küldünk (adminisztrátor)",
          "rejectCommentDisclaimer": "Az elutasítás okát megküldik a betegnek",
          "characterCount": "Karakterszám:",
          "infoForAdmin": "Az orvos megtagadta az e-recept kiadását:"
        },
        "creator": {
          "typeLbl": "Adjon ki e-receptet ezen keresztül",
          "returnLbl": "Visszatérés",
          "peselLbl": "Pesel",
          "copyPeselLbl": "Pesel másolása",
          "checkEwusLbl": "Jelentkezzen be az EWUŚ-ban",
          "issueDateLbl": "A recept utolsó kiadásának dátuma",
          "viewLbl": "Vényköteles előnézet",
          "drugsLbl": "Kért gyógyszerek",
          "commentLbl": "Kommentár az orvoshoz",
          "addProductLbl": "Adjon hozzá egy másik terméket",
          "govText1Lb": "Menj az oldalra",
          "govText2Lb": "és felíratja a receptet",
          "pinLbl": "Írja be a 4 számjegyű PIN kódot",
          "fileLbl": "Vényköteles PDF-fájl (opcionális)",
          "fileChooseLbl": "Válassza ki a fájl kiválasztásához",
          "issueLbl": "E-recept kiadása",
          "prescriptionLbl": "Recept",
          "notValidMsg": "A vényköteles nyomtatvány tartalma érvénytelen",
          "successMsg": "Vényt adtak ki",
          "prescriptionList": "A kiadott recepteken a következő kódok szerepelnek"
        },
        "product": {
          "drugLbl": "Drog",
          "removeLbl": "Távolítsa el",
          "packageLbl": "Csomag",
          "refundLbl": "Visszatérítés",
          "packNumLbl": "Csomagok száma",
          "dosageLbl": "Jelenlegi adagolás",
          "refundCauseLbl": "Fizetés",
          "recieptType": "Nyugta típusa",
          "permissions": "További engedélyek",
          "description": "Egyedi leírású adagolás",
          "dosage": "Adagolás"
        },
        "ewus": {
          "checkingLbl": "Pesel ellenőrzése EWUŚ-ban",
          "notFoundLbl": "Nem található a beteg az EWUŚ-ban",
          "insuredLbl": "A beteg biztosított",
          "notInsuredLbl": "A beteg nem biztosított",
          "closeLbl": "Bezárás"
        },
        "doctorPass": {
          "titleLbl": "ZUS tanúsítvány jelszava",
          "cancelLbl": "Megszünteti",
          "confirmLbl": "megerősít"
        }
      },
      "eReferrals": {
        "creator": {
          "title": "Új e-ajánló",
          "type": "Hivatkozás típusa",
          "destination": "Klinika / laboratórium / kórházi osztály",
          "icd10": "Diagnózis",
          "selectedIcd9": "Válogatott orvosi eljárások",
          "selectIcd9": "Válasszon legalább egy orvosi eljárást",
          "icd9": "Orvosi eljárás",
          "comment": "Orvos megjegyzése",
          "issueReferral": "E-beutaló kiadása",
          "icdNoResults": "Nincs találat",
          "successMsg": "Az e-beutaló kiállítása sikeresen megtörtént",
          "issueDate": "Kiadás dátuma"
        }
      },
      "header": {
        "labelP1": "Beteg",
        "labelP2": "Portál"
      },
      "epicrisis": {
        "treatmentStart": "A beteg azóta a Neuroszféra gondozása alatt áll",
        "ictalDiagnosis": "ICTAL diagnózis",
        "mainDiagnosis": "ICD10 diagnózis",
        "additionalDiagnosis": "További ICD10 diagnózis",
        "note0Header": "Megjegyzés 0",
        "note0Label": "A páciens kezdeti megfigyelései – a 0. felmérésen vagy a beteg első Neurosphera látogatásán fejeződnek be",
        "epicrisisEntryHeader": "Bejegyzések az epikrízisben",
        "epicrisisEntryLabel": "Ez egy olyan hely, ahol fontos információk találhatók a beteg betegségéről",
        "addEntryLabel": "Bejegyzés hozzáadása",
        "entrySavedMsg": "A bejegyzés el lett mentve",
        "createdBy": "Létrehozta",
        "addedToObservation": "Hozzáadva az epikrízishez",
        "migration": "migráció"
      }
    },
    "component": {
      "consultation": {
        "diagnosisSelection": {
          "searchIcd10": "Diagnosztika keresése",
          "searchIcd10NoResult": "Nincs találat",
          "empty": "A betegdiagnosztikai lista üres",
          "main": "Fő",
          "markAsMain": "Megjelölés főként",
          "icd10": "ICD 10"
        },
        "seizureDiagnosisSelection": {
          "first": "A rohamok első típusa",
          "second": "A rohamok második típusa",
          "third": "A rohamok harmadik típusa"
        },
        "drugDosageDialog": {
          "standardTimes": {
            "morning": "Reggel",
            "noon": "Dél",
            "evening": "Este"
          },
          "standardDose": {
            "morning": "Reggeli adagolás",
            "noon": "Déli adag",
            "evening": "Esti adagolás"
          },
          "addCustomDose": "Adja hozzá az adagot a megadott időpontban",
          "cancel": "Megszünteti",
          "save": "Megment"
        },
        "drugDosageEdition": {
          "drugs": "Gyógyszer",
          "stage": "Lépés",
          "searchDrug": "Adjon hozzá gyógyszert",
          "searchDrugNoResult": "Nincs találat",
          "dosage": "Válassza ki az adagolást",
          "cancelTherapy": "megszünteti",
          "saveTherapy": "mentse a terápiát",
          "addTherapy": "a következő lépés hozzáadása",
          "previousEndDateError": "Nincs meghatározva befejezési dátum az előző lépéshez",
          "previousEndDateBeforeCurrentError": "A terápia befejezési dátumának nagyobbnak kell lennie a jelenleginél",
          "activeSubstanceSearch": "Hatóanyagot tartalmazó gyógyszerek keresése",
          "removeManyTitle": "Automatikusan eltávolítja a gyógyszert a következő terápiákból?",
          "addManyTitle": "Automatikusan hozzáadja a gyógyszert a következő terápiákhoz?",
          "yesSameDosage": "Igen, ugyanazzal az adaggal",
          "yesDifferentDosage": "Igen, de ugyanazzal az adaggal",
          "refreshOrder": "Rendelés frissítése"
        },
        "drugTherapySummary": {
          "current": "Jelenlegi gyógyszeres terápia",
          "future": "A jövő gyógyszeres terápiája",
          "past": "Korábbi gyógyszeres terápia",
          "from": "Tól től",
          "to": "nak nek"
        },
        "drugTherapyHistory": {
          "title": "A gyógyszeres terápia történetének szerkesztése",
          "changeHistory": "Korábbi terápiák",
          "noAccess": "Nem férhet hozzá a páciens gyógyszeres kezelésének történetének módosításához",
          "updateSuccess": "A gyógyszeres kezelések történetét sikeresen frissítették"
        },
        "medicalHistory": {
          "chart": {
            "loadingLbl": "Adatok betöltése",
            "seizuresLbl": "Rohamok",
            "drugsLbl": "Gyógyszer",
            "resultsLbl": "Eredmények",
            "visitsLbl": "Látogatások",
            "eventsLbl": "Események",
            "consultationLbl": "Konzultáció",
            "seizuresNumberLbl": "Rohamok",
            "seizuresDurationLbl": "Időtartam",
            "trendBtn": "Trendvonal",
            "disabledLbl": "Most elérhetetlen",
            "trendBetterLbl": "Trend jobb",
            "trendWorseLbl": "Rosszabb tendencia",
            "rangeLbl": "Adattartomány"
          }
        },
        "patientSummary": {
          "personalInfo": {
            "personalData": "Személyes adatok"
          }
        }
      },
      "doctor": {
        "registration": {
          "form": {
            "firstName": "Név",
            "lastName": "Vezetéknév",
            "pesel": "Pesel",
            "pwz": "PWZ szám",
            "email": "Email cím",
            "password": "Jelszó",
            "passwordConfirm": "Jelszó megerősítése",
            "phone": "Telefon",
            "diploma": "Szkennelt oklevél hozzáadása",
            "isSpecialization": "Neurológiai szakterületem van",
            "isPractise": "Magánorvosi praxisom van",
            "practiceInfoName": "Gyakorló név",
            "practiceInfoNipNr": "KORTY",
            "practiceInfoRpwdlNr": "RPWDL szám",
            "practiceInfoRegonNr": "REGON",
            "practiceAddressStreet": "utca",
            "practiceAddressHouseAndApartmentNr": "Ház/lakás sz",
            "practiceAddressHouseNr": "számú ház",
            "practiceAddressApartmentNr": "számú apartman",
            "practiceAddressZipcode": "Irányítószám",
            "practiceAddressCity": "Város",
            "practiceAddressCountry": "Ország",
            "isApproveDataProcessing": "Hozzájárulok adataim kezeléséhez",
            "send": "Felkérést küld",
            "response1": "Köszönjük a regisztrációját",
            "response2": "E-mailben felvesszük Önnel a kapcsolatot",
            "gotAccountMsg1": "Már van fiókja?",
            "gotAccountMsg2": "Belépés"
          }
        },
        "database": {
          "doctor": {
            "list": {
              "noMedicalHistoryMsg": "A kórtörténeti adatlapot még nem töltötték ki. Kérjük, töltse ki a lehető legtöbb információval, mielőtt vezető orvost választ.",
              "gotoMedicalHistoryLbl": "Ugrás az űrlapra",
              "titleLbl": "Orvos adatbázis",
              "titleDescLbl": "Ha le szeretné cserélni vezető orvosát, kattintson a kiválasztott orvos profiljában a „Válasszon” gombra, majd töltsön ki egy rövid kérdőívet.",
              "subtitleLbl": "Orvosok",
              "patientTypeLbL": "Betegek vezettek",
              "sortTypeLbL": "Rendezés",
              "noResultsLbL": "Nem található a keresési paramétereknek megfelelő orvos",
              "sortTypes": {
                "patientsAsc": "Led betegek felfelé",
                "patientsDesc": "Led betegek ereszkedő",
                "alphabeticAsc": "ABC sorrendben növekvő",
                "alphabeticDesc": "ABC sorrendben csökkenő",
                "remotePriceAsc": "Távoli látogatás árának növekedése",
                "remotePriceDesc": "Távoli látogatás ára csökkenő",
                "stationaryPriceAsc": "Álló látogatási ár emelkedő",
                "stationaryPriceDesc": "Álló látogatási ár csökkenő"
              },
              "content": {
                "juniorExpLbl": "Junior szakértő",
                "standardExpLbl": "Szakértő",
                "seniorExpLbl": "Vezető szakértő",
                "leadLbL": "vezető orvos",
                "bioLbl": "BIO",
                "pricesLbl": "ÁRLISTA",
                "servicesLbl": "SZOLGÁLTATÁSOK KÖRE",
                "remoteLbl": "Távoli látogatás",
                "stationaryLbl": "Álló látogatás",
                "messageLbl": "Üzenet",
                "prescriptionLbl": "Recept"
              }
            },
            "selection": {
              "request": {
                "alert": {
                  "youHaveTimeTo": "Addig kell válaszolni",
                  "toReplyTo": "a beteg ellátására vonatkozó kérésre",
                  "detailsLbl": "Részletek",
                  "closeLbl": "Bezárás"
                }
              }
            }
          },
          "lead": {
            "titleLbl": "Vezető orvos",
            "patientsLbl": "Betegek",
            "visitsLbl": "Látogatások",
            "firstVisitLbl": "Első",
            "subsequentVisitLbl": "Későbbi",
            "bioLbl": "Bio",
            "bookVisitLbl": "Foglaljon látogatást",
            "doctor": {
              "change": {
                "request": {
                  "dialog": {
                    "title1": "Erősítse meg a vezető orvos kiválasztását",
                    "title2": "Biztosan le akarja cserélni a vezető orvosát?",
                    "selectionConfirmMsg": "Biztos, hogy ezt az orvost választja?",
                    "historyCommentLbl": "Írja meg észrevételeit a betegség történetével kapcsolatban",
                    "reasonLbl": "Miért döntött úgy, hogy orvost vált?",
                    "reasonCommentLbl": "Változtasd meg az okot",
                    "evaluationLbl": "Hogyan értékelné jelenlegi orvosát?",
                    "overallCommentLbl": "Megjegyzések a jelenlegi orvossal való együttműködésről",
                    "overallCommentInfo": "Ez lehetővé teszi számunkra az elvégzett szolgáltatások minőségének ellenőrzését, és az okok ellenőrzése után a rosszul teljesítő orvosok eltávolítását a rendszerből.",
                    "confirmLbl": "Erősítse meg a vezető orvos cseréjét",
                    "cancelLbl": "Megszünteti",
                    "reasons": {
                      "punctuality": "Az orvos nem pontos",
                      "result": "Nincs javulás",
                      "communication": "Kommunikációs problémák",
                      "other": "Egyéb"
                    },
                    "evaluations": {
                      "vgood": "nagyon jó",
                      "good": "jó",
                      "average": "átlagos",
                      "bad": "rossz"
                    },
                    "confirmationLbl": "Üzenetet küldtek az orvosnak",
                    "closeLbL": "Bezárás"
                  }
                },
                "response": {
                  "dialog": {
                    "title": "Egyetért azzal, hogy ennek a páciensnek a vezető orvosa legyen?",
                    "firstSeizureLbl": "Az első roham időpontja",
                    "epilepsyDescLbl": "Epilepszia/roham típusa",
                    "drugsUsedLbl": "Eddig használt gyógyszerek",
                    "patientCommentLbl": "Beteg megjegyzése",
                    "acceptLbl": "Egyetértek",
                    "rejectLbl": "nem értek egyet",
                    "rejectReasonLbl": "Elutasítás oka",
                    "confirmLbl": "megerősít",
                    "cancelLbl": "Megszünteti",
                    "rejection": {
                      "reasons": {
                        "punctuality": "Az orvos nem pontos",
                        "result": "Nincs javulás",
                        "communication": "Kommunikációs problémák",
                        "full": "Nincs üresedés",
                        "seizure": "A roham típusa nem felel meg a specializációnak",
                        "age": "Nem megfelelő korprofil",
                        "other": "Egyéb"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "patient": {
        "registration": {
          "form": {
            "email": "Email cím",
            "password": "Jelszó",
            "mandatoryDescription": "a csillaggal jelőlt mezők kötelezőek",
            "passwordConfirm": "Jelszó megerősítése",
            "isAgreeTerms1": "beleegyezem abba, hogy",
            "isAgreeTerms2": "a felhasználási feltételeket",
            "isAgreeTerms3": "",
            "isApprove1": "Elolvastam a személyes adataim kezelésének szabályait",
            "isApprove2": "Neurosphera sp. z o.o. Adatvédelmi irányelvek",
            "isApprove3": "és",
            "isApprove4": "A Neuroterminal S.A. adatvédelmi szabályzata",
            "marketing1and2": "Hozzájárulok olyan marketingkommunikáció (ideértve a kereskedelmi információkat is) fogadásához, amelyek célja szolgáltatások és áruk népszerűsítése, ideértve a személyes adataim felhasználásával e-mail címmel (e-mail üzenetek fogadása) és telefonszámmal (sms és bejövő hívások fogadása) végzett kommunikációt is.",
            "marketing3": "Hírlevelet szeretnék kapni a Neuroterminal S.A.-tól a Neurosphera sp. z o.o.",
            "send": "Regisztráljon fiókot",
            "response1": "Köszönjük a regisztrációját",
            "response2": "A regisztráció visszaigazolását e-mailben küldjük",
            "gotoLoginLbl": "Jelentkezzen be a rendszerbe",
            "gotAccountMsg1": "Már van fiókja?",
            "gotAccountMsg2": "Belépés",
            "consentsTitle": "Szabályzatok és marketing hozzájárulások elfogadása",
            "consentsAccept": "A kiválasztottak elfogadása"
          },
          "confirmation": {
            "header": "Regisztráció megerősítése!",
            "content1": "Elküldtük az aktiváló linket a következő címre:",
            "content2": "Ellenőrizze e-mailjeit, és kattintson az aktiváló linkre",
            "sendAgainPrefix": "Nem kapott e-mailt:",
            "sendAgain": "Küldd újra!",
            "contactReception": "vagy forduljon a recepcióhoz:",
            "resendBlocked": "Ha nem kapta meg a regisztrációs linket tartalmazó e-mailt, kérjük, lépjen kapcsolatba az ügyfélszolgálattal."
          }
        },
        "documentation": {
          "document": {
            "add": {
              "title": "Dokumentum hozzáadása",
              "nameLbL": "Név",
              "typeLbl": "típus",
              "resultDateLbl": "Eredmény dátuma",
              "confirmLbl": "Csatolni",
              "filesToLargeLbl": "Néhány fájl túl nagy volt a hozzáadáshoz",
              "filesEmptyContentLbl": "Egyes fájlok sérültek vagy nincs tartalom",
              "wrongFileType": "Hibás fájltípus. Csak pdf, kép vagy videó engedélyezett.",
              "unsavedFormWarningMsg1": "Az űrlapon nem mentett adatok találhatók",
              "unsavedFormWarningMsg2": "Szeretné feltölteni az adatokat a képernyő elhagyása előtt?",
              "yesLbl": "Igen",
              "noLbl": "Nem",
              "uploadingMsg1": "Fájlfeltöltés folyamatban..",
              "uploadingMsg2": "Nagyobb fájlok esetén ez eltarthat egy ideig",
              "uploadingMsg1Templated": "Fájlfeltöltés folyamatban {XXXXXX}"
            },
            "confirm": {
              "title": "Eltávolítja a dokumentumot?",
              "cancelLbl": "Megszünteti",
              "confirmLbl": "megerősít",
              "successMsg": "A dokumentum eltávolítva"
            },
            "edit": {
              "title": "Dokumentum szerkesztése",
              "nameLbL": "Név",
              "typeLbl": "típus",
              "resultDateLbl": "Eredmény dátuma",
              "fileNameLbL": "Fájl név",
              "cancelLbl": "Megszünteti",
              "confirmLbl": "megerősít",
              "changedMsg": "A dokumentum sikeresen módosítva"
            },
            "viewer": {
              "closeLbl": "Bezárás",
              "typeNotSupported": "Ez a fájltípus nem támogatott a megjelenítőben"
            },
            "files": {
              "selection": {
                "title1": "Drag and drop",
                "title2": "ide felvenni kívánt fájlokat",
                "title3": "vagy",
                "title4": "Fájlok kiválasztása",
                "title5": "a számítógépről"
              }
            },
            "list": {
              "title1": "Orvosi dokumentáció",
              "title2": "Itt őrizheti meg mediális dokumentációját",
              "title3": "A vizsgálati eredmények hozzáadásával segíthet az orvosnak a pontos diagnózis felállításában és növelheti a kezelés esélyét.",
              "noDataLbl": "Nincs adat",
              "noResultsLbl": "Nincs dátum",
              "loadingLbl": "Dokumentáció betöltése",
              "downloadLbl": "Letöltés",
              "showLbl": "Előadás",
              "editLbl": "Szerkesztés",
              "removeLbl": "Távolítsa el",
              "headers": {
                "type": "típus",
                "name": "Név",
                "fileName": "Fájl",
                "resultDate": "Kiadott",
                "additionDate": "Hozzáadva"
              },
              "filters": {
                "documentTypeLbl": "Előadás",
                "documentTypeAllLbl": "Mutasd az összeset",
                "resultDateFromLbl": "Eredmény dátuma -tól",
                "resultDateToLbl": "Eredmény dátuma ig"
              },
              "markCompleted": "Megjelölés befejezettként",
              "markCompletedDescription": "Ha a leírást kitöltöttnek jelöli, azt a recepció elküldi a betegnek. Ha szeretné, opcionális szöveget is hozzáadhat a fogadáshoz",
              "isCompleted": "Befejezve"
            },
            "disk": {
              "space": {
                "filledLbl": "{filled} GB/{available} GB megtelt"
              }
            }
          }
        },
        "nearest": {
          "visits": {
            "forthcomingLbl": "közelgő látogatások",
            "forthcomingConsultLbl": "(konzultációk)",
            "noVisitMsg": "Nincs közelgő látogatás.",
            "noVisitZeroMsg": "Nincs közelgő látogatás. Kérjük, foglaljon nulla látogatást.",
            "noVisitZeroDisclaimerMsg": "A nulladik látogatás során az orvos elemzi az Ön kórtörténetét, és összeállítja egészségügyi tervét",
            "sosVisitLbl": "SOS",
            "bookVisitLbl": "Könyvlátogatás",
            "cannotBookVisitMsg": "Nem tud látogatást foglalni, mivel az 'Saját adatok' részben nem volt minden mező kitöltve",
            "editLbl": "Szerkesztés",
            "cancelLbl": "Megszünteti",
            "payLbl": "Fizetés",
            "absentConsultation": "Hiányzó konzultáció - a beteg részvétele nélkül",
            "dialog": {
              "paymentPending": "Várjuk a fizetés visszaigazolását",
              "paymentError": "A fizetés meghiúsult",
              "payTill1": "Addig kell fizetni",
              "payTill2": "ellenkező esetben a wisit törlésre kerül.",
              "pay": "Fizessen egy látogatásért"
            }
          }
        },
        "treatment": {
          "plan": {
            "titleLbl": "egészségügyi terv",
            "inaccessibleLbl": "megközelíthetetlen",
            "noVisitZeroDisclaimerMsg": "A nulladik látogatás során kiválasztja az egészségügyi tervet."
          },
          "prescription": {
            "request": {
              "stockLbl": "Raktáron {days} napig",
              "otherQuestLbl": "Más gyógyszer kell",
              "otherDrugLbl": "Írja be a gyógyszer nevét a megjegyzésbe. Ne felejtse el megadni a gyógyszer pontos nevét.",
              "confirmTitleLbl": "Megerősítés",
              "confirmTextLbl": "A rendszer átirányítja az elektronikus fizetési oldalra. Folytatni?",
              "cancelBtn": "Megszünteti",
              "confirmBtn": "megerősít",
              "chooseDoctor": "Válassza ki azt az orvost, akitől receptet szeretne kérni",
              "askForPrescriptionNotAvailable": "Receptigénylést a receptet kiállító orvos felkeresése után tudja majd elküldeni",
              "noLeadDoctorAvailable": "Vezető orvosa jelenleg nem elérhető. Felírási kérelmet az ügyeletes orvosnak küldhet."
            }
          }
        },
        "lead": {
          "doctor": {
            "titleLbl": "vezető orvos",
            "noVisitZeroDisclaimerMsg1": "Ön választja ki vezető orvosát",
            "noVisitZeroDisclaimerMsg2": "nulladik látogatás során",
            "changeLbl": "változás",
            "prescriptLbl": "Kérjen receptet",
            "messageLbl": "Írj egy üzenetet",
            "servicesLbl": "nyújtott szolgáltatások",
            "services": {
              "interview": "interjú",
              "therapy": "beállítási terápia",
              "commission": "kibocsátási jutalék",
              "prescription": "receptet ad ki",
              "documents": "beutaló, igazolás, betegszabadság kiadása"
            },
            "scope": {
              "bullet1": "A beteg első orvosi kapcsolata",
              "bullet2": "Átfogó beteggondozó kiegészítő vizsgálatok rendelése, esetleg szaktanácsadók szakorvoshoz utalása",
              "bullet3": "Tanácsadó, aki a páciens közvetlen támasza és felügyeli a terápiás tervet",
              "bullet4": "A teljes csapat, az iCtal® robot és az orvosi felügyeleti és automatizálási rendszer támogatja"
            }
          }
        },
        "hashtags": {
          "title": "Hashtagek",
          "subtitle": "Itt lehet megjelölni a pácienst előre meghatározott hashtaggal",
          "label": "Hashtagek keresése"
        }
      },
      "finances": {
        "payment": {
          "history": {
            "title": "Településtörténet",
            "headers": {
              "month": "Hónap",
              "sum": "Teljes"
            }
          }
        },
        "invoice": {
          "summary": {
            "text1": "Pénzügyi összefoglaló",
            "text2": "Ott megtalálja a számlázáshoz szükséges adatokat",
            "text3": "Kérdése van a számlákkal kapcsolatban?",
            "text4": "Lépjen kapcsolatba pénzügyi osztályunkkal",
            "btn_lbl": "Összefoglaló letöltése"
          }
        },
        "general": {
          "summary": {
            "title": "Pénzügy",
            "return": "Vissza",
            "table": {
              "headers": {
                "label": "A látogatás típusa",
                "price": "Ár",
                "amount": "Összeg",
                "sum": "Teljes"
              },
              "summary": "Teljes"
            }
          }
        }
      },
      "genericDialog": {
        "approve": "Igen",
        "cancel": "Nem",
        "close": "Bezárás"
      },
      "videochat": {
        "waitingForPatient": "Várakozás a páciens csatlakozására",
        "waitingForDoctor": "Várja az orvos csatlakozását",
        "connectionError": "Csatlakozási hiba történt",
        "visitEnded": "A telekonferencia véget ért. A munkamenet megszakadt.",
        "deviceLoadError": "Hiba az eszközök betöltésekor",
        "accessDenied": "Kérjük, adjon hozzáférést a mikrofonhoz és a kamerához a böngészőben, és szükség esetén frissítse az oldalt",
        "accessDeniedAlert": "Nincs hozzáférés a mikrofonhoz és a kamerához",
        "missingAudioInput": "A mikrofon eszköz hiányzik",
        "missingVideoInput": "A kamera eszköz hiányzik",
        "accessGrantedBut": "Az engedélyeket azonban megkapták:",
        "cannotConnectCamera": "Nem lehet csatlakozni a kamerához",
        "cannotConnectMicrophone": "Nem lehet csatlakozni a mikrofonhoz",
        "deviceAlreadyInUse": "Győződjön meg arról, hogy az eszköz még nincs használatban, majd próbálja újra",
        "chooseDeviceDialog": {
          "chooseDevices": "Válasszon eszközöket",
          "visitStartMsg1": "A látogatás órakor kezdődik",
          "visitStartMsg2": "A zökkenőmentes megbeszélés érdekében válassza ki a használni kívánt audio- és videoeszközöket",
          "camera": "Kamera",
          "microphone": "Mikrofon",
          "speakers": "Hangszórók"
        },
        "dialog": {
          "confirm": "megerősít"
        },
        "tooltip": {
          "disableAudio": "Hang letiltása",
          "enableAudio": "Hang engedélyezése",
          "disableVideo": "Videó letiltása",
          "enableVideo": "Videó engedélyezése",
          "terminate": "Fejezze be online látogatását"
        },
        "confirm": {
          "warningMsg1": "A látogatás még folyamatban van",
          "warningMsg2": "Be akar fejezni egy telekonferencia-hívást egy folyamatban lévő látogatás miatt?"
        }
      },
      "updateMedicalEventDialog": {
        "title": "Esemény szerkesztése",
        "changesSaved": "A módosítások sikeresen mentve"
      },
      "addMedicalEventDialog": {
        "title": "Esemény hozzáadása",
        "descriptionPlaceholder": "Írja be a rövid leírást",
        "patientSeizureTypePlaceholder": "A roham típusa",
        "from": "Tól től",
        "to": "Nak nek",
        "duration": "Időtartam",
        "cancel": "Megszünteti",
        "approve": "Megment",
        "addNext": "Adj hozzá egy másikat",
        "delete": "Töröl",
        "addSuccessMsg": "Az esemény hozzáadva",
        "noSeizureTypes": "Új roham hozzáadásához meg kell határoznia a rohamtípusokat az állapotfelmérésben",
        "goToHealthSurvey": "Menjen az egészségügyi felméréshez",
        "serialSeizures": "Sorozatos rohamok",
        "seizuresCount": "A rohamok számítanak"
      },
      "user": {
        "settings": {
          "sms": {
            "title": "SMS-értesítések"
          },
          "other": {
            "title": "Egyéb"
          }
        }
      },
      "visitChat": {
        "title": "Látogassa meg a csevegést",
        "loading": "Az üzenetek betöltése folyamatban van...",
        "emptyList": "Üzenetek listája üres",
        "inputLabel": "Írj egy üzenetet"
      },
      "payu": {
        "banner": {
          "installmentPaymentAvailable": "Részletfizetés itt érhető el",
          "startsFrom": "tól től",
          "decisionIn": "döntés be",
          "decisionInSeconds": "{val} másodperc",
          "checkConditions": "Ellenőrizze a feltételeket"
        }
      },
      "maintenance": {
        "newAddress": "<b>Figyelem!</b> Módosítjuk a varsói fiók címét. Új címünk: <b>ul. Taneczna 30, 02-829 Varsó (Ursynów)</b>"
      }
    },
    "ui": {
      "help": "Segítség",
      "policy": "Adatvédelmi irányelvek",
      "logout": "Kijelentkezés",
      "navigation": {
        "home": "Kezdőképernyő",
        "account": {
          "name": "Rólam",
          "personalData": "A fiókom",
          "medicalDocuments": "Orvosi dokumentumok",
          "medicalHistory": "Egészségügyi felmérés"
        },
        "messages": "üzenetek",
        "therapy": "Terápia",
        "visits": {
          "name": "Látogatások",
          "archive": "Látogatások archívuma",
          "doctorDatabase": "Orvos adatbázis"
        },
        "myVisits": "A látogatásaim",
        "patients": "Betegek",
        "ediagnosis": "IctalLab",
        "calendar": "Naptár",
        "workplan": "Munkaterv",
        "visitsAndDuties": "Látogatások és kötelességek",
        "finances": "Pénzügyek",
        "ehealth": {
          "name": "E-egészségügy",
          "prescriptions": "E-receptek",
          "referrals": "E-ajánlók"
        },
        "settings": {
          "name": "Beállítások",
          "notifications": "Értesítések",
          "regulations": "Előírások",
          "payments": "Kifizetések"
        },
        "documentation": "Dokumentáció",
        "examinations": "Vizsgálatok"
      },
      "closeDialog": "Bezárás",
      "visitSummary": "visit_summary",
      "invoice": "számla",
      "correctionInvoice": "korrekciós_számla",
      "ieAlert": "Sajnos az Internet Explorert már nem támogatjuk. Kérjük, használjon másik internetböngészőt."
    },
    "components": {
      "workPlan": {
        "header": {
          "TIMETABLE": "Terv",
          "TIMETABLE_CHANGES": "Változások a naptárban"
        },
        "editDialog": {
          "workPosition": "Szerkessze a munkaterv pozícióját",
          "unsavedWorkPosition": "Új munkaterv pozíció",
          "vacation": "Nyaralás szerkesztése",
          "unsavedVacation": "Új vakáció",
          "dayOfWeekLabel": "A hét napja",
          "officeLabel": "Hivatal",
          "visitTypeLabel": "Látogatás típusa",
          "startTimeLabel": "Kezdési idő",
          "endTimeLabel": "Idő vége",
          "description": "Leírás",
          "cancel": "Megszünteti",
          "approve": "Jóváhagy",
          "visitType": "Látogatás típusa",
          "visitPlace": "Látogassa meg a helyet",
          "stationaryAndRemote": "Figyelem! Ez a vizittípus lehetővé teszi a betegek számára, hogy helyhez kötött és távoli viziteket is előírjanak",
          "workPlaceMissing": "Az állandó látogatások engedélyezéséhez be kell állítania a munkahelyét a beállítások oldalon"
        },
        "deleteDialog": {
          "text": "Biztosan törli ezt a pozíciót?",
          "cancel": "Megszünteti",
          "confirm": "Töröl"
        },
        "visitType": {
          "unavailable": "Kötelesség",
          "any": "Bármilyen fajta",
          "stationaryOrRemore": "Helyhez kötött / távoli"
        },
        "event": {
          "duty": {
            "yourDutyToChange": "Változtatásra címkézve",
            "state": {
              "AVAILABLE": "Vállalható",
              "NOT_AVAILABLE": "Vám nem áll rendelkezésre",
              "TO_CHANGE": "Kérés a szolgálat átvételére",
              "PLANNED": "Tervezett kötelessége"
            }
          }
        },
        "description": "Tervezze meg munkáját – válassza ki, mikor áll rendelkezésére látogatásokra",
        "selectedWorkPlan": "Kiválasztott munkaterv:",
        "newWorkPlan": "Új munkaterv:",
        "untilFurtherNotice": "további értesítésig",
        "couldNotDeleteActiveWorkPlan": "Nem sikerült eltávolítani az aktív munkatervet",
        "validFrom": "Től érvényes",
        "validTo": "Érvényes",
        "resetChanges": "Visszaállítás",
        "saveChanges": "Megment",
        "unsavedChanges": "Vannak nem mentett változtatások a naptárban",
        "visits": "Látogatások",
        "newPlan": "Új terv",
        "messages": {
          "deleteSuccess": "A munkaterv törölve",
          "addSuccess": "Munkaterv hozzáadva",
          "updateSuccess": "A munkaterv frissült"
        }
      },
      "doctor": {
        "calendar": {
          "todayBtn": "Ma"
        },
        "messages": {
          "title": "Aktuális üzenetek",
          "show": "Előadás",
          "item": {
            "systemLbl": "Rendszerüzenet",
            "types": {
              "prescribeLbl": "Recept kibocsátására vonatkozó kérelem innen: {sender}",
              "prescribeLblMovedFrom": "{movedFrom} helyett",
              "selectionLbl": "Kérés vezető orvosnak lenni innen: {sender}",
              "noteLbl": "Megjegyzés a páciens számára: {patient} hozzáadva"
            }
          },
          "new": "Új"
        },
        "visits": {
          "title": "Napi terv",
          "show": "Előadás",
          "types": "Látogatás típusa",
          "categories": "Látogassa meg a kategóriát",
          "type": {
            "remote": "Online látogatások",
            "stationary": "Helyhez kötött"
          },
          "item": {
            "statusPayed": "fizetett",
            "statusNotPayed": "nem fizették ki",
            "statusDone": "Kész",
            "statusToMove": "mozogni",
            "visitBtnLbl": "látogatás",
            "visitStartBtnLbl": "kezdje el a látogatást",
            "historyBtnLbl": "kórtörténet",
            "numberLbl": "Látogatások száma",
            "causeLbl": "Látogassa meg az okot",
            "lastVisitLbl": "Utolsó látogatás:",
            "diagnosisLbl": "Diagnózis",
            "diagnosisNoEpilepsyLbl": "Nem epilepsziás",
            "diagnosisNoDataLbl": "Nincs diagnózis",
            "drugsLbl": "Jelenlegi gyógyszerek",
            "drugsNoneLbl": "Egyik sem",
            "frequencyLbl": "A rohamok gyakorisága",
            "moodLbl": "Hangulat",
            "summaryLbl": "Látogassa meg az összefoglalót",
            "visitDuration": "Időtartam:"
          }
        },
        "form": {
          "pricelist": {
            "titleInfo": "Új árlista tétel",
            "cancelBtn": "Megszünteti",
            "saveBtn": "megerősít"
          },
          "avatar": {
            "titleInfo": "Válasszon képet",
            "addImageBtn": "Kép fájlrendszerből",
            "cancelBtn": "Megszünteti",
            "saveBtn": "megerősít"
          },
          "address": {
            "streetLbl": "utca",
            "homeBuildingLbl": "Épületszám / lakás",
            "apartmentLbl": "számú apartman",
            "buildingLbl": "Házszám",
            "cityLbl": "Város",
            "zipcodeLbl": "Irányítószám",
            "countryLbl": "Ország"
          }
        }
      },
      "message": {
        "history": {
          "list": "Üzenetek listája",
          "title": "üzenetek",
          "newMessage": "Új üzenet",
          "new": "Új",
          "search": "Keresés",
          "visitEnded": "vége lett",
          "filters": {
            "current": "Jelenlegi",
            "archive": "Archívum",
            "contact": "Keresés"
          },
          "noMessages": "Nincsenek üzenetek",
          "noTherapies": "Nincs terápia",
          "born": "született",
          "historyBtnLbl": "Kórtörténet",
          "return": "Visszatérés",
          "send": "Küld",
          "sendAndClose": "Küldje el és zárja be a látogatást",
          "closeNoAnswer": "Az üzenet nem igényel választ",
          "noAnswerContent": "Köszönöm az üzenetedet. Üdvözlettel",
          "confirmNoAnswer": "Biztos, hogy válasz nélkül szeretné befejezni a látogatást?",
          "hoursToAnswer": "h",
          "daysToAnswer": "napok",
          "timeIsUp": "Lejárt az idő",
          "hoursToAnswerRich": "{hours} órája van válaszolni",
          "daysToAnswerRich": "{days} napja van válaszolni",
          "timeIsUpRich": "A válaszadás meghirdetett ideje már lejárt. Add meg minél előbb!",
          "summary": "Összegzés",
          "medicalHistory": "Kórtörténet",
          "sort": {
            "label": "Előadás",
            "newest": "Legújabb",
            "alpha": "Betűrendben",
            "deadline": "Legsűrgősebb"
          },
          "sendAndCloseDialog": {
            "header": "Biztos, hogy befejezi a szálat?",
            "title": "Ha lezárja a látogatást, a páciens nem tud válaszolni az üzenetére",
            "subtitle": "Ha azt szeretné, hogy a páciens válaszolhasson az üzenetére, kattintson a „Küldés” gombra."
          },
          "newMessageOnDuty": "Új üzenet az ügyeletes orvosnak",
          "unidirectional": {
            "send": "Egyirányú üzenet küldése",
            "sendTitle": "Egyirányú üzenet a címzettnek",
            "sendSubtitle": "A páciens nem tud válaszolni erre az üzenetre",
            "sendSuccess": "Egyirányú üzenetet küldtek a páciensnek",
            "infoForPatient": "Ez egy üzenet - információ egy orvostól. Ebben az üzenetben orvosa további információkkal látta el Önt betegségével kapcsolatban. Az üzenetre nem tud válaszolni, ha további információt szeretne, írjon új üzenetet - keresse fel orvosát"
          },
          "addToEpicrisis": "Hozzáadás az epikrízishez",
          "textAddedToEpicrisis": "Bejegyzés hozzáadva az epicrisishez"
        },
        "respondeDeadlineAlert": {
          "youHaveTimeTo": "Addig kell válaszolni",
          "toReplyTo": "az üzenethez",
          "reply": "Válasz"
        },
        "patientAlerts": {
          "healthSurveyContent": "Az egészségügyi felmérést ki kell tölteni",
          "healthSurvey": "Tovább a felméréshez"
        },
        "newMessageVisit": {
          "noDoctorTitle": "Nincs kijelölve vezető orvos",
          "title": "Konzultáljon orvosával - küldjön üzenetet",
          "descriptionLbl": "Leírás",
          "description": "Az üzenetlátogatás egy rövid kérdés, amelyet az orvosnak küldenek. Az orvos válaszol rá. Ha szeretné használni ezt a kapcsolatfelvételi formát, fizesse ki látogatási üzenetét az \"Új üzenet\" gombra kattintva. Ha bármilyen aggálya van egészségével kapcsolatban, vagy hosszabb konzultációra van szüksége - kérjen RENDSZERES időpontot orvosával.",
          "messageVisitsDisabled": "Sajnáljuk, de az orvosa nem nyújt ilyen típusú szolgáltatást.",
          "noLeadDoctorAvailable": "Vezető orvosa jelenleg nem elérhető. Üzenetet írhatsz az ügyeletes orvosnak."
        },
        "drugStock": {
          "lowMsg": "A következő gyógyszerek készletei kifogyóban vannak:",
          "notInitialized": "Inicializálja gyógyszerkészletét:",
          "daysLbl": "napok"
        },
        "toast": {
          "text": "Új bejövő üzenet a következőtől"
        },
        "newPaidMessageAlert": {
          "content": "Fizetett az orvosnak küldött üzenetért",
          "reply": "Írj egy üzenetet"
        },
        "unidirectionalMessageAlert": {
          "content": "Üzenetet kapott - információ egy orvostól",
          "reply": "Tekintse meg az üzenetet"
        },
        "personalDataAcceptanceAlert": {
          "title": "Személyes adatok megerősítése",
          "subtitle": "Fontosak az Ön személyes adatai a Neuroterminál rendszerében – ügyeljen arra, hogy azok naprakészek legyenek! Ennek köszönhetően teljes körű tapasztalatot szerezhet szolgáltatásainkból.",
          "changeButton": "Módosítsa személyes adatait",
          "acceptButton": "Erősítse meg személyes adataimat"
        }
      },
      "utility": {
        "multipleSelect": {
          "all": "Minden"
        },
        "confirmationDialog": {
          "title": "biztos vagy ebben?",
          "text": "Biztos vagy benne, hogy folytatni akarod?"
        },
        "youAreNotAllowedDialog": {
          "title": "Engedélyek szükségesek",
          "text": "Nincs engedélyed. További információért forduljon hozzánk.",
          "goBackToMainPage": "Vissza a főoldalra"
        },
        "phoneInput": {
          "phoneNumberLabel": "Telefonszám",
          "legalGuardPhoneNumberLabel": "A szülő vagy törvényes képviselő telefonja"
        }
      },
      "health": {
        "state": {
          "noData": "Nincs adat",
          "stateNoChange": "Nincs változás",
          "stateBetter": "Jobb",
          "stateWorse": "Rosszabb"
        }
      },
      "calendar": {
        "title": "Látogatások és kötelesség",
        "description": "Az Ön látogatásai az aktuális héten. Kattintson a látogatásra a részletek megtekintéséhez.",
        "visitCancelled": "A látogatást törölték",
        "visitDateChanged": "A látogatás dátuma megváltozott",
        "legend": {
          "duty": "SOS ügyelet",
          "vacation": "Vakáció"
        },
        "filters": {
          "showVisits": "Látogatások megjelenítése",
          "showDuties": "Mutasd meg a kötelességeket"
        },
        "visitDetailsDialog": {
          "title": "Látogassa meg a részleteket",
          "onDay": "tovább",
          "fromHour": "tól től",
          "toHour": "nak nek",
          "close": "Bezárás"
        },
        "dutyDetailsDialog": {
          "title": "Az ügyelet részletei",
          "fromHour": "tól től",
          "toHour": "nak nek",
          "close": "Bezárás",
          "signUpForDuty": "Jelentkezzen szolgálatra",
          "markDutyToChange": "Feladatáthelyezési kérelem",
          "toChangeRequestText": "Szolgálatot kér. Segíthet ennek az orvosnak, ha jelentkezik számára erre a feladatra.",
          "cantMarkDutyAsToChange": "Sajnos nem kérheti ennek a feladatnak az átruházását.",
          "dutyNotAvailable": "Ezt a kötelezettséget már vállalták. Válasszon másik szolgálati dátumot.",
          "cancelToChange": "Vonja vissza az áthelyezési kérelmet"
        },
        "confirmVisitCancellationDialog": {
          "title": "Egy látogatás lemondása",
          "sureToCancelVisit": "Biztosan lemondja a beteg látogatását?",
          "fromDate": ", aminek től kellett megtörténnie",
          "from": "és addig folytassa",
          "to": "nak nek",
          "description": "A pácienstől eltérő időpontot kell választani, vagy a szolgálat felveszi vele a kapcsolatot.",
          "confirm": "Törölje a látogatást",
          "cancel": "Megszünteti",
          "visitAlert": "Ön lemondja azt a látogatást, amely kevesebb, mint 48 órán belül kezdődik! Nem támogatjuk az ilyen gyakorlatokat. További információért kérjük, tekintse meg oldalunkat",
          "termsAndConditions": "Felhasználási feltételek",
          "fromDatePassed": ", amely től történt",
          "fromPassed": "és addig folytatta",
          "visitError": "Ez a látogatás már megtörtént. Győződjön meg arról, hogy valóban le akarja mondani ezt a látogatást"
        },
        "visits": {
          "title": "Orvosi látogatások",
          "description": "Válogatott orvosi időpontok erre a napra. Kattintson egy mezőre a naptárban a részletek megtekintéséhez.",
          "dateSelectPrefix": "Kiválasztott nap",
          "noVisits": "Nincs látogatás",
          "doctors": "Orvosok"
        }
      },
      "duties": {
        "applyDutyDialog": {
          "title": "Duties kiadás",
          "subtitle": "Győződjön meg arról, hogy a módosítások helyesek.",
          "addedDuties": "Hozzáadott feladatok:",
          "cancelledDuties": "Lemondott feladatok:",
          "inHours": "tovább",
          "back": "Vissza a szerkesztéshez",
          "approve": "Megment",
          "acceptTermsAndConditions": "Elfogadom a Dutes Általános Szerződési Feltételeit"
        },
        "alert": {
          "text": "Feladataid vannak:"
        },
        "description": "Iratkozzon fel az SOS ügyeletre. Válassza ki a megfelelő dátumot a naptárban rákattintva"
      },
      "payment": {
        "history": {
          "title": "Fizetési előzmények",
          "filters": {
            "typeAll": "Minden",
            "advFiltLbl": "Kiterjedtebb keresés",
            "advanced": {
              "from": "Kezdő dátum",
              "till": "amíg",
              "to": "Befejezés dátuma",
              "clearLbl": "Egyértelmű",
              "searchLbl": "Keresés"
            }
          },
          "noDataLbl": "Nincs adat",
          "noResultsLbl": "Nem található keresési eredmény",
          "headers": {
            "date": "Dátum",
            "type": "típus",
            "amount": "Összeg",
            "invoice": "Számla",
            "service": "Szolgáltatás neve"
          },
          "pdfLbl": "Letöltés",
          "invoice": "Számla",
          "correctionInvoice": "Javítás"
        }
      },
      "dialog": {
        "generic": {
          "confirmation": {
            "dialog": {
              "defaultCancelLbl": "Megszünteti",
              "defaultConfirmLbl": "megerősít"
            }
          },
          "attention": "Uwaga"
        }
      },
      "patient": {
        "visits": {
          "next": {
            "visit": {
              "info": {}
            }
          },
          "operations": {
            "cancel": {
              "title": "Megerősíti a látogatás törlését?",
              "warning": "A Felhasználási feltételek szerint a látogatás dátumáig 48 órán belül lemondott látogatások esetén a látogatás költségének 50%-ának megfelelő adminisztrációs díjat kell fizetni. A fennmaradó összeget visszautaljuk (a visszaküldés módját megbeszéljük).",
              "reason": "A látogatás lemondásának oka (opcionális)",
              "confirmLbl": "Igen",
              "cancelLbl": "Nem",
              "warningCustom": "Az Általános Szerződési Feltételek szerint a látogatás dátumáig {days} napon belül lemondott látogatások esetén a látogatás költségének {percentage}%-ának megfelelő adminisztrációs díjat kell fizetni. A fennmaradó összeget visszautaljuk (a visszaküldés módját megbeszéljük)."
            },
            "modify": {
              "title": "Válasszon új látogatási dátumot",
              "confirmMsg": "Megerősíti a látogatás dátumának módosítását?",
              "cancelLbl": "Megszünteti",
              "yesLbl": "Igen",
              "noLbl": "Nem",
              "modifyDisabled": "Ez a látogatás nem helyezhető át",
              "dateBefore": "Előző dátum",
              "dateCurrent": "Mostani dátum",
              "change": "Dátum módosítása",
              "changeWarning": "Figyelem! A rendszer lehetővé teszi, hogy a páciens csak egyszer változtasson időpontot/orvost az időponthoz"
            }
          }
        }
      },
      "surveyZeroNote": {
        "addButton": "Megjegyzés 0",
        "title": "Felmérés megfigyelései 0",
        "addDialog": {
          "visitPurpose": "A látogatás célja 1",
          "visitPurposeDescription": "Kérdezze meg a pácienst, hogy mi a célja az 1. találkozónak. Ideális esetben a páciens nyilatkozatát kell beírni.",
          "surveyCompleted": "A 0. felmérés befejeződött?",
          "surveyCompletedDescription": "Ha a 0. kérdőívet nem fejezték be, vagy ha a felmérés egyes részei elkészültek, kérjük, indokolja meg.",
          "seizures": "Írja le szavakkal a rohamokat!",
          "seizuresEmpty": "Nincsenek meghatározott rohamtípusok",
          "chronicDiseases": "Van-e a betegnek egyéb krónikus betegsége - vezető? (pl. autizmus, agyvérzés)",
          "chronicDiseasesDescription": "Krónikus betegségek",
          "epilepsy": "A beteg családjában előfordult-e epilepszia vagy más neurológiai betegség?",
          "epilepsyDescription": "Röviden írja le, hogy kinek a családjában szerepel epilepsziás vagy egyéb neurológiai betegség",
          "eegCompleted": "Volt-e a páciens EEG-vizsgálaton?",
          "mriCompleted": "Volt-e a páciens MRI-vizsgálaton?",
          "ctCompleted": "Volt-e a páciens CT-vizsgálaton?",
          "geneticResearch": "Elvégezték-e a beteg genetikai vizsgálatát?",
          "relevantExaminations": "Végeztek-e a betegnél egyéb releváns vizsgálatokat?",
          "otherMedicines": "A 0 kérdőívben szereplő AED-eken kívül szed-e jelenleg gyógyszert a beteg?",
          "otherMedicinesDescription": "Írja le szavakkal, milyen gyógyszereket/anyagokat szed a beteg",
          "allergies": "Van-e a betegnek gyógyszerallergiája, beleértve az antibiotikumokat is?",
          "allergiesDescription": "Írja le szavakkal, hogy a beteg milyen gyógyszerekre/anyagokra allergiás",
          "notes": "További megjegyzések",
          "examinationDescription": "Írja le a vizsgálati eredményeket, ha vannak",
          "additionalDescription": "Szóleírás",
          "treatment": "Kezelés eddig",
          "treatmentDescription": "Szóbeli leírás",
          "specialDiet": "Van valami speciális diéta?",
          "specialDietDescription": "Szóbeli leírás",
          "perinatalInterviewUnimportant": "Fontos információk",
          "birthDate": "Szülési dátum (születési)",
          "birthType": "Születés típusa",
          "pregnancyComplications": "Komplikációk a terhesség alatt",
          "birthWeight": "Súly születéskor",
          "birthLength": "Hossza születéskor",
          "apgar": "Apgar-pontok (0-10)",
          "apgarFirst": "1 perc",
          "apgarNext": "Következő mérés",
          "birthAdditionalProcedures": "Végeztek-e perinatálisan további beavatkozásokat?",
          "birthPrematureAgeCorrected": "Korrigálták az életkort a koraszülötteknél?",
          "hasSiblings": "Van-e a betegnek testvére?",
          "sibling": "Személy",
          "siblingBirthDate": "Születési idő",
          "siblingSameParents": "Ugyanazok a szülők?",
          "siblingHealthy": "Egészséges?",
          "addSibling": "Adjon hozzá egy másik személyt",
          "geneticPredisposition": "Van-e környezeti és genetikai/örökletes hajlam és családi anamnézis?",
          "developmentCorrect": "Helyes a jelenlegi fejlesztés?",
          "retardationDetermined": "Megállapították, hogy a korához képest mennyire retardált?",
          "retardationExamined": "Volt ilyen irányú szakorvosi vizsgálat?",
          "nystagmus": "Van-e nystagmusa a betegnek?",
          "vision": "Látvány",
          "hearing": "Meghallgatás",
          "tuxedo": "Cigarettázás",
          "alcohol": "Alkohol",
          "narcotics": "Kábítószer",
          "leadingDisease": "Mi a beteg vezető betegsége?",
          "leadingDiseaseDominantSymptoms": "A betegség domináns tünetei",
          "leadingDiseaseLasting": "Mióta tart a betegség?",
          "accompanyingSymptoms": "Kísérő tünetek: szeretne a beteg hozzátenni valamit?",
          "next": "Következő",
          "previous": "Vissza",
          "saveAndClose": "Mentés és bezárás",
          "sections": {
            "surveyZero": "0. felmérés",
            "perinatalInterview": "Perinatális interjú",
            "development": "Fejlesztés",
            "familyInterview": "Családi interjú",
            "treatment": "Kezelés",
            "comorbidities": "Társbetegségek",
            "medicalExams": "Orvosi vizsgálatok",
            "others": "Más"
          }
        },
        "preview": {
          "userCreated": "Készítette",
          "seizures": "A bevitt rohamok leírása",
          "chronicDiseases": "Van-e a betegnek más súlyos betegsége?",
          "epilepsy": "Családi interjú",
          "eegCompleted": "EEG teszt",
          "mriCompleted": "MRI vizsgálat",
          "ctCompleted": "CT vizsgálat",
          "geneticResearch": "Genetikai vizsgálat",
          "relevantExaminations": "Egyéb kutatások",
          "otherMedicines": "Kábítószerek az LPP-szereken kívül",
          "allergies": "Gyógyszer allergia",
          "addToObservations": "Hozzáadás a megfigyelésekhez",
          "addFragmentToObservations": "A kiválasztott töredék hozzáadása a megfigyelésekhez",
          "addWholeToObservations": "Egész jegyzet hozzáadása a megfigyelésekhez"
        }
      },
      "medicalHistoryPrintout": {
        "download": "Orvosi előzmények letöltése",
        "withObservations": "Megfigyeléseket tartalmazó változat",
        "withoutObservations": "Megfigyelések nélküli változat",
        "downloadHistory": "Letöltési előzmények megjelenítése",
        "history": {
          "title": "A páciens kórtörténetének letöltéseinek története",
          "timeDownload": "Letöltés dátuma",
          "userDownload": "Letöltő",
          "observations": "Megfigyeléseket tartalmaz"
        },
        "parametersPopup": {
          "title": "Orvosi előzmények letöltése",
          "subtitle": "Válassza ki azt az időszakot, amelyből le szeretné tölteni a kórelőzményt",
          "allHistory": "minden történelem a neuroterminális rendszerből",
          "selectedRange": "történelem egy adott időből",
          "download": "Letöltés",
          "cancel": "Mégse"
        }
      },
      "patientExaminations": {
        "chooseExaminationLbl": "Válassza ki azt a vizsgálatot, amelynek leírását szeretné kitölteni",
        "eeg": "EEG vizsgálat",
        "addEegDescription": "Adja hozzá az EEG-vizsgálat leírását",
        "eegDescriptionHeader": "EEG vizsgálat leírása",
        "describingDoctor": "Leírás készítője",
        "months": "hónap",
        "removeEegTitle": "Távolítsa el az EEG-vizsgálat leírását",
        "removeEegContent": "Biztosan eltávolítja ezt a leírást?"
      }
    },
    "common": {
      "sex": {
        "man": "Férfiak",
        "woman": "Nő",
        "nondefined": "Nem meghatározott"
      },
      "role": {
        "patientLbl": "beteg",
        "doctorLbl": "orvos"
      },
      "years": " | év | {n} év | {n} év",
      "visit": {
        "paid": "fizetett",
        "notPaid": "nem fizetett",
        "additional": "további",
        "toMove": "mozogni",
        "confirmed": "megerősített",
        "done": "Kész",
        "cancelled": "törölve",
        "messagePaid": "folyamatban"
      },
      "bornAbbr": "született",
      "months": " | 1 hónap | {XXXX} hónap | {XXXX} hónap",
      "days": " | 1 nap | {XXXX} nap | {XXXX} nap",
      "confirm": "Erősítse meg",
      "cancel": "Mégse",
      "select": "Válassza ki"
    },
    "views": {
      "profile": {
        "welcome": "ÜDVÖZÖLJÜK !",
        "choose": "Bejelentkezés mint:",
        "doctor": "orvos",
        "patient": "beteg"
      },
      "login": {
        "loginFldLbl": "Email",
        "passwordFldLbl": "Jelszó",
        "loginBtnLbl": "Belépés",
        "loggingAs": "Ön mint bejelentkezett",
        "wantToLoginAs": "néven szeretnék bejelentkezni",
        "registerMsg": "Még nincs fiókod?",
        "registerLinkMsg": "Regisztráljon fiókot",
        "resetMsg": "Nem emlékszik a jelszavára?",
        "resetLinkMsg": "Jelszó visszaállítása",
        "return": "Visszatérés"
      },
      "register": {
        "patientLbl": "Beteg",
        "doctorLbl": "Orvos",
        "loginFldLbl": "Belépés",
        "newPassFldLbl": "Jelszó",
        "newPassConfFldLbl": "Jelszó megerősítése",
        "firstNameFldLbl": "Keresztnév",
        "secNameFldLbl": "Második név",
        "lastNameFldLbl": "Vezetéknév",
        "emailFldLbl": "Email",
        "workCertNrFldLbl": "számú bizonyítvány",
        "confirmBtnLbl": "megerősít",
        "backToLoginPageLbl": "Vissza a bejelentkezési oldalra"
      },
      "reset": {
        "loginFldLbl": "Felhasználónév",
        "newPasswordLbl": "Új jelszó",
        "newPasswordConfirmLbl": "Erősítsd meg az új jelszót",
        "confirmBtnLbl": "megerősít",
        "backToLoginPageLbl": "Vissza a bejelentkezési oldalra"
      },
      "dashboard": {
        "welcomeMessage": "Bejelentkezett felhasználó: {userName}",
        "enableEditable": "Nézet megerősítése",
        "disableEditable": "Nézet kezelése"
      },
      "visits": {
        "titleLbl": "A látogatásaim",
        "noDataLbl": "Nincs látogatás",
        "noResultsLbl": "Nem található látogatás",
        "filters": {
          "visitTime": {
            "all": "Minden",
            "future": "Jövő",
            "past": "Múlt"
          },
          "searchPatientLbl": "Beteg keresése",
          "advancedFiltersLbl": "Kiterjedtebb keresés",
          "advancedFilters": {
            "from": "Dátum innen:",
            "betweenDatesLbl": "csináld",
            "till": "Dátum ig",
            "type": "Látogatás típusa",
            "category": "Látogassa meg a kategóriát",
            "all": "Minden",
            "clearBtn": "egyértelmű",
            "searchBtn": "keresés"
          }
        }
      },
      "account": {
        "common": {
          "requiredMsg": "A kötelező mezők csillaggal vannak jelölve",
          "clearBtn": "Egyértelmű",
          "editBtn": "Szerkesztés",
          "saveBtn": "megerősít",
          "yes": "Igen",
          "no": "Nem",
          "woman": "nő",
          "man": "Férfi",
          "nondef": "nem meghatározott"
        },
        "home": {
          "myInfoLbl": "Az én információim",
          "generalInfoLbl": "Általános információ",
          "accountInfoLbl": "Gyakorlati információk",
          "pricelistInfoLbl": "Pénzügyi adat",
          "incompleteFormWarningMsg1": "Nincs minden mező kitöltve",
          "incompleteFormWarningMsg2": "Biztos, hogy el akar menteni egy hiányos űrlapot?"
        },
        "general": {
          "basicInfoLbl": "Alapinformáció",
          "avatarLbl": "Avatar",
          "nameLbl": "Név",
          "lastNameLbl": "Vezetéknév",
          "sexLbl": "Szex",
          "emailLbl": "Email",
          "phoneLbl": "Telefonszám",
          "pwzLbl": "PWZ szám",
          "dataSavedMsg": "Általános információk mentve"
        },
        "practise": {
          "basicInfoLbl": "Engedélyek, nyújtott szolgáltatások, tudományos hordozó",
          "type": "Szint",
          "consultationType": "A konzultáció típusa",
          "academicTitle": "Akadémiai cím",
          "specialization": "Szakosodás",
          "workPlace": "Munkahely",
          "epileptologyCert": "Epileptológiai minősítés",
          "nfzContract": "NFZ szerződés",
          "testResDescriptions": "Vizsgálati eredmények leírása",
          "consultations": "Konzultációk",
          "scientificWork": "Tudományos munka",
          "scientificSocieties": "Tudományos társaságok",
          "www": "WWW oldal",
          "bio": "Bio",
          "certificate": "ZUS tanúsítvány",
          "chooseFile": "Válassz fájlt",
          "patientsInfoLbl": "Betegek",
          "newPatients": {
            "label": "Új betegek",
            "accept": "elismeri",
            "noaccept": "Nem ismeri el"
          },
          "acceptedPatients": "Elfogadott betegek",
          "leadPractiseInfoLbl": "Vezető gyakorlati információk",
          "workplaceName": "Munkahely neve",
          "workplacePosition": "Pozíció",
          "communicators": "Kommunikátorok",
          "usernameMessanger": "Messenger felhasználónév",
          "usernameWhatsup": "Whatsapp felhasználónév",
          "usernameSkype": "Skype felhasználónév",
          "usernameHangouts": "Hangouts-felhasználónév",
          "dataSavedMsg": "A gyakorlati információk mentve",
          "neuroscreenDiagnoses": "Promotált betegségek",
          "partner": "Partner NSPH"
        },
        "pricelist": {
          "services": "Szolgáltatások",
          "visitsLbl": "Látogatások",
          "othersLbl": "Mások",
          "systemItemsLbl": "Rendszerelemek",
          "systemItemsDescription": "Ezeket a szolgáltatásokat globálisan a Neuroterminal állítja be",
          "descriptionsLbl": "Vizsgálati eredmények leírása",
          "table": {
            "statusLbl": "Állapot",
            "activeLbl": "Aktív",
            "inactiveLbl": "Inaktív",
            "nameLbl": "Szolgáltatás neve",
            "durationLbl": "Időtartam",
            "priceLbl": "Ár",
            "paymentLbl": "Az Ön fizetése",
            "perHourLbl": "óránként",
            "dutyLbl": "Vám"
          },
          "bankAccount": "bankszámla",
          "widgetVisibility": "Látható a kinevezési widgetben",
          "practiceName": "Magánpraxis neve",
          "nip": "Korty",
          "regon": "Regon",
          "dataSavedMsg": "Az árlista adatai mentve",
          "visitsDescription": "Állítsa be az árakat bizonyos látogatástípusokhoz.",
          "othersDescription": "Állítsa be a többi szolgáltatás díjait, amelyek nincsenek csoportosítva betegtípusok szerint, például üzenetek vagy orvosi dokumentumok",
          "choosePatientTypes": "A vizitek árlistájának meghatározásához ki kell választania legalább egy betegtípust"
        }
      },
      "patientDashboard": {
        "yourLeadDoctor": "A vezető orvosod",
        "addEvent": "Esemény hozzáadása",
        "showCalendar": "Naptár megjelenítése",
        "filesAdded": "Fájlok hozzáadva",
        "addFiles": "Fájlok hozzáadása",
        "showAllFiles": "Az összes fájl megtekintése",
        "surveyZeroDesc": "A felmérést az orvos a látogatás során tölti ki 0. felmérés",
        "showSurveyZero": "Mutassa meg a felmérést",
        "aboutMe": "Rólam",
        "myData": "adataim",
        "myDataEdit": "Szerkesztés",
        "myDataFill": "Tölt",
        "myDataShow": "Előadás",
        "medicalDocuments": "orvosi dokumentáció",
        "healthQuestionnaire": "egészségügyi kérdőív",
        "visitsLbl": "Látogatások",
        "eventCalendar": "Eseménynaptár",
        "addEventLbl": "Hozzáadás",
        "confirmDeletionTitle": "Esemény törlése",
        "confirmDeletionText": "Törölni szeretnéd az eseményt?",
        "seizure": "Roham",
        "infection": "Fertőzés",
        "fever": "Láz",
        "insomnia": "Álmatlanság",
        "menstruation": "Menstruáció",
        "pregnancyStart": "Terhesség vagy terhesség előtti időszak kezdete",
        "pregnancyEnd": "A terhesség vagy a terhesség előtti időszak vége",
        "chooseLeadDoctor": "Válasszon vezető orvost",
        "noLeadDoctor": "Vezető orvost választ a nulladik vizit után",
        "notAvailableNow": "Nem elérhető",
        "doctorCare": "Egészségügyi ellátás",
        "alert": {
          "yourVisitWithDoctor": "Látogatás az orvosnál",
          "startsAt": "kezdődik",
          "lastsFrom": "tól tart",
          "join": "Csatlakozik",
          "visitToMove": {
            "first": "Az Ön látogatása itt:",
            "second": "törölték",
            "makeNewAppointment": "Válasszon új dátumot"
          },
          "myData": {
            "label": "A Páciens fiókjának használatához töltse ki a személyes adatok űrlapját.",
            "action": "Ugrás az űrlapra"
          },
          "prescriptionsToRead": {
            "label": "Az orvos felírt egy receptet. A PIN-kód megtekintéséhez lépjen a Terápia lapra.",
            "action": "Mutasd a recepteket"
          },
          "selectLeadDoctor": {
            "label": "Válassza ki vezető orvosát.",
            "action": "Válasszon orvost"
          },
          "doctorRejectedRequest": "{doctorName} nem vállalta, hogy vezető orvosa legyen. Ok: {reason}"
        },
        "activation": {
          "welcomeLbl": "Üdvözöljük a Neuroterminálban",
          "init1Lbl": "Örülök, hogy velünk vagy.",
          "init2Lbl": "Az első látogatás előtt töltse ki egészségi állapotára vonatkozó információkat.",
          "init3Lbl": "adataim",
          "init4Lbl": "orvosi dokumentáció",
          "init5Lbl": "Utána könyvlátogatás nulla.",
          "nextLbl": "Következő"
        },
        "therapy": "jelenlegi terápia",
        "noCurrentTherapies": "Nincs jelenlegi terápia",
        "drugStock": "gyógyszerkészlet",
        "drugStockDescription": "Kattintson a „Frissítés” gombra, hogy megadja aktuális gyógyszerkészletét.",
        "supplyFor": "kínálat {days} napra",
        "amountUndefined": "összege nincs meghatározva",
        "supplyEndsIn": "{days} nap múlva ér véget",
        "medicineLbl": "Gyógyszer",
        "runningOutStocks": "A készlete fogyóban van. Kérje meg vezető orvosát, hogy írjon fel receptet.",
        "update": "Frissítés",
        "save": "Megment",
        "askForPrescription": "Kérjen receptet",
        "updateDrugStockLbl": "Gyógyszerkészlet frissítése",
        "updateDrugStockLblDesc": "Számolja meg jelenlegi gyógyszerkészletét, és írjon be egy értéket az alábbi mezőbe.",
        "drugStockUpdateHelpLbl": "Írja be a gyógyszerkészleteket a mai nap elejétől. Minden reggel automatikusan frissül az aktuális gyógyszeres terápia adagjaival.",
        "drugStockUpdated": "A(z) {products} gyógyszerkészletek sikeresen frissítve",
        "drugStockRecalculationFailed": "Az automatikus gyógyszerkészlet frissítése nem sikerült. Manuálisan frissítheti gyógyszerkészletét.",
        "referrals": {
          "title": "Az orvos ajánlása",
          "appoint": "Kinevez"
        },
        "nonepileptic": {
          "infoMsg": "Minden konzultáció egyszeri látogatás, a legjobb szakemberek által. A részletes szolgáltatási feltételek az elérhető orvosok listájával a vizitfoglalási folyamatban érhetők el.",
          "consultationsLbl": "konzultációk",
          "specialistsLbl": "szakemberek",
          "epileptLbl": "epileptológus",
          "neurosurLbl": "idegsebész",
          "neurologLbl": "neurológus szakorvos",
          "psychLbl": "pszichiáter"
        },
        "dataNotFilled": {
          "title": "Üdvözöljük a Neuroterminal alkalmazásban!",
          "footer": "Válassza ki, hogy ki legyen a Neurosphera beteg",
          "patient": "beteg vagyok",
          "patientDescription": "18 év feletti betegek számára",
          "guardian": "A beteg gyermek",
          "guardianDescription": "18 év alatti vagy törvényes gyámmal rendelkező betegek számára"
        },
        "carePlan": {
          "title": "Gondozási terv",
          "date": "Dátum",
          "incomingVisitsAndReferrals": "Bejövő látogatások és ajánlások",
          "incomingVisitsAndReferralsDesc": "Itt jelennek meg az Ön beérkező időpontjai és orvosi beutalói",
          "bookFirstVisit": "Foglalja le az első látogatást",
          "archiveVisits": "Archívum látogatások",
          "archiveVisitsDesc": "Itt láthatja a már megtörtént látogatásokat",
          "scheduledDate": "javasolt dátum",
          "getSummaryPdf": "kapjon ajánlásokat a látogatásból",
          "cancelReferral": "távolítsa el az utalást",
          "changeDate": "dátum módosítása",
          "cancelVisit": "törölje a látogatást",
          "reassignVisit": "orvost váltani",
          "payForVisit": "fizetni a látogatásért",
          "startingAt": "Kezdve",
          "goToVisit": "Írja be a látogatást",
          "showMoreArchiveVisits": "Összes archív látogatás megjelenítése",
          "cancelReferralConfirmation": "Biztosan eltávolítja ezt a hivatkozást?",
          "cancelReferralSuccess": "Az utalást eltávolítottuk",
          "chooseDate": "Válassza ki a dátumot",
          "change": "Változtassa meg a gondozási tervet",
          "chooseDateFreeReferral": "Válassza ki az ingyenes látogatás dátumát",
          "suggestedDate": "javasolt dátum",
          "referralRemoved": "Hivatkozás eltávolítva",
          "downloadSummary": "Összefoglaló letöltése",
          "description": "Leírás",
          "filters": {
            "canceled": "A műsor megszakítva",
            "visits": "Látogatások megjelenítése",
            "messages": "Üzenetek megjelenítése",
            "referrals": "Ajánlások megjelenítése"
          }
        },
        "neuroscreen": "Neuroscreen",
        "doctorOnDuty": "Ügyeletes orvos"
      },
      "patientAccount": {
        "info": {
          "general": "Betegadatok",
          "generalDesc": "Szükségünk van néhány adatra. Ez formai követelmény. Ne feledje, jogilag és műszakilag védettek!",
          "generalDescLegal": "Szükségünk van páciensünk adataira is. Ez formai követelmény, de egyben a recept, a bizonyítványok vagy a beutaló helyes kibocsátásának képessége is.",
          "infoLbl": "Személyes adatok",
          "avatarLbl": "Avatar",
          "nameLbl": "Név",
          "lastNameLbl": "Vezetéknév",
          "sexLbl": "Szex",
          "emailLbl": "Email",
          "phoneLbl": "Telefonszám",
          "peselLbl": "Pesel",
          "nameLblRich": "A páciens <b>neve</b>",
          "lastNameLblRich": "A páciens <b>vezetékneve</b>",
          "peselLblRich": "A páciens <b>pesel</b>je",
          "otherDocumentLbl": "Egyéb dokumentum",
          "documentTypeLbl": "Dokumentum típus",
          "documentNumberLbl": "Dokumentum szám",
          "birthDate": "Születési dátum",
          "repLbl": "Hivatalos reprezentatív adatok",
          "parentLbl": "Szülői adatok",
          "repLblDesc": "További információra van szükségünk Önről kedves szülő, hogy a törvényi előírásoknak megfelelően tudjunk gondoskodni Önről. Ne feledje, hogy adatai jogilag és technikailag védettek!",
          "repLblShort": "Jogi képviselő",
          "repNameLbl": "A szülő vagy a törvényes képviselő <b>neve</b>",
          "repLastNameLbl": "A szülő vagy a törvényes képviselő <b>vezetékneve</b>",
          "repPhoneLbl": "A szülő vagy a törvényes képviselő <b>telefonja</b>",
          "repPeselLbl": "a szülő vagy törvényes képviselő <b>PESEL</b>",
          "teleAddrLbl": "Beteg címe",
          "teleAddrLblDesc": "A pontos elérhetőség elengedhetetlen ahhoz, hogy az orvos receptet, igazolást vagy beutalót állítson ki.",
          "bioMedLbl": "Orvosbiológiai adatok",
          "bioMedLblDesc": "Az alábbi információk nem kötelezőek. Később újratöltheti őket. Ne feledje, hogy a biometrikus adatok megadása segít az orvosnak kiválasztani a megfelelő gyógyszeradagot.",
          "bloodTypeLbl": "Vércsoport",
          "rhTypeLbl": "RH",
          "heightLbl": "magasság",
          "weightLbl": "Súly",
          "iceLbl": "ICE (vészhelyzet esetén – azonnali helyzetekben)",
          "iceShortLbl": "JÉG",
          "others": "Mások",
          "othersDesc": "Az alábbi adatok megadása nem kötelező, ha tájékoztatást szeretne nekünk hagyni arról, hogy vészhelyzet esetén kit kell értesíteni.",
          "icePers1Lbl": "Hívható személy 1",
          "icePers1PhoneLbl": "1. személy telefonszám 1",
          "icePers2Lbl": "Hívható személy 2",
          "icePers2PhoneLbl": "2. személy telefonszáma",
          "addIceContact": "Adjon hozzá egy másik személyt",
          "nsphSourceQuestion": "Honnan hallott a Neurospheráról?",
          "nsphSourceDoctorDescription": "Kérjük, adja meg egy orvos nevét",
          "clearBtn": "Egyértelmű",
          "saveBtn": "megerősít",
          "dataSavedMsg": "Adatok mentve",
          "entitledStatementMsg": "Ezennel kijelentem, hogy minden törvényes jogom megvan",
          "legalGuardianMsg": "Kijelentem, hogy jogi képviselő vagyok, aki jogosult a beteg képviseletére, mint:",
          "invalidPeselWarnMsg": "Ha a PESEL helytelen, a nem és a születési dátum nem kerül mentésre",
          "fromNsph": "Kezelték a beteget a CTP Neurospherában?",
          "hasEpilepsy": "Epilepsziás a beteg?",
          "incompleteFormWarningMsg1": "Nincs minden mező kitöltve",
          "incompleteFormWarningMsg2": "Ha nem tölti ki az összes kötelező mezőt, fiókja nem aktiválódik, és Ön nem tud időpontot egyeztetni az 1. vizittel. Ez egy törvényi előírás, amelyet egészségügyi szervezetként kötelesek teljesíteni. A helyes adatok lehetővé teszik orvosaink számára, hogy egészségügyi szolgáltatásokat nyújtsanak, beleértve például a receptek kiadását.",
          "incompleteFormConfirmBtn": "Egészítse ki az adatokat",
          "yesLbl": "Igen",
          "noLbl": "Nem",
          "contactEmailLbl": "További e-mail cím a rendszerüzenetek fogadására",
          "contactEmailDesc": "A megadott címen elérhető lesz a klinika recepciója. Ha Ön is szeretne értesítést kapni a rendszertől a megadott címre, jelölje be a megfelelő pontokat:",
          "contactVisitNotification": "Látogatások - fizetés, visszaigazolás",
          "contactEmailPlaceholder": "Email cím",
          "contactDescriptionPlaceholder": "Cím leírása",
          "contactReferralNotification": "Beutalók – gondozási terv",
          "addAdditionalContactEmail": "Következő e-mail cím hozzáadása",
          "documentCountry": "Ország",
          "invoiceBuyer": {
            "title": "Számla adatok",
            "checkboxLbl": "A számlához más adatokat szeretnék beállítani",
            "firm": "Cég",
            "person": "Magánszemély",
            "firmLbl": "Cégnév",
            "nipLbl": "Korty",
            "firstNameLbl": "Keresztnév",
            "lastNameLbl": "Vezetéknév",
            "streetLbl": "utca",
            "buildingLbl": "Épület",
            "zipcodeLbl": "irányítószám",
            "cityLbl": "Város",
            "comment": "Megjegyzés"
          },
          "address": {
            "streetLbl": "A páciens <b>utcája</b>",
            "cityLbl": "A páciens <b>városa</b>",
            "countryLbl": "A páciens <b>országa</b>"
          },
          "leaveDialog": {
            "title": "Nem fejezte be az adatok megadását, biztos benne, hogy ki szeretne jelentkezni?",
            "subtitle": "A következő bejelentkezéskor újra meg kell adnia őket",
            "footer": "Ha további kérdése van, vagy bármilyen probléma merül fel a jelentkezés kitöltésével kapcsolatban, vegye fel velünk a kapcsolatot:"
          },
          "partner": "Partner NSPH"
        },
        "bioMed": {
          "saveSuccess": "Zmiany zostały zapisane",
          "fullInfo": "Minden adat megjelenítése"
        },
        "bioMedEditDialog": {
          "title": "Betegbiológiai adatok szerkesztése",
          "subtitle": "Az érték megváltoztatásakor az adatok automatikusan mentésre kerülnek"
        }
      },
      "awaitingPaymentNotAvailable": {
        "title": "A látogatás foglalása sikertelen!",
        "subtitle": "Elnézést a kellemetlenségért",
        "ageDescription": "Az Ön által választott orvos ilyen korú betegeket nem fogad",
        "changeDescription": "Válasszon másik orvost, majd időpontot",
        "action": "Válasszon orvost és időpontot"
      },
      "visitReservation": {
        "title": "Látogatás foglalása",
        "consultationTitle": "Konzultációs foglalás",
        "specializationChoice": "Szakterület választás",
        "visitTypeChoice": "Látogatás típusa",
        "doctorChoice": "Orvos választás",
        "slotChoice": "Slot választás",
        "summaryAndPayment": "Összegzés és fizetés",
        "noServicesAvailable": "A már kijelölt vizit után új epilepsziás viziteket írhat elő",
        "goToVisitsList": "Ugrás a látogatási listához",
        "appointConsultation": "Konzultáció/EEG időpontegyeztetés",
        "leadDoctor": "vezető orvos",
        "next": "Következő",
        "choose": "Választ",
        "expand": "Kiterjed",
        "approve": "megerősít",
        "cancel": "Megszünteti",
        "fold": "Hajtsa be",
        "back": "Vissza",
        "doctor": "Orvos",
        "service": "Szolgáltatás",
        "price": "Ár",
        "payment": "Fizetési forma",
        "date": "Dátum",
        "pay": "Fizetés",
        "cancelAndChangeDate": "Törlés / dátum módosítása",
        "calculateInstallments": "Fizessen részletekben",
        "noSlotsAvailable": "Nincsenek helyek",
        "doctors": "Orvosok",
        "availableSlots": "Rendelkezésre álló helyek",
        "selectPreviousSlots": "A rendelkezésre álló helyek a korábbi időpont kiválasztása után kerülnek bemutatásra",
        "summary": "Összegzés",
        "serviceDescription": "Leírás",
        "priceFrom": "Ár -tól",
        "visitDuration": "A látogatás időtartama",
        "durationSelectDesc1": "A vizsgálat órától tarthat",
        "durationSelectDesc2": "nak nek",
        "durationSelectDesc3": "órák",
        "durationSelectDesc4": "Az időpont egyeztetésének folytatásához válassza ki az órák számát",
        "durationTime": "A vizsgálat ideje",
        "eegDescription": "A Neuroterminális rendszerben 30 percig tartó standard EEG-vizsgálatra kell jelentkezni. A vizsgálatot a Neurosphera Epilepsia Terápiás Központban végzik. Ha nem szabványos EEG-vizsgálatra vagy EEG-módosításra (VEEG, DEEG, PSG vagy egyéb) szeretne jelentkezni, kérjen időpontot telefonon a Neurosphera Epilepsia Terápiás Központ titkárságán a +48 609 463 191-es telefonszámon.",
        "allDoctors": "Minden orvos",
        "allWorkplaces": "Minden helyszín",
        "allTechniciansIntro": "A vizsgálatot tapasztalt technikusaink egyike végzi el",
        "paymentLinkSent": "A fizetési linket elküldtük a páciensnek",
        "markedAsPaid": "A látogatást kijelölték és fizetettnek jelölték",
        "openPaymentLink": "Nyissa meg a fizetési linket",
        "linkCopied": "A link a vágólapra másolva",
        "markAsPaid": "Megjelölés fizetettként",
        "serviceTypeSelection": {
          "label": "Látogatás típusa",
          "epilepsy": "Epilepsziás vizit",
          "consultation": "Konzultáció / EEG"
        },
        "sorting": {
          "label": "Fajta",
          "alpha": "Betűrendben",
          "teleconferencePriceAsc": "Telekonferencia ára: növekvő",
          "teleconferencePriceDesc": "Telekonferencia ára: csökkenő",
          "stationaryPriceAsc": "Helyhez kötött látogatás ára: növekvő",
          "stationaryPriceDesc": "Álló látogatás ára: csökkenő",
          "bestMatch": "Legjobb meccs"
        },
        "serviceGroupDescriptions": {
          "eeg_consultation": {
            "groupSelectionTitle": "Rendelkezésre álló EEG/VEEG vizsgálatok",
            "title": "Ki végzi az EEG-tesztet?",
            "description": "Az EEG vizsgálatot epileptológus, neurológus vagy pszichiáter kérésére erre a területre szakosodott technikus végzi. Felelős a kutatások tervezéséért és lebonyolításáért a felnőttek és a gyermekek összes módozatára vonatkozóan. A kutatást az ébrenlét-elalvás-alvás során végzik. A páciens otthonában is elvégezhetők. A teszt körülbelül 30 percet vesz igénybe.",
            "bulletsTitle": "Mikor szükséges az EEG vizsgálat?",
            "bullet1": "Az EEG-teszt az epilepszia diagnózisának alapvető vizsgálata",
            "bullet2": "Az EEG-t gyakran használják az alvászavarok okainak kimutatására",
            "bullet3": "Fejsérülések miatt rendelhető",
            "bullet4": "Egyéb jelek közé tartozik a látásromlás, dadogás, visszatérő fejfájás, ájulás vagy eszméletvesztés"
          }
        },
        "serviceDescriptions": {
          "vns": {
            "title": "Mit jelent a VNS látogatás?",
            "description": "VNS-látogatás alkalmával orvosa ellenőrzi az Ön aktuális beállítását. A rögzített rohamok előzményei alapján kiválasztja a megfelelő VNS-terápiát. A látogatáson lévő orvos számítógép, konfigurációs szoftver és programozó távirányító segítségével módosítja a stimulációs beállításokat. Az orvos csökkentheti vagy növelheti az áramerősséget, valamint növelheti vagy csökkentheti a készülék inaktivitási idejét. A látogatás 15 percig tart.",
            "bulletsTitle": "Mikor érdemes VNS időpontot foglalni?",
            "bullet1": "Amikor a VNS-terápia az aktuális paraméterek fokozatos megváltoztatásával jár",
            "bullet2": "Amikor a beteg a rohamok számának jelentős növekedését észleli",
            "bullet3": "Amikor a beteg egészségi állapotának és közérzetének jelentős romlását észleli",
            "bullet4": "Amikor a páciens észreveszi, hogy a beültetett stimulátor már nem működik, azaz már nem ad ki impulzusokat",
            "bullet5": "Mielőtt bármilyen olyan orvosi vizsgálaton részt venne, amely hatással lehet a VNS-eszközre vagy hatással lehet rá, mint például a mágneses rezonancia képalkotás (MRI)"
          },
          "dietician": {
            "title": "Ki a dietetikus?",
            "description": "Felsőfokú végzettséget szerzett orvosi vagy táplálkozási (mezőgazdasági) profilú szakember. Megfelelő ismeretekkel rendelkezik a különböző betegcsoportok diétájának lefolytatásához, és az ajánlások megfogalmazásakor figyelembe veszi többek között a nemet, az életkort, a súlyt és a magasságot, a betegségeket, beleértve az allergiákat is, valamint a páciens preferenciáit. Ezért minden páciens számára egyéni étrendet készít, és felügyeli őt. A vizsgálati eredményektől vagy a pácienstől kapott információktól függően bevezeti a szükséges módosításokat. Ezért rendkívül fontos a beteggel és kezelőorvosával való szoros együttműködés.",
            "bulletsTitle": "Mikor forduljunk dietetikushoz?",
            "bullet1": "Amikor a neuroszféra orvosa ketogén diétával történő kezelést javasolt",
            "bullet2": "Ha úgy gondolja, hogy a ketogén diéta kezelési lehetőség lehet az Ön esetében (MEGJEGYZÉS - orvoslátogatás is szükséges, ha Ön Neurospeha-beteg, kérdezze meg orvosát erről a kezelési lehetőségről, ha még nem neuroszféra-beteg, kérjen időpontot a ZERO látogatásra)",
            "bullet3": "Ha ketogén diétát tart, és szeretné ellenőrizni jelenlegi kezelését (MEGJEGYZÉS – orvosi látogatás is szükséges)",
            "bullet4": "Ha ketogén diétát tart - kontroll vizit (a vizit során a dietetikus tájékoztatja Önt arról, hogy mikor érdemes bejelentkezni)",
            "bullet5": "Ha ellenőrizni szeretné jelenlegi étrendjét"
          },
          "neurologist": {
            "title": "Ki az a neurológus?",
            "description": "Szakember, aki megfigyeli a páciens szervezetének reakcióit, vizsgálja az idegrendszer működését, és ellenőrzi az emberi szervezet ingerekre adott reakcióit is. A perifériás és központi idegrendszer betegségeivel foglalkozik. A neurológus elsősorban azokra a betegségekre fókuszál, amelyeket az emberi idegrendszert romboló folyamatok okoznak. Megvizsgálja a kialakuló neurológiai betegségek alapjait, felállítja a diagnózist és a megfelelő kezelést. Olyan betegségek kezelésével foglalkozik, mint az isiász, a gerinc degenerációja, az epilepszia, a Parkinson-kór, az Alzheimer-kór, a Huntington-kór, a stroke és a stroke utáni állapotok, a migrén és egyéb fejfájások.",
            "bulletsTitle": "Mikor forduljunk neurológushoz?",
            "bullet1": "Amikor ájulás és eszméletvesztés lép fel",
            "bullet2": "Amikor a memória élesen romlik és problémák vannak a koncentrációval",
            "bullet3": "Amikor alvászavarai vannak",
            "bullet4": "Amikor egyensúlyi és koordinációs problémák vannak",
            "bullet5": "Ha a végtagok bizsergése és zavart érzése van",
            "bullet6": "Ha krónikus fájdalom társul szédüléssel"
          },
          "neurosurgeon": {
            "title": "Ki az idegsebész?",
            "description": "Egyes idegrendszeri betegségek (agy, gerincvelő, perifériás idegek) diagnosztizálására és műtéti kezelésére szakosodott sebész, amelynél a gyógyszeres kezelés nem vált be, és műtétet kell végezni.",
            "bulletsTitle": "Mikor forduljunk idegsebészhez?",
            "bullet1": "Olyan neurológiai problémák előfordulása, amelyeknél a gyógyszeres kezelés nem vált be, pl. diszkopátia, krónikus hátfájás és sérülések, agydaganatok és a gerinc daganatos elváltozásai, hemangiómák stb.",
            "bullet2": "Erős és hosszan tartó fejfájás és szédülés",
            "bullet3": "Ismeretlen okú zsibbadás vagy bizsergés a végtagokban",
            "bullet4": "Gyakori syncope előfordulása",
            "bullet5": "Ismeretlen ok jelenléte károsodás, látás, hallás",
            "bullet6": "A végtagi parézis előfordulása"
          },
          "neuropsychologist": {
            "title": "Ki az a neuropszichológus?",
            "description": "A klinikai pszichológia specialistája, aki az agy működése és az emberi viselkedés kapcsolatával foglalkozik. A neuropszichológus tevékenysége elsősorban a különböző betegségek, sérülések vagy sérülések által okozott agykárosodásban szenvedőkre irányul.",
            "bulletsTitle": "Mikor forduljunk neuropszichológushoz?",
            "bullet1": "Amikor a páciensnél normális működési problémákat diagnosztizálnak, mint például fejsérülések, agydaganatok, idegszövetet elpusztító betegségek. például. Alzheimer- vagy Parkinson-kór",
            "bullet2": "Memóriazavar lép fel",
            "bullet3": "Koncentrációs problémák",
            "bullet4": "Beszédzavarok előfordulása",
            "bullet5": "Nehézségek a tájékozódásban, az útkeresésben",
            "bullet6": "A normál szemészeti eredmények ellenére látászavar lép fel",
            "bullet7": "Hangulatváltozások, szorongás, téveszmék lépnek fel",
            "bullet8": "Addiktív állapotokban"
          },
          "neurologopedist": {
            "title": "Ki az a neurológus?",
            "description": "Neurológiai eredetű beszéd- és kommunikációs zavarok diagnosztizálásával és kezelésével foglalkozó szakember. A neurológus szakorvos egyaránt tud dolgozni olyan gyerekekkel, akiknek különböző hátterű beszédfejlődése vagy beszédzavara van, valamint felnőtt betegekkel.",
            "bulletsTitle": "Mikor forduljunk neurológushoz?",
            "bullet1": "Amikor egy gyermeknél neurológiai rendellenességet, genetikai szindrómát vagy autizmus spektrum zavart diagnosztizálnak",
            "bullet2": "Amikor a szülő pszichomotoros retardációt észlel gyermekében",
            "bullet3": "Ha egy gyermeknek beszédzavarai vannak - egy 2 éves vagy idősebb gyermek egyáltalán nem beszél, keveset beszél vagy beszédhibás",
            "bullet4": "Ha a gyermek túlzottan nyálas, nehezen eszik, nem zárja be a száját, és nem lélegzik az orrán keresztül",
            "bullet5": "Amikor egy felnőtt embernek beszédzavara van agyvérzés vagy baleset után",
            "bullet6": "Ha egy felnőttnek beszédzavarai vannak időskori demencia, Parkinson-kór, Alzheimer-kór vagy sclerosis multiplex következtében"
          },
          "psychiatrist": {
            "title": "Ki az a pszichiáter?",
            "description": "Mentálhigiénés problémákkal - mentális zavarokkal és betegségekkel foglalkozó orvos, mind a diagnózis, mind a további terápia szempontjából. A pszichiáter gyógyszeres kezelést végezhet, és további konzultációkat is utalhat más szakterületekre.",
            "bulletsTitle": "Mikor érdemes pszichiáterhez fordulni?",
            "bullet1": "Ha sokáig idegesítenek: szomorúság, szenvedés, apátia, energiahiány, tehetetlenség, pesszimista jövőkép",
            "bullet2": "Öngyilkossági gondolatok megjelenése",
            "bullet3": "Problémákban és alvászavarokban",
            "bullet4": "Szorongásos zavarokra",
            "bullet5": "Hiperaktivitás kialakulása, természetellenesen emelkedett hangulat és zavartság",
            "bullet6": "Koncentrációs problémák és memóriazavarok előfordulása",
            "bullet7": "A kapcsolatfelvétel és a nyugdíjazás elkerülése",
            "bullet8": "Valóságérzékelést befolyásoló téveszmék és hallucinációk esetén"
          },
          "epileptologist": {
            "title": "Ki az epileptológus?",
            "description": "Az epilepszia diagnosztizálására és kezelésére szakosodott neurológus. Az epileptológus szakosodhat a gyermekek vagy felnőttek epilepsziájának diagnosztizálására és kezelésére. A terápia megválasztása mellett az orvos koordinálja a terápiás folyamatot, a beteget más szakorvosokhoz is irányítja.",
            "bulletsTitle": "Mikor érdemes epileptológushoz fordulni?",
            "bullet1": "Hirtelen roham fellépése, amelyben a beteg eszméletét veszti és elesik, az izmok pedig megfeszülnek. Görcsök lépnek fel, és a beteg légzése leállhat",
            "bullet2": "A környezettel való kapcsolat hirtelen elvesztése következik be, és a beteg mozdulatlanná dermed",
            "bullet3": "Az izomgörcsök eszméletvesztés nélkül jelentkeznek",
            "bullet4": "Izomlazulás, eszméletvesztés, betegesés"
          },
          "geneticist": {
            "title": "Ki a genetikus?",
            "description": "A klinikai genetikus olyan orvos, akinek feladata annak megállapítása, hogy egy betegség örökletes-e, és milyen mechanizmus vezetett a rendellenesség öröklődéséhez. A genetikus azt is meghatározza, hogy a következő generációkban mekkora valószínűséggel fordulnak elő ugyanazok a hibák vagy betegségek. Célja továbbá a beteg családtagjainak azonosítása, akiknél örökletes betegség alakulhat ki. A genetika szerepe az is, hogy a családot tájékoztassa a betegség megelőzési és kezelési lehetőségeiről, valamint genetikai tanácsadást biztosítson számukra. Fontos elem az örökletes betegség szakorvosi kezelésének megvalósítása is.",
            "bulletsTitle": "Mikor forduljunk genetikushoz?",
            "bullet1": "Ha a családjában olyan betegség van, amelynek genetikai oka ismert vagy feltételezhető",
            "bullet2": "Mikor szeretné elvégezni a WES tesztet?",
            "bullet3": "Ha ki akar zárni egy veleszületett betegséget, amely epilepsziát okozhat",
            "bullet4": "Ha terhességet tervez, és szeretné megtudni, hogy babája is epilepsziás lehet"
          },
          "absent": {
            "title": "Mit jelent a hiányzó konzultáció?",
            "description": "A távolléti konzultáció egy olyan orvosi vizit, amelyet a kiválasztott Neurosphera szakember a beteg jelenléte nélkül hajt végre. A hiányzó konzultáció célja az orvosi feljegyzések (kibocsátási kártyák, genetikai vizsgálatok eredményei és mások) elemzése vagy további vizsgálat (EEG, MRI és mások). A neuroszférikus szakember elemzi a dokumentációt, megfelelő feljegyzést és ajánlásokat készít a páciens számára. A lebonyolítás tényét és annak eredményét a páciens vizitarchívumában tároljuk. MEGJEGYZÉS: a távolléti konzultáció 30 percig tart, és a beteg részvétele nélkül történik.",
            "bulletsTitle": "Mikor kell konzultálni?",
            "bullet1": "Az 1. vagy későbbi vizit alkalmával – amennyiben az orvos megfelelőnek ítéli – a távolmaradó konzultációra kell jelentkeznie. Célja az időkereten túlmutató, vagy más orvossal való konzultációt igénylő kiegészítő klinikai elemzés elvégzése (példa: kiderül például, hogy egy kiválasztott szakemberrel konzultálni kell anyagcsere-vizsgálattal, vagy több éven keresztül több MRI vizsgálatot kell elemezni).",
            "bullet2": "Jellemzően egy 30 perces konzultáció során a klinikai szakembernek lehetősége van két alátámasztó vizsgálat megtekintésére leírással."
          },
          "metabolic": {
            "title": "Mi az a metabolikus konzultáció?",
            "description": "Az anyagcsere-konzultáció ritka betegségekre vonatkozik – veleszületett anyagcserezavarokra, amelyek genetikai betegségek. Az anyagcsere-betegségek diagnosztizálása elsősorban számos vizsgálatot, laboratóriumi vizsgálatot foglal magában, melyeket gyakran szakorvosi vizsgálat is alátámaszt.",
            "bulletsTitle": "Mikor kell konzultálni?",
            "bullet1": "Érdemes eljönni anyagcsere-konzultációra, ha a genetikai vizsgálatok során a betegség gyanúja merül fel, vagy ha a vizsgálatok: tandem vértömeg-spektrometria (tandem MS, MS / MS) vagy vizelet szerves savprofil (GC-MS teszt) hibásak.",
            "bullet2": "Egyes epilepsziával összefüggő betegségek anyagcsere-alapúak lehetnek, pl. piruvát-dehidrogenáz (PDHD) hiány, glükóz transzporter hiánya az agyba (GLUT-1 DS) vagy kiválasztott mitokondriális betegségek."
          },
          "visit0": {
            "bulletsTitle": "Mi az a Survey 0?",
            "bullet1": "<b>Előzi meg és készíti elő</b> 1 látogatást a neuroszféra klinikai szakorvosnál",
            "bullet2": "Ez <b>a kórtörténetének részletes interjúja</b>",
            "bullet3": "Egy<b> orvosi képesítéssel rendelkező személy </b> vezeti, akit egyénileg választunk ki az Ön számára",
            "bullet4": "<b>Készülj fel rá.</b> Emlékezzen vissza, milyen gyógyszereket szedett, milyen gyakran fordulnak elő rohamok, hogyan zajlanak le és milyen jellegűek",
            "bullet5": "A 0 felmérést végző személy strukturált interjú formájában összeállítja az Ön esetének digitális dokumentációját. Az így rendszerezett digitális adatokat az Ictal robotunk elemezheti, hogy diagnózist és terápiát javasoljon a Visit 1 során.",
            "bullet6": "Időtartam <b>legfeljebb 60 perc</b>",
            "bullet7": "Kiválaszthatja a látogatás típusát – <b>online vagy helyhez kötött</b>"
          },
          "visit1": {
            "bulletsTitle": "Mi az a Visit 1?",
            "bullet1": "Ez egy látogatás az Ön által választott Neurosphery Clinical Specialistnál",
            "bullet2": "Az orvos látni fogja a 0. felmérés eredményeit, az Ictal robotunk elemzésének eredményeit és az Ön által csatolt anyagokat, amelyek segítségével hatékonyan megerősítheti a diagnózist és a terápiát.",
            "bullet3": "Szükség esetén az orvos további vizsgálatokat javasol",
            "bullet4": "Az orvos átfogó kezelési tervet ajánl fel Önnek",
            "bullet5": "Időtartam <b>legfeljebb 30 perc</b>",
            "bullet6": "Kiválaszthatja a látogatás típusát - <b> online vagy helyhez kötött</b>"
          },
          "veegOnStandby": {
            "title": "Mi a VEEG vizsgálat ébrenlétben?",
            "description": "A VEEG a szinkronizált EEG és videó valós idejű rögzítése. A helyes diagnózis érdekében az orvosnak tudnia kell, hogy mi történik a beteggel a rohamok alatt. Minden részlet fontos: milyen irányba fordul a fej, milyen irányba \"futnak\" a szemgolyók, hol jelentkeznek a rohamok stb. A videometriás vizsgálat lehetővé teszi, hogy az orvos a betegség diagnosztizálásának ebben a döntő pillanatában lássa a beteget. A vizsgálatot speciálisan előkészített laboratóriumban végzik. Az ébrenléti vizsgálat napközben történik. A beteg az orvos utasítása szerint dönti el, mennyi ideig tartson a vizsgálat. A vizsgálóteremben egy fotel és egy ágy áll a páciens rendelkezésére. A páciens az EEG-készülékhez rögzítve használhatja a WC-t a vizsgálat során. A vizsgálat után a páciens egy CD-t kap a rögzített felvétellel. A vizsgálat leírását az ár tartalmazza.",
            "bulletsTitle": "Hogyan készüljünk fel a vizsgára?",
            "bullet1": "A beteg feje tiszta legyen",
            "bullet2": "A vizsgálat előtt nem szabad gélt vagy hajlakkot felvinni a hajra",
            "bullet3": "Vegyen be gyógyszert az orvos által előírt módon",
            "bullet4": "Ne végezzen alvásmegvonást az orvossal való megállapodás nélkül - a beteg alszik"
          },
          "veegDuringSleep": {
            "title": "Mi az a VEEG vizsgálat alvás közben?",
            "description": "A VEEG a szinkronizált EEG és videó valós idejű rögzítése. A helyes diagnózis érdekében az orvosnak tudnia kell, hogy mi történik a beteggel a rohamok alatt. Minden részlet fontos: milyen irányba fordul a fej, milyen irányba \"futnak\" a szemgolyók, hol jelentkeznek a rohamok stb. A videometriás vizsgálat lehetővé teszi, hogy az orvos a betegség diagnosztizálásának ebben a döntő pillanatában lássa a beteget. A vizsgálatot speciálisan előkészített laboratóriumban végzik. Az alvásvizsgálatot este vagy éjszaka végezzük. A vizsgálat időtartamát a beteg dönti el, az orvos utasítása szerint. A vizsgálóteremben egy fotel és egy ágy áll a páciens rendelkezésére. A beteg az EEG-készülékhez kötve használhatja a WC-t a vizsgálat során. Nagy gyerekek és felnőttek egyedül fekszenek az ágyon, kisgyermekek kísérővel, a váróterem a kísérő rendelkezésére áll. A vizsgálat után a páciens CD-t kap a rögzített felvétellel. A vizsgálat leírását az ár tartalmazza.",
            "bulletsTitle": "Hogyan készüljünk fel a vizsgára?",
            "bullet1": "A beteg feje tiszta legyen",
            "bullet2": "A vizsgálat előtt nem szabad gélt vagy hajlakkot felvinni a hajra",
            "bullet3": "Vegyen be gyógyszert az orvos által előírt módon",
            "bullet4": "Ne végezzen alvásmegvonást az orvossal való megállapodás nélkül - a beteg alszik",
            "bullet5": "Nem árt, ha magaddal viszed otthonról kedvenc párnádat"
          },
          "veegNight": {
            "title": "Mi az a 12 órás VEEG teszt?",
            "description": "A VEEG a szinkronizált EEG és videó valós idejű rögzítése. A helyes diagnózis érdekében az orvosnak tudnia kell, hogy mi történik a beteggel a rohamok alatt. Minden részlet fontos: milyen irányba fordul a fej, milyen irányba \"futnak\" a szemgolyók, hol jelentkeznek a rohamok stb. A videometriás vizsgálat lehetővé teszi, hogy az orvos a betegség diagnosztizálásának ebben a döntő pillanatában lássa a beteget. A vizsgálatot speciálisan előkészített laboratóriumban végzik. Az irodában a páciens rendelkezésére áll egy fotel és egy ágy. A vizsgálatot a beteg elalvásától kezdjük, majd végigjárjuk az egyes fázisokat, végül rögzítjük az ébredés pillanatát. A páciensnek lehetősége van a WC használatára a vizsgálat során, miközben az EEG készülékhez csatlakozik. Nagy gyerekek és felnőttek egyedül fekszenek az ágyon, kisgyermekek a gondozójukkal, váróterem áll rendelkezésre. A vizsgálat után a páciens CD-t kap a rögzített felvétellel. A vizsgálat leírását az ár tartalmazza.",
            "bulletsTitle": "Hogyan készüljünk fel a vizsgára?",
            "bullet1": "A beteg feje tiszta legyen",
            "bullet2": "A vizsgálat előtt nem szabad gélt vagy hajlakkot felvinni a hajra",
            "bullet3": "Vegyen be gyógyszert az orvos által előírt módon",
            "bullet4": "Ne végezzen alvásmegvonást az orvossal való megállapodás nélkül - a beteg alszik",
            "bullet5": "Nem árt, ha magaddal viszed otthonról kedvenc párnádat"
          },
          "veegDay": {
            "title": "Mi az a 24 órás VEEG teszt?",
            "description": "A VEEG-teszt egy szinkronizált EEG- és videovizsgálat valós idejű rögzítése. A helyes diagnózis érdekében az orvosnak tudnia kell, hogy mi történik a beteggel a rohamok alatt. Minden részlet fontos: milyen irányba fordul a fej, milyen irányba \"futnak\" a szemgolyók, hol jelentkeznek a rohamok stb. A videometriás vizsgálat lehetővé teszi, hogy az orvos a betegség diagnosztizálásának ebben a döntő pillanatában lássa a beteget. A vizsgálatot speciálisan előkészített laboratóriumban végzik. Az irodában a páciens rendelkezésére áll egy fotel és egy ágy. A vizsgálat általában éjszakai vizsgálattal kezdődik, majd nappal megfigyeljük a beteget. A páciensnek lehetősége van a WC használatára a vizsgálat során, miközben az EEG készülékhez kötve marad. Az étkezést a vizsgálat ára tartalmazza. Nagy gyerekek és felnőttek egyedül fekszenek egy ágyon, kisgyermekek kísérővel, váróterem áll rendelkezésre. A vizsgálat után a páciens CD-t kap a rögzített felvétellel. A vizsgálat leírását az ár tartalmazza.",
            "bulletsTitle": "Hogyan készüljünk fel a vizsgára?",
            "bullet1": "A beteg feje tiszta legyen",
            "bullet2": "A vizsgálat előtt nem szabad gélt vagy hajlakkot felvinni a hajra",
            "bullet3": "Vegyen be gyógyszert az orvos által előírt módon",
            "bullet4": "Ne végezzen alvásmegvonást az orvossal való megállapodás nélkül - a beteg alszik",
            "bullet5": "Nem árt, ha magaddal viszed otthonról kedvenc párnádat",
            "bullet6": "További részletek a technikussal egyeztethetők a rendelő recepciójának +48 609 463 191 telefonszámán"
          },
          "eegConsultation": {
            "title": "Mi az EEG vizsgálat?",
            "description": "A szokásos EEG-vizsgálatot a beteg ébrenléte közben végzik. A technikus kiválaszt egy megfelelő sapkát, és elektródákat helyez a páciens fejére. A vizsgálat minősége szempontjából fontos, hogy a lehető legszorosabban tapadjanak a fejbőrhöz. Maga a vizsgálat körülbelül 20 percet vesz igénybe. A vizsgálat után a beteg azonnal visszatérhet a normál tevékenységhez. A vizsgálat után a páciens egy CD-t kap a rögzített felvétellel. A vizsgálat leírását az ár tartalmazza.",
            "bulletsTitle": "Hogyan készüljünk fel a vizsgára?",
            "bullet1": "A beteg feje tiszta legyen",
            "bullet2": "A vizsgálat előtt nem szabad gélt vagy hajlakkot felvinni a hajra",
            "bullet3": "Vegyen be gyógyszert az orvos által előírt módon",
            "bullet4": "Ne végezzen alvásmegvonást az orvossal való megállapodás nélkül - a beteg alszik"
          },
          "sleepDisturbance": {
            "title": "Mi az alvászavar Konzultáció?",
            "description": "Az alvászavarok lehetnek elsődlegesek (magában az alvásban fellépő rendellenességek következtében), valamint más rendszerekből származó betegségek tünetei. Néha pszichiátriai konzultáció szükséges. A látogatás során részletes interjút készítenek az éjszaka menetéről és a nap programjáról. Egy ilyen látogatásra érdemes alvásnaplót készíteni, amely legalább 5 napra terjed ki, és otthoni módszerekkel rögzíti az éjszakai zavaró epizódokat. A konzultáció után felmérik, hogy a probléma fiziológiás-e vagy sem. Ha alvászavart diagnosztizálnak, kezelést ajánlanak Önnek – kezelést vagy beutalót az alapproblémával foglalkozó szakemberhez.",
            "bulletsTitle": "Mikor kell konzultálni?",
            "bullet1": "Ha napközben álmos vagy ingerlékeny",
            "bullet2": "Ha érzelmi tényezők által okozott álmatlanságot vagy túlzott álmosságot tapasztal",
            "bullet3": "Ha zavarokat észlel az alvás cirkadián ritmusában és felébred",
            "bullet4": "Ha horkol alvás közben"
          },
          "regularVisitEegPackage": {
            "title": "Mi a rendszeres vizit + EEG csomag?",
            "description": "EEG teszt – az agy bioelektromos aktivitásának felmérésére szolgáló standard teszt, amely alapján az agy működésének helyességét értékelik. A vizsgálat körülbelül 20 percet vesz igénybe. A vizsgálat után a beteg látogatásra megy. RENDSZERES állólátogatás - minden további látogatás. 30 percig tart. A vizit során az orvos elemzi az elvégzett EEG vizsgálatot, felállítja a végső diagnózist és elemzi a rendszerben gyűjtött adatokat. A páciens egy CD-t kap a rögzített EEG-vizsgálattal és a rendszeres látogatás ajánlásaival.",
            "bulletsTitle": "Mikor kell regisztrálni a csomagra?",
            "bullet1": "Az EEG-teszt az epilepszia diagnózisának alapvető vizsgálata",
            "bullet2": "Az epilepsziában szenvedő betegnek évente rendszeresen EEG-vizsgálatot kell végeznie, és rendszeresen fel kell keresnie epileptológust.",
            "bullet3": "Ha stacionárius orvosi látogatást tervezünk, érdemes EEG-t végezni az agy bioelektromos aktivitásának felmérésére.",
            "bullet4": "Nem érez javulást vagy romlást egészségi állapotában, beszélni szeretne kezelőorvosával a kezelés megváltoztatásáról"
          },
          "adhdPackage": {
            "title": "Hogyan diagnosztizálható az ADHD?",
            "description": "Az ADHD diagnosztika 2 neuropszichológussal való találkozásból áll, mindegyik 1,5 órás, amely során klinikai interjút és neuropszichológiai teszteket végeznek. Az értekezletek után a pszichológus véleményt mond, és javasolja, hogy kihez forduljon a beteg, neurológushoz (30 perc) vagy pszichiáterhez (45 perc). A megfelelő vizit alkalmával a beteg diagnózist és javaslatokat kap a további kezelésre (gyógyszeres kezelés vagy pszichológiai terápia).",
            "bulletsTitle": "Mikor kell regisztrálni az ADHD csomagra?",
            "bullet1": "Ha nehézségek adódnak az idő és a feladatok megszervezésével. Nehéznek találja a projektek elindítását és befejezését, a tervezést és a rend fenntartását",
            "bullet2": "Ha érzelmi nehézségei vannak, akkor hajlamos a hangulati ingadozásokra, frusztrációra, és nehézségekbe ütközik az érzelmek kontrollálása. Erős reakciókat tapasztalsz stresszes helyzetekben",
            "bullet3": "Amikor interperszonális nehézségeket veszel észre magadon. Nyugtalan vagy, impulzív vagy, és nehezen tudod figyelembe venni mások szempontjait",
            "bullet4": "Ha nehezen tudja megbecsülni a feladat elvégzéséhez szükséges időt"
          },
          "standardVisit": {
            "title": "Mit jelent a rendszeres látogatás?",
            "description": "RENDSZERES látogatás – minden további látogatás. 30 percig tart. A látogatás során az orvos interjút készít, és elemzi a páciens eseménynaptárát. Az orvos áttekinti a beteg egészségügyi dokumentációját, és további vizsgálatokat javasolhat. Minden vizit után a páciens megkapja a vizitből származó ajánlásokat, terápiás tervet és további ellátási tervet. A páciens kiválaszthatja a látogatás típusát - online vagy helyhez kötött.",
            "bulletsTitle": "Hogyan néz ki egy rendszeres látogatás?",
            "bullet1": "Aktuális egészségi állapotának megbeszélése - ne feledje az utolsó támadás dátumát és jólétét - töltse ki az eseménynaptárt",
            "bullet2": "A legutóbbi vizsgálatok eredményeinek megismerése - ha nemrégiben végzett vizsgálatokat, győződjön meg arról, hogy minden eredményt csatolt az orvosi dokumentációhoz",
            "bullet3": "A további terápia megbeszélése",
            "bullet4": "Átfogó gondozási terv felállítása",
            "bullet5": "Recept, beutaló, betegszabadság, igazolás kiállítása"
          },
          "caseConference": {
            "title": "Mi az az esetkonferencia?",
            "description": "A szakorvosok és a Neurosphery orvosi partnerei közötti orvosi esetkonferencia egy kollektív találkozó a páciens klinikai esetének megvitatására és az optimális kezelési terv meghatározására. Konzultációra olyan helyzetekben kerül sor, amikor segítségre vagy további szaktudásra van szükség a legjobb kezelési eredmény eléréséhez. Ez egyfajta interdiszciplináris orvosi konferencia, amely során ismereteket és véleményeket cserélnek a szakemberek."
          },
          "mmpi2Package": {
            "title": "Mi az MMPI-2 személyiségteszt?",
            "description": "Lehetővé tesszük a személyiségzavarok diagnosztizálását a Lengyelországban elérhető legkiterjedtebb és univerzális klinikai értékelő eszközzel - a Minnesota Multidimensional Personality Inventory (az MMPI-2 teljes adaptációja 2012-ben jelent meg). A teszt tíz kontrollskálát tartalmaz, amelyek segítségével leírható a kapott eredményprofil megbízhatósága és igazolható klinikai értelmezésük. Az ellenőrző skálák tájékoztathatják a diagnosztikust az alany motivációjáról, az alkalmazkodás szintjéről és a lehetséges védekezési mechanizmusokról is.",
            "bulletsTitle": "Mi az MMPI-2 személyiségteszt?",
            "bullet1": "Maga a leltár felhasználható a személyiségproblémák klinikai leírásában, de hasznos lehet egy meghatározott szakmai funkció ellátására alkalmatlan személyek azonosításában is. Használható olyan felnőttek diagnosztizálására, akik legalább 6 éves formális iskolai végzettséget szereztek. A tesztet pszichológus felügyelete mellett kell elvégezni. Körülbelül 90 percet kell szánnia a teszt elvégzésére és kitöltésére. A teszt kitöltésekor 567 állításra kell hivatkoznia az IGAZ/HAMIS kiválasztásával. Magát a teszt kitöltését meg kell előznie egy körülbelül 50 perces megbeszélésnek, amely során adatokat gyűjtenek az interjúból, és bemutatják a tesztet a tesztelt személynek. Egy héten belül a vizit elvégzése és a teszt elvégzése után a vizsgált személy megkapja a kapott eredményeket összefoglaló jegyzőkönyvet."
          },
          "cbtPackage": {
            "title": "Mi az álmatlanság CBT-terápia?",
            "description": "Az álmatlanság kezelésére szolgáló kognitív viselkedésterápia (CBT) az álmatlansággal küzdő emberek első vonalbeli kezelésének számít, amelyet olyan hatóságok javasolnak, mint a National Institutes of Health (NIH), a National Institute for Health and Care Excellence (NICE) és az American College. az orvosok. A CBT-I hatékony alternatívája az altató gyógyszereknek, amely tartós megoldásokat kínál az alvási szokások és a gondolkodás megváltoztatásával. A teljes CBT-I folyamat öt ülésből áll, hat hét alatt, de a javulás általában korábban észrevehető. A foglalkozások helyhez kötötten vagy online is tarthatók. A krónikus álmatlanságban szenvedők körülbelül 75%-a tapasztal jelentős javulást az alvásban, és 90%-uk csökkenti vagy teljesen abbahagyja az altatók szedését.",
            "bulletsTitle": "A CBT-I terápia azoknak ajánlott, akik:",
            "bullet1": "Krónikus álmatlanságban szenvednek: legalább három hónapon keresztül legalább hetente háromszor jelentkeznek alvási problémák.",
            "bullet2": "Nehezen alszik el: Több mint 30 percet töltenek azzal, hogy éjszaka próbálnak elaludni.",
            "bullet3": "Éjszakai ébredés: Rendszeresen felébrednek az éjszaka közepén, és több mint 30 percig nehezen tudnak visszaaludni.",
            "bullet4": "Ettől fáradtnak és ingerlékenynek érzik magukat a nap folyamán: Az álmatlanság befolyásolja mindennapi közérzetüket és életminőségüket.",
            "bullet5": "Alternatívát keresnek az altatók helyett: el akarják kerülni a gyógyszeres terápia függőségét, és hosszú távú, természetes megoldásokat keresnek."
          },
          adhdConsultation: {
              title: 'Miért éri meg?',
              description: 'Az egyéni coaching egy intenzív, egyórás találkozó egy tapasztalt ADHD edzővel, amely az Ön aktuális kihívásaira és céljaira összpontosít. Ezek nem elméleti beszélgetések – a tervek szerint a foglalkozás konkrét, gyakorlati eszközöket ad, amelyeket azonnal meg is tudtok valósítani. Egy órán át teljes mértékben az igényeire összpontosíthat általános tanácsok és „egy megoldás mindenki számára” nélkül. Ez a támogatás az ADHD sajátosságaihoz és az Ön egyéni céljaihoz igazodik – így azonnal érezheti a különbséget az életében.',
              bulletsTitle: 'Mit nyersz egy ülésen?',
              bullet1: '<b>A jelenlegi kihívások elemzése</b> – a coach segít megérteni, mely nehézségek származnak közvetlenül az ADHD-ből, és hogyan lehet hatékonyan leküzdeni őket.',
              bullet2: '<b>Gyakorlati stratégiák</b> – olyan konkrét technikákat sajátíthat el, amelyeket azonnal elkezdhet használni a koncentráció, a szervezettség és az időgazdálkodás javítására.',
              bullet3: '<b>Egyéni cselekvési terv</b> – kidolgozza a következő ülés előtt megteendő lépések tervet, amely segít motiváltnak maradni és gyors eredményeket elérni.',
          },
          psychotherapy: {
              title: 'Mi az a pszichoterápiás ülés?',
              description: 'A pszichoterápiás foglalkozás a páciens (kliens) és a pszichoterapeuta találkozása, melynek célja érzelmi, mentális vagy viselkedési problémák közös megoldása. A foglalkozás célja, hogy segítsen megérteni, elfogadni és átalakítani gondolatait, érzelmeit vagy viselkedését. Ez magában foglalhatja a traumák, szorongás, depresszió, stressz vagy kapcsolati problémák kezelését.',
              bulletsTitle: 'Mikor kell jelentkezni egy foglalkozásra?',
              bullet1: 'A pszichoterápia számos olyan helyzetben javasolt, amikor valaki olyan érzelmi, mentális vagy szociális nehézségeket tapasztal, amelyek befolyásolják a mindennapi működést és az életminőséget. Hasznos lehet az öntudat fejlesztésében és a másokkal való kapcsolatok javításában is.',
          }
        },
        "visitPackageDescriptions": {
          "startPackage1": "Új Neurosphera betegként vagy a beteg szülőjeként Önnek 1. időpontot kell kérnie egy klinikai szakemberhez. Ezt egy 0. felmérés előzi meg, amely lehetővé teszi számunkra, hogy információkat gyűjtsünk a hatékony diagnózis és terápia érdekében. Mindezt egy megfizethető áron.",
          "startPackage2": "Válassza ki azt a szakorvost, aki felveszi az 1. látogatást, és azt, hogy az online vagy helyhez kötött. Ügyeljen az árkülönbségekre és a helyhez kötött látogatás helyére.",
          "startPackage3": "A 0. felmérés megelőzi és előkészíti az 1. látogatást. Ezt egy általunk választott orvosi képesítéssel rendelkező személlyel tartjuk. Válassza ki az Ön számára megfelelő 0. felmérés típusát (online vagy helyhez kötött) és annak dátumát. Ne feledje, hogy a 0. felmérés legfeljebb 60 percig tart.",
          "startPackage4": "A fizetés lehetővé teszi számunkra, hogy időpontot foglaljunk egy adott orvoshoz. Ne feledje, a dátumot bármikor ingyenesen módosíthatja!"
        },
        "slotChoiceDescriptions": {
          "VISIT_1": "Az 1. vizitre a 0. felmérés után kerül sor. A Neurosphery Clinical Specialist és az előző lépésben már kiválasztott vizittípus. Az orvos diagnózist készít, és megfelelő terápiát javasol. Egyéni gondozási tervet is készít. Ne feledje, az 1. látogatás legfeljebb 30 percig tart."
        },
        "payments": {
          "directPayment": "{provider} val biztonságosan fizethet:",
          "creditCard": "Hitelkártya",
          "blik": "Blik",
          "onlineTransfer": "Online átutalás",
          "onlineApplication": "Online alkalmazás",
          "mobilePayments": "Mobil fizetések",
          "payLater": "Fizessen később"
        },
        "stage": "Színpad",
        "sum": "Összeg",
        "freeAppointmentPrice": "Ingyenes",
        "appointmentSuccess": "Wizyta została umówiona",
        "sameDateAsPrevious": "A látogatásnak ugyanazon a napon kell lennie, mint az előző csomaglátogatásnak",
        "refundRestrictions": "Látogassa meg a lemondási feltételeket",
        "refundRestrictionsAccept": "Elfogadom a lemondási feltételeket",
        "supervisorDoctor": "látogassa meg a felügyelőt",
        "supervisorDoctorAbbr": "Felügyelő",
        "doctorPackageDescription": "Az Orvoscsomag egy látogatás 2 vagy több orvos részvételével. A látogatást végző orvos elvégzi a vizittel kapcsolatos minden tevékenységet. A megfigyelő orvos megfigyeli a látogatást, és klinikai megbeszélést folytathat a kezelőorvossal és a pácienssel.",
        "specialist": "Szakember a"
      },
      "paymentOutcome": {
        "title": "Fizetési visszaigazolás",
        "loading": "Fizetési adatok betöltése",
        "status": {
          "title": "Állapot",
          "types": {
            "pending": {
              "title": "Fizetés feldolgozása folyamatban",
              "subtitle": "A fizetés még nincs megerősítve",
              "comment": "Kérjük, várjon egy kicsit, amíg a fizetés befejeződik"
            },
            "success": {
              "title": "A szolgáltatást kifizették",
              "subtitle": "A fizetés megerősítésre került",
              "comment": "Köszönjük a fizetést"
            },
            "error": {
              "title": "A szolgáltatásért nem fizettek",
              "subtitle": "A fizetést törölték",
              "comment": "Kérjük, ismételje meg a fizetési folyamatot"
            }
          }
        },
        "details": {
          "title": "Részletek",
          "date": "Dátum",
          "name": "A szolgáltatás neve",
          "doctor": "Orvos"
        }
      },
      "visitPaymentOutcome": {
        "title": "Látogatás foglalása",
        "subtitle": "megerősítés",
        "details": "részletek",
        "loading": "A látogatási adatok betöltése folyamatban van...",
        "card": {
          "successTitle": "Látogatását kifizették",
          "successLabel1": "Köszönjük a fizetést",
          "successLabel1Package": "Köszönöm! Jól törődnek veled!",
          "successLabel2": "A látogatásról 24 órával korábban értesítést kap",
          "failTitle": "Látogatását nem fizették ki",
          "failLabel1": "Hiba történt a fizetési folyamat során",
          "retryPayment": "Próbáld újra a fizetést",
          "toGoMessageView": "Ugrás az üzenet nézethez",
          "paymentPending": "Befizetését még nem erősítették meg. Ez akár 1 percig is eltarthat",
          "paymentConfirmed": "A fizetés visszaigazolva!"
        },
        "goHome": "Ugrás a főoldalra",
        "visitScope": "A látogatás hatóköre",
        "scopeBullets": {
          "visit0Bullet1": "<b>Előzi meg és készíti elő</b> 1 látogatást a neuroszféra klinikai szakorvosnál",
          "visit0Bullet2": "Ez <b>a kórtörténetének részletes interjúja</b>",
          "visit0Bullet3": "Egy<b> orvosi képesítéssel rendelkező személy </b> vezeti, akit egyénileg választunk ki az Ön számára",
          "visit0Bullet4": "<b>Készüljön fel rá.</b> Emlékezzen vissza, milyen gyógyszereket szedett, milyen gyakran fordulnak elő rohamok, hogyan zajlanak és milyen jellegűek",
          "visit0Bullet5": "A 0 felmérést végző személy strukturált interjú formájában összeállítja az Ön esetének digitális dokumentációját. Az így rendszerezett digitális adatokat az Ictal robotunk elemezheti, hogy diagnózist és terápiát javasoljon a Visit 1 során.",
          "visit0Bullet6": "Időtartam <b>legfeljebb 60 perc</b>",
          "visit0Bullet7": "Kiválaszthatja a látogatás típusát – <b>online vagy helyhez kötött</b>",
          "migrationVisitBullet1": "Azon betegek számára készült, akik 2021 előtt gondozásunkban álltak vagy állnak",
          "migrationVisitBullet2": "A páciens egészségügyi, biometrikus és személyes adatainak ellenőrzése",
          "migrationVisitBullet3": "Átállás egy új ellátórendszerre",
          "visit1Bullet1": "Ez egy látogatás az Ön által választott Neurosphery Clinical Specialistnál",
          "visit1Bullet2": "Az orvos látni fogja a 0. felmérés eredményeit, az Ictal robotunk elemzésének eredményeit és az Ön által csatolt anyagokat, amelyek segítségével hatékonyan megerősítheti a diagnózist és a terápiát.",
          "visit1Bullet3": "Szükség esetén az orvos további vizsgálatokat javasol",
          "visit1Bullet4": "Az orvos átfogó kezelési tervet ajánl fel Önnek",
          "visit1Bullet5": "Időtartam <b>legfeljebb 30 perc</b>",
          "visit1Bullet6": "Kiválaszthatja a látogatás típusát - <b> online vagy helyhez kötött</b>",
          "standardVisitBullet1": "Időtartam 30-45 perc",
          "standardVisitBullet2": "A végső diagnózis meghatározása",
          "standardVisitBullet3": "Az ICtal® rendszerben gyűjtött adatok folyamatos elemzése",
          "standardVisitBullet4": "Beavatkozási képesség, ha nincs javulás vagy állapotromlás – az ICtal® vagy a tanács tanácsa",
          "consultationBullet1": "Időtartam 45-60 perc",
          "consultationBullet2": "Látogasson el egy másik szakirányú orvoshoz",
          "consultationBullet3": "Kórtörténet",
          "consultationBullet4": "Az epilepszia és a társbetegségek anamnézisének elemzése",
          "consultationBullet5": "Orvosi tanácsadás a konzultáns orvos szakterületén",
          "messageBullet1": "Ne feledje, hogy az üzenet egy rövid szöveges kérdés, amelyre az orvos a lehető leghamarabb válaszol a munkaidejében.",
          "messageBullet2": "A díj részeként választhat, hogy csak egy üzenetet küld - egy kérdést. Amikor az orvos válaszol, lehetősége van újabb utólagos kérdést feltenni, amelyre az orvos ismét válaszol, és a szál automatikusan véget ér.",
          "messageBullet3": "Figyelem! Az orvosnak joga van megtagadni a tanácsadást az üzenet részeként, ha úgy véli, hogy az Ön megkeresése rendszeres látogatásra alkalmas. Az üzenetnek nem célja, hogy vészhelyzetekben vagy életveszélyes helyzetekben segítsen!"
        }
      },
      "neuroscreen": {
        "main": {
          "title": "A Neurosphera klinika új minőséget teremt a betegkezelésben!",
          "subtitle1": "Hatékonyan ötvözzük a tudást és a sok éves tapasztalatot kezelési módszereink folyamatos fejlesztésének, tökéletesítésének igényével!",
          "subtitle2": "Készítettünk Önnek egy felmérést, amely segít kiválasztani a megfelelő orvost és a megfelelő ellátási tervet a Neurosphera klinikán.",
          "subtitle3": "A kérdőív kitöltésével az orvos a vizit előtt elolvassa azt, és tudni fogja, milyen problémával kapcsolatban jelentkezik.",
          "subtitle4": "A kérdőív kitöltése körülbelül 5 percet vesz igénybe.",
          "fillNeuroscreen": "Töltse ki a felmérést",
          "fillNeuroscreenDescription": "Szeretném kitölteni a kérdőívet és beírni a beteg ellátási tervét",
          "goToReservation": "Válasszon orvost",
          "goToReservationDescription": "Nem a kérdőívet szeretném kitölteni, hanem azonnal orvost szeretnék választani"
        },
        "survey": {
          "title": "Neuroscreen – Neurosphera betegfelmérés",
          "subtitle1": "A kérdőív kitöltése után elmondjuk, milyen vizithez és orvoshoz érdemes időpontot egyeztetni.",
          "subtitle2": "Az itt megadott információkat megosztjuk kezelőorvosával, és segít a megfelelő diagnózis felállításában és kezelésében.",
          "next": "Következő",
          "prev": "Vissza",
          "save": "Teljes felmérés",
          "form": {
            "section": {
              "HEADACHES": "Fejfájás",
              "MOVEMENT_ISSUES": "Mozgászavarok",
              "MEMORY_ISSUES": "Memóriazavarok",
              "EATING_ISSUES": "Étkezési zavarok",
              "CONSCIOUSNESS_ISSUES": "Tudat- vagy tudatzavarok",
              "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Pszichomotoros fejlődési zavarok",
              "EPILEPSY": "Epilepszia és görcsrohamok",
              "SPINE_ISSUES": "Gerinc problémák",
              "MUTIPLE_SCLEROSIS": "Sclerosis multiplex",
              "DIZZINESS": "Szédülés",
              "AUTISM": "Autizmus spektrum",
              "OTHER_SYMPTOMS": "Egyéb tünetek"
            },
            "adult": {
              "field": {
                "headachesGeneral": "Fejfájást tapasztal?",
                "headaches1": "Állandó, tompa fejfájása van?",
                "headaches2": "Lüktető fájdalmat érez, általában a feje egyik oldalán?",
                "headaches3": "Fejfájását fény-, hangérzékenység, hányinger vagy hányás kíséri?",
                "headaches4": "Érez látászavarokat, például fényvillanásokat vagy foltokat a szeme előtt, mielőtt a fejfájás elkezdődik?",
                "headaches5": "Ha fejfájása van, tapasztal-e szempírt, könnyezést vagy szemhéjduzzanatot a fájdalom oldalán, vagy orrdugulást?",
                "movementGeneral": "Észrevett-e remegést a végtagjaiban, izommerevséget vagy mozgási nehézséget?",
                "movement1": "Remegést tapasztal a kezében vagy más testrészében?",
                "movement2": "Nyugalomban súlyosbodik a remegés?",
                "movement3": "Érzed az izmaid merevségét, ami megnehezíti a mozgás megkezdését?",
                "movement4": "Észrevetted, hogy lassultak a napi tevékenységeid?",
                "movement5": "Észrevett-e bármilyen problémát a járás során, például kis lépések megtétele vagy váratlan esések?",
                "memoryIssuesGeneral": "Nehézséget okoz az új információk emlékezésében, a szavak megtalálásában vagy a térbeli tájékozódásban?",
                "memoryIssues1": "Nehezen emlékszik a közelmúlt eseményeire?",
                "memoryIssues2": "Előfordul, hogy elfelejt neveket, dátumokat, vagy nehezen találja a megfelelő szavakat beszéd közben?",
                "memoryIssues3": "Egyre nagyobb segítségre van szüksége a mindennapi tevékenységekben, mint a háztartási gépek kezelése, pénzügyek intézése, tervezés?",
                "memoryIssues4": "Nehezen tájékozódsz olyan helyeken, amelyeket korábban ismertél?",
                "memoryIssues5": "Észrevesz-e viselkedésében változásokat, mint például a társadalmi visszahúzódás, az apátia, az impulzivitás vagy a tapintat hiánya?",
                "epilepsyGeneral": "Tapasztalt már valaha eszméletvesztést, kontrollálhatatlan görcsöket vagy rövid ideig tartó felfüggesztést?",
                "epilepsy1": "Hirtelen, megmagyarázhatatlan áramszüneteket tapasztal?",
                "epilepsy2": "Amikor elveszíti az eszméletét, zavartnak érzi magát, vagy nehezen tud azonnal visszatérni a szokásos tevékenységeihez?",
                "epilepsy3": "Az eszméletvesztést görcsök kísérik?",
                "epilepsy4": "Tapasztal-e valamilyen konkrét érzést vagy jelet (pl. \"aura\") az eszméletvesztés/görcsök előtt?",
                "epilepsy5": "Vannak pillanatok, amikor hirtelen abbahagyja a reagálást, és egy rövid időre \"lefagy\"?",
                "spineIssuesGeneral": "Krónikus vagy akut hát- vagy nyakfájást tapasztal?",
                "spineIssues1": "Krónikus hát- vagy nyakfájástól szenved?",
                "spineIssues2": "Zsibbadást tapasztal a karjában vagy a lábában?",
                "spineIssues3": "Észrevett-e karjaiban vagy lábaiban az erő elvesztését?",
                "spineIssues4": "Nehézségei vannak a hólyag vagy a belek szabályozásában?",
                "spineIssues5": "Kisugárzik a fájdalom a gerincből a test más részeire?",
                "mutipleSclerosisGeneral": "Észrevett-e bizsergést, zsibbadást vagy egyéb szenzoros érzetváltozást, amely a test különböző részein előfordulhat?",
                "mutipleSclerosis1": "Váratlan időszakokat tapasztal végtagjaiban gyengeség vagy járási nehézség?",
                "mutipleSclerosis2": "Egyensúlyi vagy koordinációs problémái vannak?",
                "mutipleSclerosis3": "Érzik-e hirtelen látászavarok, például kettős látás vagy homályos látás?",
                "mutipleSclerosis4": "Szokatlan fáradtságot tapasztal, amely nem kapcsolódik az edzéshez?",
                "mutipleSclerosis5": "Izomfájdalmakat és görcsöket tapasztal?",
                "dizzinessGeneral": "Szédülést vagy egyensúlyproblémákat tapasztal?",
                "dizziness1": "A szédülés hirtelen és figyelmeztetés nélkül jelentkezik?",
                "dizziness2": "Hányingert vagy hányást is érez, amikor szédül?",
                "dizziness3": "A szédülés súlyosabbá válik, ha megváltoztatja testhelyzetét, például feláll vagy megdönti a fejét?",
                "dizziness4": "A szédülés előfordulása összefügg-e fülfájással vagy hallásváltozással?",
                "dizziness5": "A szédülést fülzúgás vagy teltségérzet kíséri a fülben?",
                "otherSymptomsGeneral": "Van olyan egyéb tünete, amelyek nem férnek bele a fenti kategóriákba?",
                "otherSymptoms1": "Étkezés közben nyelési nehézséget vagy gyakori fulladást tapasztal?",
                "otherSymptoms2": "Alvászavarai vannak, például álmatlansága vagy túlzott nappali álmossága?",
                "otherSymptoms3": "Időnként koncentrálási nehézséget vagy \"agyködöt\" tapasztal, amely megakadályozza, hogy a feladatokra összpontosítson?",
                "otherSymptoms4": "Észrevette, hogy a horkolás elkezdett vagy súlyosbodik alvás közben?",
                "otherSymptoms5": "Érez néha hirtelen gyengeséget valamelyik testrészében vagy testének egyik oldalán?"
              }
            },
            "child": {
              "field": {
                "headachesGeneral": "Gyermekének fáj a feje?",
                "headaches1": "Gyermekének állandó, tompa fejfájása van?",
                "headaches2": "Ez lüktető fájdalom, általában egyoldalú?",
                "headaches3": "A fájdalmat fény- és hangérzékenység, hányinger és hányás kíséri?",
                "headaches4": "Érez-e a gyermek látászavarokat, például fényvillanásokat vagy foltokat a szeme előtt a fejfájás kezdete előtt?",
                "headaches5": "Ha fejfájása van, tapasztal-e szempírt, könnyezést vagy szemhéjduzzanatot a fájdalom oldalán, vagy orrdugulást?",
                "movementGeneral": "Van-e gyermekének mozgászavara: remegés, merevség, parézis?",
                "movement1": "Remeg a gyermeked?",
                "movement2": "Nyugalomban jelentkezik ez a remegés?",
                "movement3": "Mozgás közben jelentkezik ez a remegés?",
                "movement4": "Gyermekének végtagmerevsége van, amely egyre rosszabb?",
                "movement5": "Gyermeke mozgásfejlődése nem halad előre, vagy nem fejlődik vissza?",
                "epilepsyGeneral": "Gyermekének vannak görcsrohamai?",
                "epilepsy1": "Kiújulnak a rohamok?",
                "epilepsy2": "Különbözőek vagy hasonlóak a rohamok?",
                "epilepsy3": "Előfordulnak görcsrohamok ébrenlét közben?",
                "epilepsy4": "Előfordulnak-e görcsrohamok alvás közben (éjjel vagy nappal)?",
                "epilepsy5": "A rohamok általánosak?",
                "epilepsy6": "Fokálisak-e a rohamok, azaz nem az egész testre, pl. egy vagy két végtag vagy a test egyik fele?",
                "epilepsy7": "A rohamok ritmikusak?",
                "consciousnessIssuesGeneral": "Szenved-e a gyermek tudatzavarban és/vagy eszméletvesztésben?",
                "consciousnessIssues1": "A gyermek tudatzavarban szenved?",
                "consciousnessIssues2": "A gyermek teljes eszméletvesztést él át eséssel?",
                "consciousnessIssues3": "Tapasztal-e gyermeke akadozásokat vagy gondolatokat?",
                "consciousnessIssues4": "A tudatzavar néhány másodpercig tart?",
                "consciousnessIssues5": "A csökkent tudatállapotok 30 másodpercnél tovább tartanak?",
                "eatingIssuesGeneral": "Gyermekének étkezési zavara van?",
                "eatingIssues1": "Gyermekének kóros étvágytalansága van?",
                "eatingIssues2": "Van gyermekének kórosan túlzott étvágya?",
                "autismGeneral": "Van-e a gyermeknek autizmus spektrum zavar jellemzői vagy diagnózisa?",
                "autism1": "A gyermeknek autista spektrumzavara van?",
                "autism2": "Autizmusra gyanakszik a gyermek?",
                "psychomotorDevelopmentIssuesGeneral": "Vannak-e pszichomotoros fejlődési zavarai a gyermeknek?",
                "psychomotorDevelopmentIssues1": "A pszichomotoros fejlődési rendellenességet orvos/orvosi központ diagnosztizálja és igazolja?",
                "psychomotorDevelopmentIssues2": "A pszichomotoros fejlődési zavar csak gyanús és nem igazolt?"
              }
            }
          }
        },
        "rating": {
          "title": "Köszönjük, hogy kitöltötte a kérdőívet!",
          "subtitle": "A neuroterminális rendszer megmondja Önnek a betegségéhez megfelelő orvost",
          "showMore": "Mutass többet",
          "diagnosis": {
            "HEADACHES": "<b>A fejfájás összetett neurológiai probléma,</b> amely többféle formát és intenzitást ölthet. <b>Ezek a betegségek számos betegség tünete lehet,</b> nemcsak a neurológia, hanem az orvostudomány más területein is. Kulcsfontosságú, hogy a fejfájás diagnosztizálását és kezelését neurológus végezze – a kefalológia specialistája, egy e kérdéssel foglalkozó tudomány. <br/><b>A Neurosphera Center egyénileg és csapatban közelíti meg a fejfájás diagnosztizálását és kezelését.</b>",
            "MOVEMENT_ISSUES": "<b>A mozgászavarok a neurológiai rendellenességek csoportja, amelyek különböző mozgási problémákban nyilvánulnak meg.</b> Ezek lehetnek izomgyengeség és parézis, valamint remegés, merevség vagy görcsösség. A tünetek széles köre az idegrendszer károsodásának vagy működési zavarának a következménye. <b>Fontos szakemberhez fordulni, ha bármilyen aggasztó tünetet észlel,</b> mert a korai diagnózis és a megfelelő kezelés kulcsfontosságú a beteg életminőségének javításában. <br/><b>A Neurosphera képes pontosan diagnosztizálni a problémák okát és kiválasztani a megfelelő kezelési módszereket.</b>",
            "MEMORY_ISSUES": "<b>A memóriazavarok gyakran társulnak a demenciához, ahol ez az egyik fő tünet.</b> A memóriazavarok azonban bármely életkorban előfordulhatnak, és különféle betegségek jelei lehetnek, az enyhétől a súlyosig. Ezért olyan fontos, hogy <b>keressen fel egy szakembert, ha bármilyen aggasztó tünetet észlel</b>, aki átfogóan felméri a memória működését és diagnosztizálja a lehetséges okokat. <br/><b>A Neuroszféra Centrumban a páciensnek lehetősége van olyan neuropszichológiai szakorvost választani, aki elvégzi a megfelelő vizsgálatokat és diagnosztizálja a betegség okát, illetve neurológust, aki a megfelelő terápiát választja ki a beteg számára. b>",
            "EATING_ISSUES": "<b>Az étkezési zavarok összetett egészségügyi problémák, amelyek átfogó megközelítést igényelnek.</b> Különféle szakemberek, például dietetikusok, neurológusok és pszichiáterek vesznek részt kezelésükben. Bár az evészavarok gyakran mélyebb lelki vagy fizikai problémák tünetei, <b>a megfelelően kiválasztott étrend és táplálkozási terápia a kezelés fontos eleme lehet,</b> amely a beteg testi és lelki egészségét egyaránt érinti. <br/><b>A Neuroszféra Központ lehetőséget kínál az evészavar problémájának diagnosztizálására neurológus vagy dietetikus szakemberrel, aki jól személyre szabott étrendet készít a páciens számára.</b>",
            "CONSCIOUSNESS_ISSUES": "<b>A tudat- és tudatzavarok olyan állapotok, amelyek mindig aggodalomra adnak okot, és sürgős neurológus konzultációt igényelnek.</b> Gyakran súlyos neurológiai betegség vagy más súlyos egészségügyi rendellenesség első jelei. E betegségek okának pontos meghatározásához <b>további vizsgálatok elvégzésére van szükség, mint például EEG (elektroencefalográfia) és MRI (mágneses rezonancia képalkotás).</b> Ezek a diagnosztikai módszerek lehetővé teszik az agy elektromos aktivitásának értékelését. és szerkezeteinek részletes vizualizálása, ami kulcsfontosságú a helyes diagnózis felállításához és a megfelelő kezelés végrehajtásához. <br/><b>A Neuroszféra Központ egyénileg és csapatként közelíti meg a diagnosztikát és a terápiát. A klinikán lehetőség nyílik EEG vizsgálatok elvégzésére.</b>",
            "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "<b>A gyermekek pszichomotoros fejlődési zavarai összetett klinikai probléma, amely speciális és átfogó megközelítést igényel.</b> A tünetek és a lehetséges okok sokfélesége miatt a diagnózist és a terápiát multidiszciplináris szakembergárdának kell végeznie. <br/><b>A Neuroszféra Központ átfogó ellátást kínál, amely lehetővé teszi a vizsgálatok széles skáláját és a különböző szakemberekkel folytatott konzultációkat.</b> Ez lehetővé teszi a rendellenességek okainak pontos meghatározását és a megfelelő terápiás program végrehajtását. A korai diagnózis és terápia kulcsfontosságú a gyermek optimális fejlődéséhez.",
            "EPILEPSY": "<b>Az epilepszia kezelése a Neurosphera Centerben a mi szakterületünk.</b> 25 éves tapasztalattal rendelkezünk ennek a betegségnek a diagnosztizálásában és terápiájában, amivel Lengyelország egyik vezető központja vagyunk. <b>20 szakemberből álló csapatunk a legújabb diagnosztikai eszközöket alkalmaz</b>, beleértve a mesterséges intelligencián alapuló fejlett rendszereket is, hogy átfogó ellátást nyújtson a betegeknek. Ennek köszönhetően az epilepszia legbonyolultabb eseteit is hatékonyan tudjuk diagnosztizálni és kezelni, pácienseink számára a legmagasabb színvonalú orvosi ellátást kínálva.",
            "SPINE_ISSUES": "A gerinc- és hátfájdalom sok embert érintő probléma, és többféle oka lehet. <b>Ez egy olyan állapot, amely átfogó megközelítést igényel, amely egyesíti a neurológusok és idegsebészek tudását és tapasztalatát.</b> <br/><b>A Neurosphera Centerben egy speciális csapatunk van, amely a diagnózissal és a az ilyen típusú betegségek kezelésére.</b> Szakorvosaink széleskörű tudásának és tapasztalatának, valamint a modern diagnosztikai módszerek elérhetőségének köszönhetően képesek vagyunk pontosan meghatározni a fájdalom okát és optimális kezelést javasolni. <b>Multidiszciplináris megközelítésünk lehetővé teszi a páciens átfogó felmérését és egyéni terápiás terv kidolgozását.</b>",
            "MUTIPLE_SCLEROSIS": "<b>A sclerosis multiplex egy krónikus neurológiai betegség, melynek lefolyása nagyon egyéni, és betegenként jelentősen eltérhet.</b> Az SM diagnosztizálása gyakran kihívást jelent a tünetek sokfélesége miatt, amelyek más betegségeket utánozhatnak. <b>A sclerosis multiplex hatékony kezelése speciális megközelítést és sok szakember együttműködését igényli.</b> A terápiát az egyes betegek egyéni szükségleteihez kell igazítani, és magában kell foglalnia mind a gyógyszeres, mind a nem gyógyszeres kezelést. <b>A tapasztalt neurológusokból álló csapat elengedhetetlen az SM-ben szenvedő betegek megfelelő diagnózisához és átfogó kezeléséhez.</b>",
            "DIZZINESS": "<b>A szédülés olyan állapot, amely leggyakrabban tünet, nem önálló betegség.</b> Különféle tényezők okozhatják, mind gégészeti (pl. egyensúlyzavarok), mind neurológiai (pl. központi idegrendszeri károsodás) tényezők. <br/><b>A szédüléses betegek gyakran fordulnak neurológushoz, mi a Neuroszféra Központban pedig tökéletesen felkészültünk az ilyen betegek fogadására.</b> Átfogó diagnosztikát kínálunk, amely lehetővé teszi a tünetek okának pontos meghatározását. <b>Egyes esetekben szükség lehet idegsebész konzultációra, különösen, ha a szédülés gerincproblémákhoz kapcsolódik.</b> Multidiszciplináris megközelítésünknek köszönhetően hatékony segítséget tudunk nyújtani pácienseinknek.",
            "AUTISM": "<b>Az autizmus spektrumzavar egy összetett idegrendszeri fejlődési rendellenesség, amely magában foglalja a szociális kommunikáció nehézségeit, a társas interakciókat, valamint a korlátozott és ismétlődő viselkedési mintákat.</b> Az autizmus számos formát ölthet, az enyhétől a súlyosig, és gyakran együtt fordul elő egyéb rendellenességek. <b>Mind a tipikus, mind az atipikus gyermekkori autizmushoz szakorvosi diagnózis és átfogó terápiás megközelítés szükséges.</b> <br/><b>A Neuroszféra Központban tapasztalt szakembergárdánk van, akik a betegségek diagnosztizálásával és kezelésével foglalkoznak. autizmus és szövődményei.</b> Szolgáltatásaink széles skáláját kínáljuk, az egyes betegek egyéni igényeihez igazodva.",
            "OTHER_SYMPTOMS": "<b>A neurológiai tünetek rendkívül sokrétűek és gyakran kétértelműek lehetnek.</b> A jellegzetesebb tünetek, például zsibbadás vagy egyensúlyzavarok mellett a betegek gyengeségről, fájdalomról, alvásproblémákról, ingerlékenységről és koncentrációs nehézségekről is beszámolnak. <b>A tünetek e széles köre kihívást jelenthet a pontos diagnózis felállításában, mivel sok közülük különböző neurológiai állapotokra, sőt néha eltérő okokból kifolyólag rendellenességekre is utalhat.</b> <br/><b>A Neuroszféra Központ erre specializálódott. felszerelést és tapasztalt csapat</b>ot, melynek köszönhetően pontos diagnosztikát tudunk végezni és a leghomályosabb neurológiai tünetek okát is jelezni tudjuk."
          }
        }
      }
    },
    "error": {
      "LOGIN_CRED_MISSING": "Bejelentkezés és jelszó szükséges",
      "LOGIN_INVALID_CRED": "Érvénytelen felhasználónév vagy jelszó",
      "PASS_AND_CONF_NOT_EQUAL": "A jelszó és a megerősítés eltérő",
      "AUTHORIZATION": "jogosulatlan hozzáférés az erőforráshoz, irányítás a bejelentkezési oldalra",
      "WRONG_PROFILE": "A bejelentkezés nem sikerült, mert rossz profilt választott ki"
    },
    "validation": {
      "required": "Kötelező mező",
      "alphanumeric": "Csak betűket és számjegyeket tartalmazhat",
      "email": "Érvénytelen e-mail cím",
      "invalidRange": "Érvénytelen tartomány",
      "nip": "Érvénytelen NIP-szám",
      "regon": "Érvénytelen REGON szám",
      "pesel": "Érvénytelen PESEL-szám",
      "max": "Legfeljebb {length} karakter megengedett",
      "min": "Legalább {length} karakter szükséges",
      "passNonMatching": "A jelszavak nem egyeznek",
      "maxFileSize": "A fájl mérete nem haladhatja meg a {size} MB-ot",
      "pwz": "Érvénytelen PWZ-szám",
      "zusCertificate": "Az engedélyezett fájlkiterjesztés .pfx",
      "password": {
        "lowercase": "Kisbetű kötelező",
        "uppercase": "Nagybetű kötelező",
        "minimumChar": "Legalább {length} karakter",
        "numericChar": "Egy szám szükséges",
        "specialChar": "Speciális karakter szükséges, a következő karakterek állnak rendelkezésre: !@$\\-_.*"
      }
    },
    "office": {
      "phone": "+48 609 463 191",
      "phoneHref": "tel:+48609463191",
      "mail": "biuro@neurosphera.pl",
      "mailHref": "mailto:biuro@neurosphera.pl"
    },
    "appointWidget": {
      "appoint": {
        "title": "Ütemezze be a látogatást",
        "visitTypeLabel": "Látogatás típusa",
        "visitLocationLabel": "Elhelyezkedés",
        "doctorListDisclaimer": "A végső ár a látogatás típusától függ",
        "visitTypeListDisclaimer": "A végső ár a látogatás típusától függ",
        "doctorListHeader": "Válassz orvost",
        "visitTypeListHeader": "Válassza ki a látogatás típusát",
        "visitLocationListHeader": "Válassza ki a látogatás helyét",
        "doctorLabel": "Orvos",
        "startingPricePrefix": "tól től",
        "clearSelection": "Kiválasztás törlése",
        "visitOrder": "Ez az első látogatása ennél a szakembernél?",
        "visitOrderFirst": "Igen",
        "visitOrderNext": "Nem",
        "appointment": {
          "selectHeader": {
            "START_PACKAGE": "Ütemezze be a 0. és az 1. látogatást – epilepszia",
            "MIGRATION_VISIT": "A migrációs látogatás ütemezése",
            "STANDARD_VISIT": "Rendszeres látogatás ütemezése - epilepszia",
            "NEUROSURGEON_CONSULTATION": "Időpont idegsebészeti konzultáció",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Egyeztessen egy neuropszichológiai konzultációt",
            "NEUROLOGOPEDIST_CONSULTATION": "Foglaljon be logopédus (neurológiai) konzultációt",
            "PSYCHIATRIST_CONSULTATION": "Kérjen időpontot pszichiátriai konzultációra",
            "GENETICIST_CONSULTATION": "Egyeztessen genetikus konzultációt",
            "EPILEPTOLOGIST_CONSULTATION": "Egyeztessen epileptológus konzultációt",
            "EEG_CONSULTATION": "EEG vizsgálat ütemezése",
            "NEUROLOGIST_CONSULTATION": "Időpont neurológiai konzultáció",
            "DIETICIAN_CONSULTATION": "Foglaljon időpontot dietetikus konzultációra",
            "VNS_CONSULTATION": "Ütemezzen egy VNS látogatást",
            "ABSENT_CONSULTATION": "Távollétben történő konzultáció ütemezése",
            "METABOLIC_CONSULTATION": "Egyeztessen egy anyagcsere konzultációt",
            "SLEEP_DISTURBANCE_CONSULTATION": "Egyeztessen alvászavarral kapcsolatos konzultációt",
            "ADHD_PACKAGE": "Ütemezze be az ADHD csomaglátogatásokat",
            "REGULAR_VISIT_EEG_PACKAGE": "Rendszeres epilepsziás vizit és EEG ütemezése",
            ADHD_CONSULTATION: 'ADHD coaching ülés',
            PSYCHOTHERAPY_CONSULTATION: 'Pszichoterápiás foglalkozás'
          },
          "description": {
            "START_PACKAGE": "A 0. vizit egy ingyenes vizit, melynek során részletes interjút készítünk a páciens kórtörténetéről, az 1. látogatást megelőzően. Az 1. vizit során diagnózis, terápia és átfogó gondozási terv készül a következő évre. A 0. látogatás kb. 1 óra, látogatás 1-30 perc; mindkettő történhet a helyszínen vagy online.",
            "MIGRATION_VISIT": "Migrációs látogatás",
            "STANDARD_VISIT": "A rendszeres epileptológiai vizit azoknak a jelenlegi Neurosphera betegeknek szól, akik már átestek az 1. viziten vagy a migrációs viziten.",
            "NEUROSURGEON_CONSULTATION": "A konzultáció javasolt krónikus neurológiai problémák esetén, mint pl.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "A következő esetekben jelezve:",
            "NEUROLOGOPEDIST_CONSULTATION": "Logopédus (neurológiai) konzultáció",
            "PSYCHIATRIST_CONSULTATION": "Látogatás mentális egészségügyi problémákkal küzdő emberek számára, beleértve:",
            "GENETICIST_CONSULTATION": "A következő esetekben jelezve:",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
            "EEG_CONSULTATION": "Az epilepszia diagnózisának alapvizsgálata, valamint:",
            "NEUROLOGIST_CONSULTATION": "A következő esetekben jelezve:",
            "DIETICIAN_CONSULTATION": "Látogatás embereknek:",
            "VNS_CONSULTATION": "VNS látogatás",
            "ABSENT_CONSULTATION": "Távollétben történő konzultáció",
            "METABOLIC_CONSULTATION": "Veleszületett anyagcsere-rendellenességek esetén javasolt. Egyes anyagcsere-betegségek hozzájárulhatnak az epilepszia kialakulásához. Érdemes a genetikai vizsgálatok során kiértékelni, hogy fennáll-e a betegség gyanúja.",
            "SLEEP_DISTURBANCE_CONSULTATION": "A következő esetekben jelezve:",
            "ADHD_PACKAGE": "A csomag 2 pszichológusi látogatást (egyenként 1,5 órás) és neurológusi vagy pszichiáter látogatást tartalmaz. A csomag azoknak szól, akik észreveszik:",
            "REGULAR_VISIT_EEG_PACKAGE": "A csomag tartalmazza az EEG vizsgálatot és a rendszeres stacioner látogatást. A csomag azoknak a jelenlegi idegrendszeri betegeknek szól, akik már elvégezték az 1. látogatást vagy a migrációs látogatást. Iratkozz fel csomagra, ha:",
            "MMPI2_PACKAGE": "Lehetővé tesszük a személyiségzavarok diagnosztizálását a Lengyelországban elérhető legkiterjedtebb és univerzális klinikai értékelő eszközzel - a Minnesota Multidimensional Personality Inventory (az MMPI-2 teljes adaptációja 2012-ben jelent meg).",
            "CBT_PACKAGE": "Az álmatlanság kezelésére szolgáló kognitív viselkedésterápia (CBT) az álmatlansággal küzdő emberek első vonalbeli kezelésének számít, amelyet olyan hatóságok javasolnak, mint a National Institutes of Health (NIH), a National Institute for Health and Care Excellence (NICE) és az American College. az orvos.",
            ADHD_CONSULTATION: 'Az egyéni coaching egy intenzív, egyórás találkozó egy tapasztalt ADHD edzővel, amely az Ön aktuális kihívásaira és céljaira összpontosít.',
            PSYCHOTHERAPY_CONSULTATION: 'A pszichoterápiás foglalkozás a páciens (kliens) és a pszichoterapeuta találkozása, melynek célja érzelmi, mentális vagy viselkedési problémák közös megoldása.'
          },
          "bulletPoints": {
            "DIETICIAN_CONSULTATION": [
              "for whom the ketogenic diet is indicated by physician or is of interest",
              "who are using a ketogenic diet and want to review their current treatment",
              "they want to verify their current diet"
            ],
            "EEG_CONSULTATION": [
              "to indicate the causes of sleep disorders",
              "in head injuries and recurring headaches",
              "fainting or loss of consciousness"
            ],
            "NEUROLOGIST_CONSULTATION": [
              "fainting and loss of consciousness, problems with balance and coordination",
              "chronic headaches combined with dizziness",
              "deterioration of memory and trouble concentrating",
              "tingling in the extremities with a disturbed sensation"
            ],
            "PSYCHIATRIST_CONSULTATION": [
              "depression",
              "anxiety disorders",
              "sleep problems",
              "unnaturally elevated mood and hyperactivity"
            ],
            "GENETICIST_CONSULTATION": [
              "family history of diseases with genetic causes",
              "the exclusion of congenital disease as a cause of epilepsy",
              "assessment of the risk of a child having epilepsy in the event of a planned pregnancy",
              "before performing the WES test"
            ],
            "NEUROSURGEON_CONSULTATION": [
              "chronic pain and injuries of the spine",
              "brain tumors and neoplastic changes in the spine",
              "numbness, tingling and paresis of the limbs",
              "severe headaches and dizziness, visual and hearing disturbances",
              "consultation of magnetic resonance imaging of the head"
            ],
            "NEUROPSYCHOLOGIST_CONSULTATION": [
              "problems in normal functioning that have arisen as a result of, for example, head injuries, brain tumors, diseases that destroy nervous tissue",
              "impairment of memory or concentration",
              "speech, orientation or wayfinding difficulties",
              "visual disturbances despite normal ophthalmic results"
            ],
            "SLEEP_DISTURBANCE_CONSULTATION": [
              "daytime sleepiness or irritability",
              "sleepiness or insomnia caused by emotional factors",
              "disruption of the daily rhythm of sleep and waking up",
              "snoring while sleeping"
            ],
            "ADHD_PACKAGE": [
              "Difficulty Organizing",
              "Underestimating time",
              "interpersonal difficulties",
              "Emotional difficulties"
            ],
            "REGULAR_VISIT_EEG_PACKAGE": [
              "You haven't had an EEG done in the last year",
              "You are planning a regular stationary visit with an Egyptologist",
              "You feel a lack of improvement or deterioration of your health"
            ]
          },
          "detailsHeader": {
            "VISIT_0": "Látogatás 0 - epilepszia",
            "MIGRATION_VISIT": "Migrációs látogatás",
            "VISIT_1": "1. látogatás - epilepszia",
            "START_PACKAGE": "Látogassa meg a 0-t és az 1-et",
            "STANDARD_VISIT": "Rendszeres látogatás - epilepszia",
            "NEUROSURGEON_CONSULTATION": "Konzultáció - idegsebész",
            "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultáció - neuropszichológus",
            "NEUROLOGOPEDIST_CONSULTATION": "Konzultáció - logopédus (neurológus)",
            "PSYCHIATRIST_CONSULTATION": "Konzultáció - pszichiáter",
            "GENETICIST_CONSULTATION": "Konzultáció - genetikus",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
            "EEG_CONSULTATION": "Rendeljen EEG-vizsgálatot",
            "NEUROLOGIST_CONSULTATION": "Konzultáció - neurológus",
            "DIETICIAN_CONSULTATION": "Konzultáció - dietetikus",
            "VNS_CONSULTATION": "VNS látogatás",
            "ABSENT_CONSULTATION": "Távollétben történő konzultáció",
            "METABOLIC_CONSULTATION": "Anyagcsere konzultáció",
            "SLEEP_DISTURBANCE_CONSULTATION": "Alvászavarral kapcsolatos konzultáció",
            "ADHD_PACKAGE": "ADHD csomag",
            "REGULAR_VISIT_EEG_PACKAGE": "Rendszeres epilepsziás vizit és EEG",
            ADHD_CONSULTATION: 'ADHD coaching ülés',
            PSYCHOTHERAPY_CONSULTATION: 'Pszichoterápiás foglalkozás'
          },
          "details": {
            "VISIT_0": "A 0. látogatás egy előkészítő látogatás, amely során egy mélyreható, nagyon részletes interjút készítenek. A látogatás kb. 1 óra. A 0. látogatást az 1. látogatás előtt kell végrehajtani.",
            "MIGRATION_VISIT": "",
            "VISIT_1": "Az 1. vizit alkalmával a diagnózis megerősítésre kerül, és átfogó gondozási tervet készítenek. A látogatás kb. 30 perc.",
            "STANDARD_VISIT": "A látogatás időtartama körülbelül 30 perc.",
            "NEUROSURGEON_CONSULTATION": "A konzultáció időtartama 15-45 perc.",
            "NEUROPSYCHOLOGIST_CONSULTATION": "A konzultáció időtartama körülbelül 1 óra.",
            "NEUROLOGOPEDIST_CONSULTATION": "Konzultáció - logopédus (neurológus)",
            "PSYCHIATRIST_CONSULTATION": "A konzultáció időtartama kb. 45 perc.",
            "GENETICIST_CONSULTATION": "A konzultáció időtartama kb. 45 perc.",
            "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
            "EEG_CONSULTATION": "Ütemezze be az EEG-vizsgálatot",
            "NEUROLOGIST_CONSULTATION": "Ütemezés:<ul><li>migrén vagy fejfájás</li><li>gerincfájdalom, érzékszervi zavar</li><li>eszméletvesztés</li><li>stroke, memóriazavar</li><li>egyéb neurológiai betegség</li></ul></br>A konzultáció időtartama kb. 30 perc.",
            "DIETICIAN_CONSULTATION": "A konzultáció időtartama körülbelül 1 óra.",
            "VNS_CONSULTATION": "VNS látogatás",
            "ABSENT_CONSULTATION": "Távollétben történő konzultáció",
            "METABOLIC_CONSULTATION": "A konzultáció időtartama kb. 30 perc.",
            "SLEEP_DISTURBANCE_CONSULTATION": "A konzultáció időtartama kb. 45 perc.",
            ADHD_CONSULTATION: 'A foglalkozás időtartama kb. 1 óra.',
            PSYCHOTHERAPY_CONSULTATION: 'A foglalkozás időtartama kb. 1 óra.'
          }
        },
        "remoteVisitDescription": "Távoli látogatás Neuroterminálon keresztül. Szükség van egy kamerával és mikrofonnal rendelkező, internethez csatlakoztatott eszközre",
        "details": "Részletek",
        "visitHeader": {
          "VISIT_0": "Látogassa meg a 0",
          "MIGRATION_VISIT": "Migrációs látogatás",
          "VISIT_1": "Látogassa meg az 1",
          "START_PACKAGE": "Látogassa meg a 0-t és az 1-et",
          "STANDARD_VISIT": "Rendszeres látogatás",
          "NEUROSURGEON_CONSULTATION": "Konzultáció - idegsebész",
          "NEUROPSYCHOLOGIST_CONSULTATION": "Konzultáció - neuropszichológus",
          "NEUROLOGOPEDIST_CONSULTATION": "Konzultáció - logopédus (neurológus)",
          "PSYCHIATRIST_CONSULTATION": "Konzultáció - pszichiáter",
          "GENETICIST_CONSULTATION": "Konzultáció - genetikus",
          "EPILEPTOLOGIST_CONSULTATION": "Konzultáció - epileptológus",
          "EEG_CONSULTATION": "Rendeljen EEG-vizsgálatot",
          "NEUROLOGIST_CONSULTATION": "Konzultáció - neurológus",
          "DIETICIAN_CONSULTATION": "Konzultáció - dietetikus",
          "VNS_CONSULTATION": "VNS látogatás",
          "ABSENT_CONSULTATION": "Távollétben történő konzultáció",
          "METABOLIC_CONSULTATION": "Anyagcsere konzultáció",
          "SLEEP_DISTURBANCE_CONSULTATION": "Alvászavarral kapcsolatos konzultáció",
          "ADHD_PACKAGE": "ADHD csomag",
          "REGULAR_VISIT_EEG_PACKAGE": "Rendszeres epilepsziás vizit és EEG"
        },
        "instruction": {
          "START_PACKAGE": "Ha nem látja az Ön számára megfelelő időpontot, próbáljon meg másik orvost, vizittípust vagy korábbi időpontot választani 0"
        },
        "visitPriceLabel": "A látogatás ára",
        "showAllSlots": "mutasson több órát",
        "hideSlots": "összeomlás",
        "nextLabel": "Következő",
        "validationErrorHeader": "Az összefoglaló megnyitásához válassza a következőket:",
        "ok": "Rendben",
        "validationError": {
          "slot": "Dátum",
          "location": "Elhelyezkedés",
          "doctor": "Orvos",
          "service": "Látogatás típusa"
        },
        "chooseLabel": "Választ",
        "doctorMissingLabel": "Orvos",
        "locationMissingLabel": "Látogatás típusa",
        "andLabel": "és",
        "unavailableDoctors": "Az orvosok a kiválasztott időpontban, típusban és/vagy vizittípusban nem elérhetők",
        "ADULT": "Felnőtt",
        "CHILD": "Gyermek",
        "visitNotAvailable": "Sajnáljuk, de az Ön által kiválasztott dátum: <b>{visitName}</b> nem érhető el. Menjen vissza, és válasszon másik dátumot",
        "visitNotSaved": "A látogatást még nem regisztrálták. Az időpontfoglalási folyamat befejezéséhez lépjen vissza az összefoglalóhoz."
      },
      "exitPoll": {
        "title": "Miért akarsz elmenni?",
        "description": "Válasza nagyon fontos számunkra! Ennek köszönhetően javítani tudjuk szolgáltatásainkat.",
        "SERVICE": "Nem találtam meg az engem érdeklő szolgáltatást",
        "DATE": "Nincs megfelelő látogatási dátum",
        "PRICE": "Nem tetszik a szolgáltatás ára",
        "OTHER": "Egyéb",
        "sendResponse": "Válasz küldése",
        "back": "Vissza az oldalra",
        "whatService": "Milyen szolgáltatást keresett?",
        "enterMessage": "Kérjük, írja be üzenetét",
        "reasonMissing": "Kérjük, indokolja lemondását"
      },
      "main": {
        "exitPopup": {
          "description": "Biztos benne, hogy meg akarja szakítani a találkozó folyamatát? Kiválasztásai nem kerülnek mentésre.",
          "confirm": "Igen, el akarok menni",
          "decline": "Nem, menj vissza az időpont egyeztetéshez"
        }
      },
      "summary": {
        "title": "Összegzés",
        "grossPrice": "Teljes összeg",
        "availableOnlinePayments": "Elfogadunk online fizetést",
        "transfer": "Banki átutalás",
        "installments": "0% törlesztőrészlet",
        "payment": "Fizetés",
        "paymentDescription": "A fizetés folytatásához kérjük, adja meg e-mail címét. Összefoglalót és fizetési linket küldünk.",
        "descriptionExistingAccount": "Ha rendelkezik fiókkal a Neuroterminálon, kérjük, adja meg a bejelentkezéshez használt e-mail címét.",
        "makeReservations": "Foglaljon időpontokat",
        "makeReservation": "Foglaljon időpontot",
        "unexpectedError": "Váratlan hiba történt a találkozó ütemezése közben. Kérjük, próbálja újra."
      },
      "thanku": {
        "title": "Köszönöm!",
        "emailHasBeenSent": "Összefoglalót és fizetési linket tartalmazó üzenetet küldtünk a megadott e-mail címre:",
        "finalizeAppointment": "Nyissa meg e-mailjét, és az elküldött fizetési linkre kattintva <b>fejezze be az időpont-ütemezési folyamatot</b>. <b>{XXXXXX}</b>-ig kell befejeznie az időpont-ütemezési folyamatot.",
        "resendEmail": "Nem kapta meg az e-mailt? Ellenőrizze a Spam mappát vagy",
        "resendEmailLink": "E-mail újraküldése",
        "resendEmailDone": "Az e-mailt újraküldtük",
        "backToNeurosphera": "Vissza a neurosphera.pl oldalra"
      }
    },
    "server": {
      "episode": {
        "description": "A látogatás során hozzáadták a rohamepizódot"
      },
      "generatedepisode": {
        "description": "A látogatás során hozzáadták a rohamepizódot"
      }
    },
    "enums": Enums.hu,
    "serverErrors": ServerErrors.hu,
    "dates": {
      "today": "ma",
      "tomorrow": "holnap"
    }
  },
  "formats": {
    "currency": {
      "style": "valuta",
      "currency": "usadollár"
    }
  }
}