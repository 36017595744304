import Countries from "../countries/countries";

export default {
  "SeizureTypeEnum": {
    "IA": "Fokaler einfacher Anfall",
    "IB": "Fokaler komplexer Anfall",
    "IC": "Fokaler Anfall, der sich zu einem generalisierten Anfall entwickelt",
    "IIA": "Absence-Anfall",
    "IIB": "Mioklonischer Anfall",
    "IIC": "Tonischer Anfall",
    "IID": "Klonischer Anfall",
    "IIE": "Tonisch-klonischer Anfall",
    "IIF": "Atonischer Anfall",
    "GEN": "Generierter Anfall",
    "UNK": "Unbekannter Anfall"
  },
  "SeizureTypeBriefEnum": {
    "IA": "Fokaler einfacher Anfall",
    "IB": "Fokaler komplexer Anfall",
    "IC": "Fokaler Anfall, der sich zu einem generalisierten Anfall entwickelt",
    "IIA": "Abwesenheit",
    "IIB": "Mioklonisch",
    "IIC": "Tonic",
    "IID": "Klonisch",
    "IIE": "Tonisch-klonisch",
    "IIF": "Atonisch",
    "GEN": "Generiert",
    "UNK": "Unbekannt"
  },
  "MedicalEventTypeEnum": {
    "SEIZURE": "Beschlagnahme",
    "MENSTRUATION": "Menstruation",
    "INFECTION": "Infektion",
    "SLEEP_DEPRIVATION": "Schlafentzug",
    "FEVER": "Fieber",
    "OTHER": "Andere",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START": "Beginn der Schwangerschaft oder Vorschwangerschaft",
    "PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END": "Ende der Schwangerschaft oder Vorschwangerschaft"
  },
  "MessageTypeEnum": {
    "EMAIL": "Email",
    "SMS": "SMS",
    "SYS": "System",
    "CHAT": "Plaudern"
  },
  "MessageStatusEnum": {
    "NEW": "neu",
    "SENT": "gesendet",
    "READ": "lesen"
  },
  "MessageMarkerEnum": {
    "MARKED": "markiert",
    "NOT_MARKED": "nicht markiert"
  },
  "MedicalServiceSystemItemEnum": {
    "SOS_TELECONFERENCE": "SOS-Telefonkonferenz",
    "SOS_CHAT": "SOS-Chat"
  },
  "MedicalServiceGroupEnum": {
    "VISIT_0": "Umfrage 0",
    "MIGRATION_VISIT": "Migrationsbesuch",
    "VISIT_1": "Besuchen Sie 1",
    "STANDARD_VISIT": "Standardbesuch",
    "MESSAGE": "Nachricht",
    "PRESCRIPTION": "Rezept",
    "NEUROSURGEON_CONSULTATION": "Beratung - Neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Beratung - Neuropsychologe",
    "NEUROLOGOPEDIST_CONSULTATION": "Beratung - Neurologe",
    "PSYCHIATRIST_CONSULTATION": "Beratung - Psychiater",
    "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
    "NEUROLOGIST_CONSULTATION": "Beratung - Neurologe",
    "DIETICIAN_CONSULTATION": "Beratung - Ernährungsberater",
    "VNS_CONSULTATION": "VNS-Besuch",
    "GENETICIST_CONSULTATION": "Beratung - Genetiker",
    "ABSENT_CONSULTATION": "Fehlende Beratung",
    "METABOLIC_CONSULTATION": "Stoffwechselberatung",
    "EEG_CONSULTATION": "EEG-Untersuchung",
    "SLEEP_DISTURBANCE_CONSULTATION": "Beratung bei Schlafstörungen",
    "PARTNER_CONSULTATION": "Partnerberatung",
    "ADHD_CONSULTATION": 'ADHS-Coaching-Sitzung',
    "PSYCHOTHERAPY_CONSULTATION": 'Psychotherapiesitzung'
  },
  "MedicalServiceGroupConsultantEnum": {
    "NEUROSURGEON_CONSULTATION": "Neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Neuropsychologe",
    "NEUROLOGOPEDIST_CONSULTATION": "Neurologe",
    "PSYCHIATRIST_CONSULTATION": "Psychiater",
    "EPILEPTOLOGIST_CONSULTATION": "Epileptologe",
    "NEUROLOGIST_CONSULTATION": "Neurologe",
    "DIETICIAN_CONSULTATION": "Ernährungsberater",
    "VNS_CONSULTATION": "VNS-Berater",
    "GENETICIST_CONSULTATION": "Genetiker",
    "METABOLIC_CONSULTATION": "Stoffwechselberater",
    "EEG_CONSULTATION": "EEG/VEEG-Berater",
    "SLEEP_DISTURBANCE_CONSULTATION": "Berater für Schlafstörungen"
  },
  "MedicalServiceEnum": {
    "VISIT_0": "Umfrage 0",
    "MIGRATION_VISIT": "Migrationsbesuch",
    "VISIT_1": "Besuchen Sie 1",
    "STANDARD_VISIT": "Standardbesuch",
    "MESSAGE": "Nachricht",
    "PRESCRIPTION": "Rezept",
    "NEUROSURGEON_CONSULTATION": "Beratung - Neurochirurg",
    "NEUROPSYCHOLOGIST_CONSULTATION": "Beratung - Neuropsychologe",
    "NEUROLOGOPEDIST_CONSULTATION": "Beratung - Neurologe",
    "PSYCHIATRIST_CONSULTATION": "Beratung - Psychiater",
    "EPILEPTOLOGIST_CONSULTATION": "Beratung - Epileptologe",
    "NEUROLOGIST_CONSULTATION": "Beratung - Neurologe",
    "DIETICIAN_CONSULTATION": "Beratung - Ernährungsberater",
    "VNS_CONSULTATION": "VNS-Besuch",
    "GENETICIST_CONSULTATION": "Beratung - Genetiker",
    "ABSENT_CONSULTATION": "Fehlende Beratung",
    "METABOLIC_CONSULTATION": "Stoffwechselberatung",
    "EEG_CONSULTATION": "Standard-EEG-Untersuchung 30 Min",
    "VEEG_ON_STANDBY_CONSULTATION": "VEEG-Untersuchung im Wachzustand 1-6 h",
    "VEEG_DURING_SLEEP_CONSULTATION": "VEEG-Untersuchung im Schlaf 1-6 h",
    "VEEG_NIGHT_CONSULTATION": "VEEG-Untersuchung 12 Std",
    "VEEG_DAY_CONSULTATION": "VEEG-Untersuchung 24 Stunden",
    "SLEEP_DISTURBANCE_CONSULTATION": "Beratung bei Schlafstörungen",
    "CASE_CONFERENCE": "Medizinische Fallkonferenz",
    "VEEG_ON_STANDBY_CONSULTATION_1H": "VEEG Untersuchung im Wachzustand 1 h",
    "VEEG_ON_STANDBY_CONSULTATION_2H": "VEEG Untersuchung im Wachzustand 2 h",
    "VEEG_ON_STANDBY_CONSULTATION_6H": "VEEG Untersuchung im Wachzustand 6 h",
    "VEEG_DURING_SLEEP_CONSULTATION_1H": "VEEG-Untersuchung im Schlaf 1 h",
    "VEEG_DURING_SLEEP_CONSULTATION_2H": "VEEG-Untersuchung im Schlaf 2 h",
    "VEEG_DURING_SLEEP_CONSULTATION_6H": "VEEG-Untersuchung im Schlaf 6 h",
    "ADHD_CONSULTATION": 'ADHS-Coaching-Sitzung',
    "PSYCHOTHERAPY_CONSULTATION": 'Psychotherapiesitzung'
  },
  "VisitTypeEnum": {
    "STATIONARY": "Stationär",
    "REMOTE": "Fernbedienung",
    "ABSENT": "Abwesend"
  },
  "VisitTypeDescriptionEnum": {
    "STATIONARY": "Der Besuch findet in einer Ambulanz in {place} statt. Während des Besuchs trifft der Arzt den Patienten direkt. Vor Ort prüft er die aktuelle Dokumentation des Patienten. Wenn ein Rezept ausgestellt wird, stellt der Arzt vor Ort ein Rezept aus und versendet es per SMS oder druckt es aus.",
    "REMOTE": "Online-Besuch, bei dem sich Arzt und Patient auf dem Bildschirm sehen. Die Verbindung erfolgt über Neuroterminal und erfordert keine Installation zusätzlicher Software. Die Dokumentation wird vom Patienten elektronisch über Neuroterminal übermittelt. Ist ein Rezept erforderlich, stellt der Arzt ein E-Rezept aus.",
    "ABSENT": "Eine Abwesenheitskonsultation ist eine Art Arztbesuch, der von einem ausgewählten Facharzt ohne Anwesenheit des Patienten durchgeführt wird. Eine Abwesenheitskonsultation dient der Analyse medizinischer Unterlagen oder einer zusätzlichen Untersuchung. Der Neurosphärenspezialist wird die Dokumentation analysieren, eine entsprechende Notiz und Empfehlungen für den Patienten verfassen."
  },
  "AffinityTypeEnumAdults": {
    "LEGAL_GUARDIAN": "Erziehungsberechtigter",
    "CURATOR": "Kurator",
    "SPOUSE": "Ehepartner"
  },
  "AffinityTypeEnumChildren": {
    "PARENT": "Elternteil",
    "ADOPTER": "Verehrer",
    "GUARDIAN": "zum Vormund ernannt"
  },
  "VisitTypeEnumUndefined": "Andere",
  "DocumentTypeEnum": {
    "REFERRAL_GENERAL_PURPOSE": "Allgemeine Empfehlung",
    "REFERRAL_CLINIC": "Überweisung an eine Fachklinik",
    "REFERRAL_TO_HOSPITAL": "Überweisung an das Krankenhaus",
    "REFERRAL_TO_PSYCHIATRIC_HOSPITAL": "Überweisung an eine psychiatrische Klinik",
    "REFERRAL_TO_SPECIALIST": "Überweisung an Facharzt",
    "REFERRAL_TO_LAB": "Überweisung an das Labor",
    "REFERRAL_TO_DIAGNOSTIC_LABORATORY": "Überweisung an diagnostisches Labor",
    "CERTIFICATE": "Zertifikat",
    "CERTIFICATE_FOR_ZUS": "Zertifikat für ZUS",
    "CERTIFICATE_OF_DISABILITY": "Behindertenausweis",
    "HEALTH_CERTIFICATE": "Gesundheitszertifikat",
    "INFORMATION_FOR_POZ_DOCTOR": "Informationen für POZ-Arzt",
    "APPLICATION_FOR_REFUND": "Antrag auf Rückerstattung",
    "APPLICATION_FOR_DRUG_REFUND": "Antrag auf Arzneimittelrückerstattung",
    "DEMAND_FOR_DRUG_FROM_ABROAD": "Nachfrage nach einem Medikament aus dem Ausland",
    "DEMAND_FOR_FOODSTUFF_FROM_ABROAD": "Nachfrage nach einem Lebensmittel aus dem Ausland",
    "OTHER": "Sonstige Unterlagen"
  },
  "MedicalResultDocumentTypeEnum": {
    "HOSPITAL_INFORMATION_CARD": "Informationskarte zur Krankenhausbehandlung",
    "DISEASE_HISTORY": "Krankheitsgeschichte",
    "VIDEO_EVENT": "Video - Veranstaltung",
    "LABORATORY_TEST_RESULT": "Labortestergebnis",
    "VISUAL_RESULT_MRI_FILE": "Testergebnisdatei - Magnetresonanz",
    "VISUAL_RESULT_MRI_DESC": "Beschreibung des Testergebnisses – Magnetresonanz",
    "VISUAL_RESULT_CT_FILE": "Testergebnisdatei - Computertomographie",
    "VISUAL_RESULT_CT_DESC": "Beschreibung des Testergebnisses - Computertomographie",
    "VISUAL_RESULT_EEG_FILE": "Testergebnisdatei - EEG",
    "VISUAL_RESULT_EEG_DESC": "Beschreibung des Testergebnisses – EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Zusätzliche Testergebnisdatei",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Zusätzliche Beschreibung des Testergebnisses",
    "PATIENT_CERTIFICATE": "Neurosphera-Zertifikat",
    "STATE_CERTIFICATE": "Staatszertifikat",
    "EEG_DESCRIPTION": "EEG-Beschreibung",
    "OTHER": "Andere"
  },
  "MedicalResultTypeEnum": {
    "VIDEO_EVENT": "Video",
    "LABORATORY_TEST_RESULT": "Labortest",
    "VISUAL_RESULT_MRI_FILE": "Magnetresonanz",
    "VISUAL_RESULT_MRI_DESC": "Magnetresonanz",
    "VISUAL_RESULT_CT_FILE": "Computertomographie",
    "VISUAL_RESULT_CT_DESC": "Computertomographie",
    "VISUAL_RESULT_EEG_FILE": "EEG",
    "VISUAL_RESULT_EEG_DESC": "EEG",
    "VISUAL_RESULT_ADDITIONAL_FILE": "Zusätzlich",
    "VISUAL_RESULT_ADDITIONAL_DESC": "Zusätzlich"
  },
  "DoctorTitle": {
    "LEK_MED": "lek. med.",
    "DR": "DR",
    "DR_MED": "dr n. med.",
    "DR_HAB": "Dr. hab.",
    "DR_PROF": "Prof. Dr. Hab.",
    "TECHN": "techn.",
    "STUDENT": "Student UM",
    "MGR": "mgr"
  },
  "PricelistItemGroupEnum": {
    "NORMAL_VISIT": "normaler Besuch",
    "SOS_VISIT": "SOS-Besuch",
    "DIAGNOSTIC": "Testbeschreibung",
    "OTHER": "andere"
  },
  "PricelistItemTypeEnum": {
    "FIRST_TIME": "pierwszorazowa",
    "SUBSEQUENT": "kolejna",
    "OTHER": "inne"
  },
  "SexEnum": {
    "FEMALE": "Weiblich",
    "MALE": "Männlich"
  },
  "SeizureFrequencyPeriodEnum": {
    "YEAR": "Jahr",
    "MONTH": "Monat",
    "DAY": "Tag"
  },
  "BloodType": {
    "TYPE_A": "A",
    "TYPE_B": "B",
    "TYPE_AB": "AB",
    "TYPE_0": "0"
  },
  "RhType": {
    "RH_PLUS": "RH+",
    "RH_MINUS": "RH-"
  },
  "PsychomotorDevelopmentOverallEnum": {
    "NORMAL": "Normal",
    "RETARDED": "Verzögert",
    "VERY_RETARDED": "Sehr zurückgeblieben",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "PsychomotorRetardationEnum": {
    "NO": "NEIN",
    "LIGHT": "Licht",
    "MODERATE": "Mäßig",
    "SIGNIFICANT": "Bedeutsam"
  },
  "PsychomotorSpeechEnum": {
    "NORMAL": "Normal",
    "DISTURBED": "Gestört",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "PsychomotorWalkEnum": {
    "NORMAL": "Normal",
    "INCORRECT": "Falsch",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "PsychomotorSexEnum": {
    "NORMAL": "Normal",
    "LIBIDO_DISORDERS": "Libidostörungen",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "NystagmusEnum": {
    "NO": "NEIN",
    "VESTIBULAR": "Vestibular",
    "CNS": "ZNS"
  },
  "VisionEnum": {
    "NORMAL": "Normal",
    "DEFECT_PLUS": "Sehfehler plus (+)",
    "DEFECT_MINUS": "Sehfehler minus (-)",
    "DEFECT_OTHER": "Anderer Sehfehler",
    "BLIND": "Blind",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "HearingEnum": {
    "NORMAL": "Normal",
    "DISTURBED": "Gestört",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "SmokingEnum": {
    "NO": "NEIN",
    "YES_IN_PAST": "Ja, in der Vergangenheit",
    "YES_NOW": "Ja, derzeit",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "AlcoholEnum": {
    "NO": "NEIN",
    "SOMETIMES_LITTLE": "Manchmal wenig",
    "SOMETIMES_A_LOT": "Manchmal viel",
    "OFTEN_LITTLE": "Oft wenig",
    "OFTEN_A_LOT": "Oft sehr viel",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "NarcoticsEnum": {
    "NO": "NEIN",
    "YES_IN_PAST": "Ja, in der Vergangenheit",
    "YES_NOW_RARELY": "Ja, derzeit – gelegentlich",
    "YES_NOW_OFTEN": "Ja, obecnie – oft",
    "NOT_APPLICABLE": "Unzutreffend"
  },
  "SeizuresCharacteristicsFrequencyEnum": {
    "ALWAYS": "Ja (1 Anfall) / Ja, immer (100 % Anfälle)",
    "VERY_OFTEN": "Ja, sehr oft (75–99 % der Anfälle)",
    "OFTEN": "Ja, häufig (51–74 % der Anfälle)",
    "SOMETIMES": "Ja, manchmal (~ 50 % der Anfälle)",
    "RARELY": "Ja, aber selten (25–49 % der Anfälle)",
    "VERY_RARELY": "Ja, aber äußerst selten (1–24 % der Anfälle)",
    "NEVER": "Nein (1 Anfall) / Nein, nie (0 % Anfälle)",
    "NOT_KNOWN": "Ich weiß es nicht"
  },
  "DrugHistoryTimeEnum": {
    "PAST": "Ja, in der Vergangenheit",
    "CURRENTLY": "Ja, derzeit"
  },
  "DrugHistoryEffectEnum": {
    "REMISSION": "Remission",
    "BIG_IMPROVEMENT": "Verbesserung > 50 %",
    "LITTLE_IMPROVEMENT": "Bis zu 50 % Verbesserung",
    "NO_CHANGE": "Keine Änderung",
    "LITTLE_DETERIORATION": "Leichte Verschlechterung",
    "BIG_DETERIORATION": "Deutliche Verschlechterung",
    "EXTREME_DETERIORATION": "Extreme Verschlechterung/Beseitigung von Nebenwirkungen (z. B. Hautausschlag)"
  },
  "SeizuresCharacteristicsDurationEnum": {
    "VERY_LONG": "Sehr lang, mehr als 5 Minuten",
    "LONG": "Lang, 1 bis 5 Minuten",
    "BIT_LONG": "30 bis 60 Sekunden",
    "VARIABLE": "Ganz anders, wandelbar",
    "SHORT": "Kurzzeitig von einigen bis 30 Sekunden",
    "VERY_SHORT": "Sehr kurz, weniger als 5 Sek",
    "ULTRA_SHORT": "Ultrakurz, ein Bruchteil einer Sekunde bis zu einer Sekunde",
    "NOT_KNOWN": "Ich weiß es nicht"
  },
  "UserAccountSettingCodeEnum": {
    "VISIT_CANCELED_BY_DOCTOR": "Warnt, wenn der Arzt einen Besuch absagt",
    "VISIT_CANCELLED_BY_PATIENT": "Warnt, wenn der Patient einen Besuch absagt",
    "VISIT_CHANGED_BY_PATIENT": "Warnt, wenn der Patient die Besuchszeit ändert",
    "VISIT_APPOINTED_REMINDER": "Erinnerungen an Besuche am nächsten Tag",
    "VISIT_PLANNED_REMINDER": "Erinnerungen an am nächsten Tag geplante Besuche",
    "DUTY_PLANNED_REMINDER": "Erinnerungen an geplante Aufgaben am nächsten Tag",
    "ADMIT_PATIENTS": "Sichtbar für Patienten in der Arztdatenbank",
    "MESSAGE_RECEIVED_BY_DOCTOR": "Warnt, wenn der Patient eine Nachricht sendet",
    "PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR": "Warnt, wenn der Patient eine Rezeptanfrage sendet"
  },
  "VisitCancellationReason": {
    "INCONVENIENT_DATE": "Ungünstiges Datum",
    "DOCTOR_CHANGE": "Arztwechsel"
  },
  "DoctorTypeEnum": {
    "RESIDENT": "Klinischer Assistenzarzt",
    "ASSISTANT": "Assistent",
    "SPECIALIST": "Klinischer Spezialist",
    "EXPERT": "Medizinischer Partner",
    "CONSULTANT": "Klinischer Berater",
    "INTERNATIONAL_CONSULTANT": "Internationaler klinischer Berater",
    "PARTNER_CONSULTANT": "Partnerberater"
  },
  "PatientTypeEnum": {
    "INFANT": "Kinder bis 2 Jahre",
    "CHILD": "Kinder von 2 bis 12 Jahren",
    "YOUTH": "Jugendliche von 12 bis 18 Jahren",
    "ADULT": "Erwachsene von 18 bis 65 Jahren",
    "SENIOR": "Senioren über 65"
  },
  "HasEpilepsyEnum": {
    "NO": "NEIN",
    "YES": "Ja",
    "NOT_KNOWN": "Nicht bekannt"
  },
  "HealthSurveyPartEnum": {
    "PSYCHOMOTOR_DEVELOPMENT": "psychomotorische Entwicklung",
    "SEIZURE_TYPES": "Anfallsarten",
    "SEIZURES_HISTORY": "Anfallsgeschichte",
    "SEIZURES_CHARACTERISTICS": "Merkmale von Anfällen",
    "COMORBIDITIES": "Komorbiditäten",
    "DRUGS_HISTORY": "Behandlung"
  },
  "TimeRangeEnum": {
    "1M": "1 Monat",
    "3M": "3 Monate",
    "6M": "6 Monate",
    "1Y": "1 Jahr",
    "2Y": "2 Jahre",
    "3Y": "3 Jahre",
    "SINCE_FIRST_SEIZURE": "Seit dem ersten Anfall",
    "SINCE_BIRTH": "Seit der Geburt",
    "1H": "1 Stunde",
    "2H": "2 Stunden",
    "3H": "3 Stunden",
    "4H": "4 Stunden",
    "5H": "5 Stunden",
    "6H": "6 Stunden"
  },
  "ShortTimeRangeEnum": {
    "h": "H",
    "m": "Mindest"
  },
  "MedicalRequestStatusEnum": {
    "NEW": "Neu",
    "ACCEPTED": "Akzeptiert",
    "DONE": "Erledigt",
    "REJECTED": "Abgelehnt",
    "CANCELLED": "Abgesagt"
  },
  "PersonalDocumentEnum": {
    "PASSPORT": "Reisepass",
    "DRIVING_LICENSE": "Fahrerlaubnis"
  },
  "NSPHSourceEnum": {
    "INTERNET_SEARCH": "Internetsuche",
    "SOCIAL_MEDIA": "Sozialen Medien",
    "INTERNET_ARTICLE": "Internetartikel",
    "PRESS_ARTICLE": "Presseartikel",
    "TV": "Fernsehen",
    "FAMILY_FRIENDS": "Von Familie oder Freunden",
    "DOCTOR": "Vom Arzt",
    "OTHER": "Andere"
  },
  "NFZBranchEnum": {
    "NFZ_01": "01 – Niederschlesische Zweigstelle des Nationalen Gesundheitsfonds in Breslau",
    "NFZ_02": "02 – Zweigstelle Kujawsko-Pomorskie des Nationalen Gesundheitsfonds in Bydgoszcz",
    "NFZ_03": "03 – Lubliner Zweigstelle des Nationalen Gesundheitsfonds in Lublin",
    "NFZ_04": "04 – Zweigstelle Lubuski des Nationalen Gesundheitsfonds in Zielona Góra",
    "NFZ_05": "05 – Łódź-Zweigstelle des Nationalen Gesundheitsfonds in Łódź",
    "NFZ_06": "06 – Małopolska-Zweigstelle des Nationalen Gesundheitsfonds in Krakau",
    "NFZ_07": "07 – Mazowiecki-Zweigstelle des Nationalen Gesundheitsfonds in Warschau",
    "NFZ_08": "08 – Oppelner Zweigstelle des Nationalen Gesundheitsfonds in Oppeln",
    "NFZ_09": "09 – Podkarpackie-Zweigstelle des Nationalen Gesundheitsfonds in Rzeszów",
    "NFZ_10": "10 – Podlaski-Zweigstelle des Nationalen Gesundheitsfonds in Białystok",
    "NFZ_11": "11 – Pommersche Zweigstelle des Nationalen Gesundheitsfonds in Danzig",
    "NFZ_12": "12 – Schlesische Zweigstelle des Nationalen Gesundheitsfonds in Kattowitz",
    "NFZ_13": "13 – Świętokrzyski-Zweigstelle des Nationalen Gesundheitsfonds in Kielce",
    "NFZ_14": "14 – Zweigstelle Ermland-Masuren des Nationalen Gesundheitsfonds in Olsztyn",
    "NFZ_15": "15 – Wielkopolska-Zweigstelle des Nationalen Gesundheitsfonds in Posen",
    "NFZ_16": "16 – Westpommersche Zweigstelle des Nationalen Gesundheitsfonds in Stettin"
  },
  "VisitPackageEnum": {
    "START_PACKAGE": "Umfrage 0 + Besuch 1",
    "ADHD_PACKAGE": "ADHS-Paket",
    "REGULAR_VISIT_EEG_PACKAGE": "Regelmäßiger Besuch + EEG",
    "MMPI2_PACKAGE": "MMPI-2 Persönlichkeitstest",
    "CBT_PACKAGE": "Kognitive Verhaltenstherapie"
  },
  "CountryEnum": Countries.de,
  "WellBeingEnum": {
    "WORST": "maximal schlimmer/schlecht",
    "MUCH_WORSE": "viel schlimmer",
    "BIT_WORSE": "etwas schlimmer",
    "SAME": "Dasselbe",
    "BIT_BETTER": "ein bisschen besser",
    "MUCH_BETTER": "viel besser",
    "IDEAL": "vollkommene, volle Gesundheit"
  },
  "CurrentTreatmentEnum": {
    "WORST": "Total schlecht, es funktioniert nicht und ich möchte es nicht weitermachen",
    "VERY_BAD": "nicht gut, ich ändere es lieber",
    "BAD": "Mir geht es nicht gut, aber ich kann weitermachen",
    "NO_CHANGE": "Ich spüre keine Veränderung",
    "BIT_BETTER": "Es ist in Ordnung, ich fühle mich durch diese Behandlung etwas besser",
    "MUCH_BETTER": "Es gibt eine Besserung, die Behandlung hilft eindeutig",
    "IDEAL": "Die Behandlung ist perfekt, voller Erfolg"
  },
  "SideEffectsEnum": {
    "WORST": "Sie sind so schwerwiegend, dass ich diese Behandlung auf keinen Fall fortsetzen möchte",
    "BAD": "Sie sind erheblich und stören mich, aber bei Bedarf kann ich weitermachen",
    "BEARABLE": "Sie sind spürbar, aber nicht so schlimm, die Behandlung zu ändern",
    "MINIMAL": "Es ist in Ordnung, die Nebenwirkungen sind minimal, keine Wirkung auf mich"
  },
  "TreatmentEfficiencyEnum": {
    "IDEAL": "Perfekt",
    "GOOD": "Gut",
    "MEDIOCRE": "Mittelmäßig",
    "BAD": "Schlecht",
    "WORST": "Im schlimmsten Fall muss die Behandlung geändert werden"
  },
  "TreatmentSafetyEnum": {
    "TOTAL": "Gesamt",
    "ACCEPTABLE": "Akzeptabel",
    "WEAK": "Schwach",
    "BAD": "Schlecht",
    "WORST": "Schließt Medikamente und notwendige Veränderungen aus"
  },
  "AdditionalIncidentsEnum": {
    "NEW_DISEASES": "Neue Krankheiten",
    "INJURIES": "Verletzungen",
    "HOSPITALIZATIONS": "Krankenhausaufenthalt",
    "OTHER": "Andere",
    "NONE": "Keiner"
  },
  "SeizureCountEnum": {
    "MORE": "Mehr",
    "LESS": "Weniger",
    "SAME": "Dasselbe",
    "NONE": "Keiner"
  },
  "SeizureEvaluationEnum": {
    "WORSE": "Schlechter",
    "BETTER": "Feuerzeug",
    "NONE": "Keiner"
  },
  "AllSeizureRegisteredEnum": {
    "TRUE": "Ich habe sie alle registriert",
    "FALSE": "Ich habe sie nicht registriert – ich werde es jetzt tun"
  },
  "ExternalReferralTypeEnum": {
    "CLINIC": "in die Fachklinik",
    "LABORATORY": "zum Diagnostiklabor",
    "HOSPITAL": "für eine Krankenhausbehandlung"
  },
  "CertificateTypeEnum": {
    "DRUGS_INTAKE": "Eine Bescheinigung des Gesundheitszentrums über die eingenommenen Medikamente",
    "POSTPONE_VACCINATION": "Bescheinigung über die Verschiebung der Impfung",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Bescheinigung über keine Kontraindikationen für Impfungen",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Bescheinigung über keine Kontraindikationen für den Eingriff",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Bescheinigung, dass keine Kontraindikationen für die Teilnahme an den Kursen vorliegen",
    "NO_CONTRAINDICATION_FOR_WORK": "Bescheinigung, dass keine Kontraindikationen für die Ausübung körperlicher Arbeit bestehen",
    "OTHER": "Andere"
  },
  "CertificateTypeDescriptionEnum": {
    "DRUGS_INTAKE": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Ich bitte um die Verschreibung von Medikamenten am Wohnort. Der Patient nimmt die folgenden Medikamente ein",
    "POSTPONE_VACCINATION": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Ich bitte um eine Verschiebung der Impfungen bis",
    "NO_CONTRAINDICATION_FOR_VACCINE": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Gegen eine Impfpflicht bestehen keine Kontraindikationen",
    "NO_CONTRAINDICATION_FOR_PROCEDURE": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Es bestehen keine Kontraindikationen für eine Vollnarkose während des geplanten Eingriffs",
    "NO_CONTRAINDICATION_FOR_ACTIVITIES": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Es gibt keine Kontraindikationen für die Teilnahme an den Aktivitäten",
    "NO_CONTRAINDICATION_FOR_WORK": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet. Es gibt keine Kontraindikationen für körperliche Arbeit.",
    "OTHER": "Hiermit bestätige ich, dass sich der Patient in der Obhut des Neurosphera Zentrums für Neurologie, Epilepsie und Psychiatrie befindet."
  },
  "MessageVisitSummaryStatusEnum": {
    "NEW": "Unrealisiert",
    "PENDING": "Antwort abwarten",
    "RESPONDED": "Antwortete",
    "DONE": "Erledigt",
    "UNIDIRECTIONAL": "Unidirektional"
  },
  "SickLeaveTypeEnum": {
    "DISEASE": "Krankheit",
    "CARE": "Sich um die Kranken kümmern"
  },
  "SickLeaveIndicationEnum": {
    "SHOULD_LIE": "Sollte lügen",
    "CAN_WALK": "Kann laufen"
  },
  "SickLeaveInsuredInEnum": {
    "ZUS": "ZUS",
    "KRUS": "KRUS",
    "OTHER_PL": "andere in Polen",
    "OTHER_COUNTRY": "in einem anderen Land"
  },
  "SickLeaveStatusEnum": {
    "ISSUED": "Ausgegeben",
    "CANCELED": "Abgesagt"
  },
  "SurveyCompletedEnum": {
    "YES": "Ja",
    "NO": "NEIN",
    "PARTIALLY": "Halbfertig"
  },
  "EegCompletedEnum": {
    "YES": "Ja",
    "NO": "NEIN",
    "BEFORE_NEXT": "Wird vor Besuch 1 erledigt"
  },
  "DocplannerIssueTypeEnum": {
    "DP_VISIT_NOT_BOOKED": "Besuch in ZN nicht gebucht",
    "DP_VISIT_NOT_CANCELED": "Besuch in ZN nicht abgesagt",
    "DP_VISIT_NOT_MOVED": "Besuch nicht in ZN verschoben",
    "NOT_ELIGIBLE_FOR_CONSULTATION": "Neuer Patient - Beratung"
  },
  "DocplannerVisitStatusEnum": {
    "NEW": "Neu",
    "CANCELED_BY_SCHEDULER": "Nicht bezahlt",
    "CANCELED_BY_PERSON": "Abgesagt",
    "SOLVED": "Gelöst",
    "PAID_NOT_DONE": "Bezahlt",
    "DONE": "Erledigt"
  },
  "ReferralStatusEnum": {
    "NEW": "Neu",
    "CANCELLED": "Abgesagt",
    "DONE": "Erledigt"
  },
  "VisitCaseSolvedEnum": {
    "APPOINTMENT": "Termin",
    "RESIGNATION": "Rücktritt",
    "OTHER": "Andere"
  },
  "VisitPaymentMarkingEnum": {
    "WAITING_FOR_PAYMENT": "Zur Zahlung markiert",
    "PAYMENT_RECEIVED": "Als bezahlt markiert"
  },
  "PaymentProviderEnum": {
    "PAYU": "PayU",
    "PAYNOW": "Zahlen Sie jetzt"
  },
  "VisitPackageTemplateType": {
    "EPILEPSY": "Epilepsie",
    "CONSULTATION": "Beratung"
  },
  "SexEnumAbbr": {
    "FEMALE": "F",
    "MALE": "M"
  },
  "TherapyTypeEnum": {
    "POLI": "Politherapie",
    "MONO": "Monotherapie",
    "NO_ACTIVE": "Keine aktive Therapie"
  },
  "ComorbidityTypeEnum": {
    "HEADACHES": "Kopfschmerzen",
    "PSYCHIATRIC": "Psychiatrische Erkrankungen",
    "NEUROLOGICAL": "Neurologische Erkrankungen",
    "INFECTIOUS": "Entzündliche und infektiöse Erkrankungen",
    "CANCER": "Krebserkrankungen",
    "IMMUNE": "Blut- und Immunerkrankungen",
    "ENDOCRINE": "Endokrine und metabolische Erkrankungen",
    "EYE": "Augenkrankheiten",
    "LARYNGOLOGICAL": "Laryngologische Erkrankungen",
    "HEART": "Erkrankungen des Herzens und des Kreislaufsystems",
    "RESPIRATORY": "Atemwegserkrankungen",
    "DIGESTIVE": "Erkrankungen des Verdauungssystems",
    "SKIN": "Hautkrankheiten",
    "BONE": "Knochenerkrankungen",
    "UROGENITAL": "Erkrankungen des Urogenitalsystems",
    "DEVELOPMENTAL_DEFECTS": "Entwicklungsstörungen, Geburtsfehler und Chromosomenaberrationen",
    "INJURIES_POISONING": "Verletzungen und Vergiftungen",
    "SOMATIC": "Krankheiten, die die Behandlung von Epilepsie beeinflussen"
  },
  "PatientAccountRemovalReason": {
    "NO_MEDICAL_SERVICE": "Ich habe den Service, der mich interessiert, nicht gefunden",
    "NO_DATES": "Ich habe keinen passenden Besuchstermin gefunden",
    "PRICE": "Die Servicepreise passen nicht zu mir",
    "OTHER": "Andere"
  },
  "PrescriptionRejectedReasonEnum": {
    "VISIT_ONLY": "Ein Rezept für ein ausgewähltes Medikament kann nur während eines Besuchs ausgestellt werden",
    "INQUIRY_REQUIRED": "Ein Rezept für ein ausgewähltes Medikament kann ausgestellt werden, nachdem der aktuelle Gesundheitszustand des Patienten bekannt gegeben wurde",
    "ALREADY_PRESCRIBED": "Das zuvor ausgestellte Rezept sollte den Bedarf des Patienten decken",
    "OTHER": "Andere"
  },
  "BirthTypeEnum": {
    "PHYSIOLOGICAL": "Physiologisch (Naturkräfte)",
    "PLANNED_CESAREAN": "Kaiserschnitt (geplant)",
    "UNPLANNED_CESAREAN": "Kaiserschnitt (ungeplant)",
    "VACUUM": "Kräfte / Vakuum"
  },
  "PaymentRefundRestrictionsEnum": {
    "REFUND_100": "mehr als 7 Tage vor der Dienstleistung, 100% Rückerstattung",
    "REFUND_75": "75% Rückerstattung 6-5 Tage vor der Dienstleistung",
    "REFUND_50": "50% Rückerstattung 4-3 Tage vor dem Service",
    "REFUND_25": "25% Rückerstattung 2 Tage vor der Dienstleistung",
    "REFUND_0": "am Tag vor der Dienstleistung und am Tag der Dienstleistung ohne Rückerstattung"
  },
  "NeuroscreenDiagnosisEnum": {
    "HEADACHES": "Kopfschmerzen",
    "MOVEMENT_ISSUES": "Bewegungsstörungen",
    "MEMORY_ISSUES": "Gedächtnisstörungen",
    "EATING_ISSUES": "Essstörungen",
    "CONSCIOUSNESS_ISSUES": "Bewusstseinsstörungen",
    "PSYCHOMOTOR_DEVELOPMENT_ISSUES": "Psychomotorische Entwicklungsstörungen",
    "EPILEPSY": "Epilepsie und Krampfanfälle",
    "SPINE_ISSUES": "Wirbelsäulenprobleme",
    "MUTIPLE_SCLEROSIS": "Multiple Sklerose",
    "DIZZINESS": "Schwindel",
    "AUTISM": "Autismus-Spektrum",
    "OTHER_SYMPTOMS": "Andere neurologische Symptome"
  },
  "NeuroscreenFrequencyEnum": {
    "ALWAYS": "Ja",
    "SOMETIMES": "Manchmal",
    "RARELY": "Selten",
    "NEVER": "Niemals"
  }
}