import Countries from "../countries/countries";

export default {
    SeizureTypeEnum: {
        IA: "Napad częściowy prosty",
        IB: "Napad częściowy złożony",
        IC: "Napady częściowe rozwijające się w uogólnione",
        IIA: "Napad nieświadomości",
        IIB: "Napad miokloniczny",
        IIC: "Napad toniczny",
        IID: "Napad kloniczny",
        IIE: "Napady toniczno-kloniczne",
        IIF: "Napad atoniczny",
        GEN: "Napady wygenerowane",
        UNK: "Napady nieokreślone"
    },

    SeizureTypeBriefEnum: {
        IA: "Częściowe proste",
        IB: "Częściowe złożone",
        IC: "Częściowe rozwijające się w uogólnione",
        IIA: "Nieświadomości",
        IIB: "Miokloniczne",
        IIC: "Toniczne",
        IID: "Kloniczne",
        IIE: "Toniczno-kloniczne",
        IIF: "Atoniczne",
        GEN: "Wygenerowane",
        UNK: "Nieokreślone"
    },

    MedicalEventTypeEnum: {
        SEIZURE: 'Napad padaczkowy',
        MENSTRUATION: 'Miesiączka',
        INFECTION: 'Infekcja',
        SLEEP_DEPRIVATION: 'Brak snu',
        FEVER: 'Gorączka',
        OTHER: 'Inne',
        PREGNANCY_OR_PRE_PREGNANCY_PERIOD_START: 'Rozpoczęcie ciąży lub okresu starania się o dziecko',
        PREGNANCY_OR_PRE_PREGNANCY_PERIOD_END: 'Zakończenie ciąży lub okresu starania się o dziecko',
        VNS_STIMULATOR_IMPLANTATION: 'Implantacja stymulatora VNS'
    },

    MessageTypeEnum: {
        EMAIL: 'email',
        SMS: 'sms',
        SYS: 'systemowe',
        CHAT: 'chat'
    },

    MessageStatusEnum: {
        NEW: 'nowa',
        SENT: 'wysłana',
        READ: 'przeczytana',
        // ERROR: 'błąd'
    },

    MessageMarkerEnum: {
        MARKED: 'oznaczona',
        NOT_MARKED: 'nieoznaczona'
    },

    MedicalServiceSystemItemEnum: {
        SOS_TELECONFERENCE: 'Telekonferencja SOS',
        SOS_CHAT: 'Czat SOS'
    },

    MedicalServiceGroupEnum: {
        VISIT_0: 'Ankieta 0',
        MIGRATION_VISIT: 'Wizyta migracyjna',
        VISIT_1: 'Wizyta 1',
        STANDARD_VISIT: 'Wizyta regularna',
        MESSAGE: 'Wiadomość',
        PRESCRIPTION: 'Recepta',
        NEUROSURGEON_CONSULTATION: 'Konsultacja - neurochirurg',
        NEUROPSYCHOLOGIST_CONSULTATION: 'Konsultacja - neuropsycholog',
        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
        PSYCHIATRIST_CONSULTATION: 'Konsultacja - psychiatra',
        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
        NEUROLOGIST_CONSULTATION: 'Konsultacja - neurolog',
        DIETICIAN_CONSULTATION: 'Konsultacja - dietetyk',
        VNS_CONSULTATION: 'Wizyta VNS',
        GENETICIST_CONSULTATION: 'Konsultacja - genetyk',
        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
        METABOLIC_CONSULTATION: 'Konsultacja metaboliczna',
        EEG_CONSULTATION: 'Badania EEG/VEEG',
        SLEEP_DISTURBANCE_CONSULTATION: 'Konsultacja zaburzeń snu',
        PARTNER_CONSULTATION: 'Konsultacja u partnera',
        ADHD_CONSULTATION: 'Sesja coachingowa ADHD',
        PSYCHOTHERAPY_CONSULTATION: 'Sesja psychoterapeutyczna'
    },

    MedicalServiceGroupConsultantEnum: {
        NEUROSURGEON_CONSULTATION: 'neurochirurg',
        NEUROPSYCHOLOGIST_CONSULTATION: 'neuropsycholog',
        NEUROLOGOPEDIST_CONSULTATION: 'neurologopeda',
        PSYCHIATRIST_CONSULTATION: 'psychiatra',
        EPILEPTOLOGIST_CONSULTATION: 'epileptolog',
        NEUROLOGIST_CONSULTATION: 'neurolog',
        DIETICIAN_CONSULTATION: 'dietetyk',
        VNS_CONSULTATION: 'konsultant VNS',
        GENETICIST_CONSULTATION: 'genetyk',
        METABOLIC_CONSULTATION: 'konsultant metaboliczny',
        EEG_CONSULTATION: 'konsultant EEG/VEEG',
        SLEEP_DISTURBANCE_CONSULTATION: 'konsultant zaburzeń snu'
    },

    MedicalServiceEnum: {
        VISIT_0: 'Ankieta 0',
        MIGRATION_VISIT: 'Wizyta migracyjna',
        VISIT_1: 'Wizyta 1',
        STANDARD_VISIT: 'Wizyta regularna',
        FOLLOWUP_VISIT: 'Wizyta kontrolna',
        MESSAGE: 'Wiadomość',
        PRESCRIPTION: 'Recepta',
        NEUROSURGEON_CONSULTATION: 'Konsultacja - neurochirurg',
        NEUROPSYCHOLOGIST_CONSULTATION: 'Konsultacja - neuropsycholog',
        NEUROLOGOPEDIST_CONSULTATION: 'Konsultacja - neurologopeda',
        PSYCHIATRIST_CONSULTATION: 'Konsultacja - psychiatra',
        EPILEPTOLOGIST_CONSULTATION: 'Konsultacja - epileptolog',
        NEUROLOGIST_CONSULTATION: 'Konsultacja - neurolog',
        DIETICIAN_CONSULTATION: 'Konsultacja - dietetyk',
        VNS_CONSULTATION: 'Wizyta VNS',
        GENETICIST_CONSULTATION: 'Konsultacja - genetyk',
        ABSENT_CONSULTATION: 'Konsultacja zaoczna',
        METABOLIC_CONSULTATION: 'Konsultacja metaboliczna',
        CASE_CONFERENCE: 'Konsylium medyczne',
        EEG_CONSULTATION: 'Standardowe badanie EEG 30 min.',
        VEEG_ON_STANDBY_CONSULTATION: 'Badanie VEEG w czuwaniu 1-6 godz.',
        VEEG_ON_STANDBY_CONSULTATION_1H: 'Badanie VEEG w czuwaniu 1 godz.',
        VEEG_ON_STANDBY_CONSULTATION_2H: 'Badanie VEEG w czuwaniu 2 godz.',
        VEEG_ON_STANDBY_CONSULTATION_6H: 'Badanie VEEG w czuwaniu 6 godz.',
        VEEG_DURING_SLEEP_CONSULTATION: 'Badanie VEEG we śnie 1-6 godz.',
        VEEG_DURING_SLEEP_CONSULTATION_1H: 'Badanie VEEG we śnie 1 godz.',
        VEEG_DURING_SLEEP_CONSULTATION_2H: 'Badanie VEEG we śnie 2 godz.',
        VEEG_DURING_SLEEP_CONSULTATION_6H: 'Badanie VEEG we śnie 6 godz.',
        VEEG_NIGHT_CONSULTATION: 'Badanie VEEG 12 godz.',
        VEEG_DAY_CONSULTATION: 'Badanie VEEG dobowe 24 godz.',
        SLEEP_DISTURBANCE_CONSULTATION: 'Konsultacja zaburzeń snu',
        ADHD_CONSULTATION: 'Sesja coachingowa ADHD',
        PSYCHOTHERAPY_CONSULTATION: 'Sesja psychoterapeutyczna'
    },

    VisitTypeEnum: {
        STATIONARY: 'Wizyta stacjonarna',
        REMOTE: 'Wizyta online',
        ABSENT: 'Zaoczna'
    },

    VisitTypeDescriptionEnum: {
        STATIONARY: 'Wizyta odbywa się w przychodni pod adresem {place}. Podczas wizyty lekarz spotyka się bezpośrednio z pacjentem. Na miejscu przegląda bieżącą dokumentację pacjenta. W przypadku wystawienia recepty – lekarz wystawia receptę na miejscu i przekazuje za pośrednictwem SMS lub drukuje.',
        REMOTE: 'Wizyta online, podczas której lekarz i pacjent widzą się na ekranie. Połączenie odbywa się przez Neuroterminal i nie wymaga instalacji dodatkowego oprogramowania. Dokumentację pacjent przesyła drogą elektroniczną, przez Neuroterminal. W przypadku konieczności recepty, lekarz wystawi e-receptę.',
        ABSENT: 'Konsultacja zaoczna jest rodzajem wizyty medycznej, którą przeprowadza wybrany specjalista bez obecności pacjenta. Konsultacja zaoczna ma na celu analizę dokumentacji medycznej lub badania dodatkowego. Specjalista Neurosphery dokona analizy dokumentacji, sporządzi stosowną notatkę i zalecenia dla pacjenta.'
    },

    AffinityTypeEnumAdults: {
        LEGAL_GUARDIAN: 'opiekun prawny',
        CURATOR: 'kurator',
        SPOUSE: 'małżonek'
    },

    AffinityTypeEnumChildren: {
        PARENT: 'rodzic',
        ADOPTER: 'przysposabiający',
        GUARDIAN: 'opiekun małoletniego'
    },

    VisitTypeEnumUndefined: 'Inna',

    DocumentTypeEnum: {
        REFERRAL_GENERAL_PURPOSE: 'Skierowanie ogólne',
        REFERRAL_CLINIC: 'Skierowanie do poradni specjalistycznej',
        REFERRAL_TO_HOSPITAL: 'Skierowanie do szpitala',
        REFERRAL_TO_PSYCHIATRIC_HOSPITAL: 'Skierowanie do szpitala psychiatrycznego',
        REFERRAL_TO_SPECIALIST: 'Skierowanie do specjalisty',
        REFERRAL_TO_LAB: 'Skierowanie do laboratorium',
        REFERRAL_TO_DIAGNOSTIC_LABORATORY: 'Skierowanie do pracowni diagnostycznej',
        CERTIFICATE: 'Zaświadczenia',
        CERTIFICATE_FOR_ZUS: 'Zaświadczenie dla ZUS',
        CERTIFICATE_OF_DISABILITY: 'Zaświadczenie do orzeczenia o niepełnosprawności',
        HEALTH_CERTIFICATE: 'Zaświadczenie o stanie zdrowia',
        INFORMATION_FOR_POZ_DOCTOR: 'Informacja dla lekarza POZ',
        APPLICATION_FOR_REFUND: 'Wniosek o refundację',
        APPLICATION_FOR_DRUG_REFUND: 'Wniosek o refundację leku',
        DEMAND_FOR_DRUG_FROM_ABROAD: 'Zapotrzebowanie na lek z zagranicy',
        DEMAND_FOR_FOODSTUFF_FROM_ABROAD: 'Zapotrzebowanie na środek spożywczy z zagranicy',
        OTHER: 'Inne'
    },

    MedicalResultDocumentTypeEnum: {
        HOSPITAL_INFORMATION_CARD: 'Karta informacyjna leczenia szpitalnego',
        DISEASE_HISTORY: 'Historia choroby',
        VIDEO_EVENT: 'Video - zdarzenie',
        LABORATORY_TEST_RESULT: 'Wynik badania laboratoryjnego',
        VISUAL_RESULT_MRI_FILE: 'Plik badania obrazowego - rezonans magnetyczny',
        VISUAL_RESULT_MRI_DESC: 'Opis badania obrazowego - rezonans magnetyczny',
        VISUAL_RESULT_CT_FILE: 'Plik badania obrazowego - tomografia komputerowa',
        VISUAL_RESULT_CT_DESC: 'Opis badania obrazowego - tomografia komputerowa',
        VISUAL_RESULT_EEG_FILE: 'Plik badania EEG',
        VISUAL_RESULT_EEG_DESC: 'Opis badania EEG',
        VISUAL_RESULT_ADDITIONAL_FILE: 'Wynik badania dodatkowego',
        VISUAL_RESULT_ADDITIONAL_DESC: 'Opis badania dodatkowego',
        PATIENT_CERTIFICATE: 'Zaświadczenie Neurosphery',
        STATE_CERTIFICATE: 'Zaświadczenie państwowe',
        EEG_DESCRIPTION: 'Opis EEG',
        OTHER: 'Inne',
    },

    MedicalResultTypeEnum: {
        VIDEO_EVENT: 'Video',
        LABORATORY_TEST_RESULT: 'Badanie laboratoryjne',
        VISUAL_RESULT_MRI_FILE: 'Rezonans magnetyczny',
        VISUAL_RESULT_MRI_DESC: 'Rezonans magnetyczny',
        VISUAL_RESULT_CT_FILE: 'Tomografia komputerowa',
        VISUAL_RESULT_CT_DESC: 'Tomografia komputerowa',
        VISUAL_RESULT_EEG_FILE: 'EEG',
        VISUAL_RESULT_EEG_DESC: 'EEG',
        VISUAL_RESULT_ADDITIONAL_FILE: 'Dodatkowe',
        VISUAL_RESULT_ADDITIONAL_DESC: 'Dodatkowe'
    },

    DoctorTitle: {
        LEK_MED: 'lek. med.',
        DR: 'dr',
        DR_MED: 'dr n. med.',
        DR_HAB: 'dr hab.',
        DR_PROF: 'prof dr hab.',
        TECHN: 'techn.',
        STUDENT: 'student UM',
        MGR: 'mgr',
    },

    PricelistItemGroupEnum: {
        NORMAL_VISIT: 'wizyta normalna',
        SOS_VISIT: 'wizyta SOS',
        DIAGNOSTIC: 'opis badania',
        OTHER: 'inna'
    },

    PricelistItemTypeEnum: {
        FIRST_TIME: 'pierwszorazowa',
        SUBSEQUENT: 'kolejna',
        OTHER: 'inne'
    },

    SexEnum: {
        FEMALE: "Kobieta",
        MALE: "Mężczyzna"
    },

    SexEnumAbbr: {
        FEMALE: "K",
        MALE: "M"
    },

    SeizureFrequencyPeriodEnum: {
        YEAR: 'rok',
        MONTH: 'miesiąc',
        DAY: 'dzień'
    },

    BloodType: {
        TYPE_A: "A",
        TYPE_B: "B",
        TYPE_AB: "AB",
        TYPE_0: "0"
    },

    RhType: {
        RH_PLUS: 'RH+',
        RH_MINUS: 'RH-'
    },

    PsychomotorDevelopmentOverallEnum: {
        NORMAL: 'Prawidłowy',
        RETARDED: 'Opóźniony',
        VERY_RETARDED: 'Znacznie opóźniony',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    PsychomotorRetardationEnum: {
        NO: 'Brak',
        LIGHT: 'Lekkie',
        MODERATE: 'Umiarkowane',
        SIGNIFICANT: 'Znaczne'
    },

    PsychomotorSpeechEnum: {
        NORMAL: 'Prawidłowy',
        DISTURBED: 'Zaburzona',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    PsychomotorWalkEnum: {
        NORMAL: 'Prawidłowy',
        INCORRECT: 'Nieprawidłowy',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    PsychomotorSexEnum: {
        NORMAL: 'Prawidłowy',
        LIBIDO_DISORDERS: 'Zaburzenia popędu',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    NystagmusEnum: {
        NO: 'Brak',
        VESTIBULAR: 'Obecny vestibularny',
        CNS: 'Obecny CNS'
    },

    VisionEnum: {
        NORMAL: 'Prawidłowy',
        DEFECT_PLUS: 'Wada wzroku plus (+)',
        DEFECT_MINUS: 'Wada wzroku minus (-)',
        DEFECT_OTHER: 'Wada wzroku inna',
        BLIND: 'Niewidomy',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    HearingEnum: {
        NORMAL: 'Prawidłowy',
        DISTURBED: 'Zaburzony',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    SmokingEnum: {
        NO: 'Brak',
        YES_IN_PAST: 'Tak, w przeszłości',
        YES_NOW: 'Tak, obecnie',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    AlcoholEnum: {
        NO: 'Brak',
        SOMETIMES_LITTLE: 'Sporadycznie, mało',
        SOMETIMES_A_LOT: 'Sporadycznie, dużo',
        OFTEN_LITTLE: 'Często, mało',
        OFTEN_A_LOT: 'Często, dużo',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    NarcoticsEnum: {
        NO: 'Brak',
        YES_IN_PAST: 'Tak, kiedyś',
        YES_NOW_RARELY: 'Tak, obecnie - okazjonalnie',
        YES_NOW_OFTEN: 'Tak, obecnie - często',
        NOT_APPLICABLE: 'Nie dotyczy'
    },

    SeizuresCharacteristicsFrequencyEnum: {
        ALWAYS: 'Tak (1 napad) / Tak, zawsze (100% napadów)',
        VERY_OFTEN: 'Tak, bardzo często (75 - 99% napadów)',
        OFTEN: 'Tak, często (51 - 74% napadów)',
        SOMETIMES: 'Tak, zdarza się (~50% napadów)',
        RARELY: 'Tak, ale rzadko (25 - 49% napadów)',
        VERY_RARELY: 'Tak, ale wyjątkowo rzadko (1 - 24% napadów)',
        NEVER: 'Nie (1 napad) / Nie, nigdy (0% napadów)',
        NOT_KNOWN: 'Nie wiem'
    },

    DrugHistoryTimeEnum: {
        PAST: 'Tak, w przeszłości',
        CURRENTLY: 'Tak, obecnie'
    },

    DrugHistoryEffectEnum: {
        REMISSION: 'Remisja',
        BIG_IMPROVEMENT: 'Poprawa > 50%',
        LITTLE_IMPROVEMENT: 'Poprawa do 50%',
        NO_CHANGE: 'Bez zmian',
        LITTLE_DETERIORATION: 'Lekkie pogorszenie',
        BIG_DETERIORATION: 'Znaczne pogorszenie',
        EXTREME_DETERIORATION: 'Skrajne pogorszenie / wystąpiły działania niepożądane eliminujące (np. wysypka)'
    },

    SeizuresCharacteristicsDurationEnum: {
        VERY_LONG: 'Bardzo długo, powyżej 5 minut',
        LONG: 'Długo, od 1 do 5 minut',
        BIT_LONG: 'Od 30 do 60 sekund',
        VARIABLE: 'Zupełnie różnie, zmiennie',
        SHORT: 'Krótko od kilku do 30 sekund',
        VERY_SHORT: 'Bardzo krótko, poniżej 5 sek',
        ULTRA_SHORT: 'Ultrakrótko, ułamek sekundy do sekundy',
        NOT_KNOWN: 'Nie wiem'
    },

    UserAccountSettingCodeEnum: {
        VISIT_CANCELED_BY_DOCTOR: 'Powiadomienia o odwołaniu wizyty przez lekarza',
        VISIT_CANCELED_BY_PATIENT: 'Powiadomienia o odwołaniu wizyty przez pacjenta',
        VISIT_CHANGED_BY_PATIENT: 'Powiadomienia o zmianie terminu wizyty przez pacjenta',
        VISIT_APPOINTED_REMINDER: 'Przypomnienia o wizytach umówionych na kolejny dzień',
        VISIT_PLANNED_REMINDER: 'Przypomnienia o wizytach zaplanowanych na kolejny dzień',
        DUTY_PLANNED_REMINDER: 'Przypomnienia o dyżurach zaplanowanych na kolejny dzień',
        ADMIT_PATIENTS: 'Widoczność dla pacjentów w bazie lekarzy',
        MESSAGE_RECEIVED_BY_DOCTOR: 'Powiadomienia o otrzymaniu wiadomości od pacjenta',
        PRESCRIPTION_REQUEST_RECEIVED_BY_DOCTOR: 'Powiadomienia o otrzymaniu prośby o wystawienie recepty'
    },

    VisitCancellationReason: {
        INCONVENIENT_DATE: 'Niedogodny termin',
        DOCTOR_CHANGE: 'Zmiana lekarza'
    },

    DoctorTypeEnum: {
        RESIDENT: 'Rezydent Kliniczny',
        ASSISTANT: 'Asystent',
        SPECIALIST: 'Specjalista Kliniczny',
        EXPERT: 'Partner Medyczny',
        CONSULTANT: 'Konsultant Kliniczny',
        INTERNATIONAL_CONSULTANT: 'Międzynarodowy Konsultant Kliniczny',
        PARTNER_CONSULTANT: 'Konsultant partnera',
    },

    PatientTypeEnum: {
        INFANT: 'dzieci do 2 lat',
        CHILD: 'dzieci od 2 do 12 lat',
        YOUTH: 'młodzież od 12 do 18 lat',
        ADULT: 'dorośli od 18 lat do 65 lat',
        SENIOR: 'seniorzy od 65 lat'
    },

    HasEpilepsyEnum: {
        NO: 'Nie',
        YES: 'Tak',
        NOT_KNOWN: 'Nie wiadomo'
    },

    HealthSurveyPartEnum: {
        PSYCHOMOTOR_DEVELOPMENT: 'rozwój psychoruchowy',
        SEIZURE_TYPES: 'typy napadów',
        SEIZURES_HISTORY: 'historia napadów',
        SEIZURES_CHARACTERISTICS: 'charakterystyka napadów',
        COMORBIDITIES: 'choroby współistniejące',
        DRUGS_HISTORY: 'leczenie',
    },

    TimeRangeEnum: {
        '1M': '1 miesiąc',
        '3M': '3 miesiące',
        '6M': '6 miesięcy',
        '1Y': '1 rok',
        '2Y': '2 lata',
        '3Y': '3 lata',
        'SINCE_FIRST_SEIZURE': 'Od pierwszego napadu',
        'SINCE_BIRTH': 'Od urodzenia',
        '1H': '1 godzina',
        '2H': '2 godziny',
        '3H': '3 godziny',
        '4H': '4 godziny',
        '5H': '5 godzin',
        '6H': '6 godzin'
    },

    ShortTimeRangeEnum: {
        'h': 'godz.',
        'm': 'min.'
    },

    MedicalRequestStatusEnum: {
        'NEW': 'Nowe',
        'ACCEPTED': 'Zaakceptowane',
        'DONE': 'Wykonane',
        'REJECTED': 'Odrzucenie prośby',
        'FORWARDED': 'Odmowa e-recepty',
        'CANCELLED': 'Anulowane'
    },

    PersonalDocumentEnum: {
        'PASSPORT': 'Paszport',
        'DRIVING_LICENSE': 'Prawo jazdy'
    },

    NSPHSourceEnum: {
        'INTERNET_SEARCH': 'Wyszukiwarka internetowa',
        'SOCIAL_MEDIA': 'Social media',
        'INTERNET_ARTICLE': 'Artukuł internetowy',
        'PRESS_ARTICLE': 'Artukuł prasowy',
        'TV': 'Telewizja',
        'FAMILY_FRIENDS': 'Z polecenia rodziny, znajomego',
        'DOCTOR': 'Z polecenia lekarza',
        'OTHER': 'Inne'
    },

    NFZBranchEnum: {
        'NFZ_01': '01 - Dolnośląski Oddział Narodowego Funduszu Zdrowia we Wrocławiu',
        'NFZ_02': '02 - Kujawsko-Pomorski Oddział Narodowego Funduszu Zdrowia w Bydgoszczy',
        'NFZ_03': '03 - Lubelski Oddział Narodowego Funduszu Zdrowia w Lublinie',
        'NFZ_04': '04 - Lubuski Oddział Narodowego Funduszu Zdrowia w Zielonej Górze',
        'NFZ_05': '05 - Łódzki Oddział Narodowego Funduszu Zdrowia w Łodzi',
        'NFZ_06': '06 - Małopolski Oddział Narodowego Funduszu Zdrowia w Krakowie',
        'NFZ_07': '07 - Mazowiecki Oddział Narodowego Funduszu Zdrowia w Warszawie',
        'NFZ_08': '08 - Opolski Oddział Narodowego Funduszu Zdrowia w Opolu',
        'NFZ_09': '09 - Podkarpacki Oddział Narodowego Funduszu Zdrowia w Rzeszowie',
        'NFZ_10': '10 - Podlaski Oddział Narodowego Funduszu Zdrowia w Białymstoku',
        'NFZ_11': '11 - Pomorski Oddział Narodowego Funduszu Zdrowia w Gdańsku',
        'NFZ_12': '12 - Śląski Oddział Narodowego Funduszu Zdrowia w Katowicach',
        'NFZ_13': '13 - Świętokrzyski Oddział Narodowego Funduszu Zdrowia w Kielcach',
        'NFZ_14': '14 - Warmińsko-Mazurski Oddział Narodowego Funduszu Zdrowia w Olsztynie',
        'NFZ_15': '15 - Wielkopolski Oddział Narodowego Funduszu Zdrowia w Poznaniu',
        'NFZ_16': '16 - Zachodniopomorski Oddział Narodowego Funduszu Zdrowia w Szczecinie'
    },

    VisitPackageEnum: {
        START_PACKAGE: "Ankieta 0 + Wizyta 1",
        ADHD_PACKAGE: "Pakiet ADHD",
        REGULAR_VISIT_EEG_PACKAGE: "Wizyta regularna + badanie EEG",
        MMPI2_PACKAGE: "Test osobowości MMPI-2",
        CBT_PACKAGE: "Terapia CBT"
    },

    CountryEnum: Countries.pl,

    WellBeingEnum: {
        WORST: "maksymalnie gorzej/źle",
        MUCH_WORSE: "znacznie gorzej",
        BIT_WORSE: "nieco gorzej",
        SAME: "tak samo",
        BIT_BETTER: "nieco lepiej",
        MUCH_BETTER: "znacznie lepiej",
        IDEAL: "idealnie, pełne zdrowie"
    },
    CurrentTreatmentEnum: {
        WORST: "złe maksymalnie, nie działa i nie chcę go kontynuować",
        VERY_BAD: "nie jest dobre, przeszkadza mi, wolę zmienić",
        BAD: "nie czuję się za dobrze, ale mogę kontynuować",
        NO_CHANGE: "nie czuję żadnej zmiany",
        BIT_BETTER: "jest OK, jest trochę lepiej na tym leczeniu",
        MUCH_BETTER: "jest wyraźna poprawa, leczenie wyraźnie pomaga",
        IDEAL: "leczenie jest idealne, pełny sukces"
    },
    SideEffectsEnum: {
        WORST: "są na tyle poważne, że z tego powodu nie chcę go na pewno kontynuować",
        BAD: "są znaczne i mi przeszkadzają, ale jeśli trzeba mogę kontynuować",
        BEARABLE: "są nieco odczuwalne, ale nie na tyle żeby myśleć o zmianie",
        MINIMAL: "jest OK, objawy niepożądane minimalne, bez wpływu na mnie, prawie ich nie ma",
        NO_TREATMENT: "brak leczenia (leków)"
    },
    TreatmentEfficiencyEnum: {
        IDEAL: "idealna",
        GOOD: "dobra",
        MEDIOCRE: "średnia",
        BAD: "słaba",
        WORST: "zła, trzeba zmienić",
        NO_TREATMENT: "brak leczenia (leków)"
    },
    TreatmentSafetyEnum: {
        TOTAL: "Zupełne",
        ACCEPTABLE: "Niezłe",
        WEAK: "Słabe",
        BAD: "Złe",
        WORST: "Wyklucza lek, konieczna zmiana"
    },
    AdditionalIncidentsEnum: {
        NEW_DISEASES: "Nowe choroby",
        INJURIES: "Urazy",
        HOSPITALIZATIONS: "Hospitalizacje",
        OTHER: "Inne",
        NONE: "Brak"
    },
    SeizureCountEnum: {
        MORE: "więcej",
        LESS: "mniej",
        SAME: "tyle samo"
    },
    SeizureEvaluationEnum: {
        WORSE: "cięższe",
        BETTER: "łagodniejsze",
        SAME: "takie same"
    },
    AllSeizureRegisteredEnum: {
        TRUE: "Wprowadziłem i są one wszystkie wpisane",
        FALSE: "Nie wprowadziłam/em - teraz podam ilość napadów"
    },
    ExternalReferralTypeEnum: {
        CLINIC: "do poradni specjalistycznej",
        LABORATORY: "do pracowni diagnostycznej",
        HOSPITAL: "na leczenie szpitalne"
    },
    CertificateTypeEnum: {
        DRUGS_INTAKE: "Zaświadczenie do POZ o przyjmowanych lekach",
        POSTPONE_VACCINATION: "Zaświadczenie o odroczeniu szczepień",
        NO_CONTRAINDICATION_FOR_VACCINE: "Zaświadczenie o braku przeciwwskazań do szczepień",
        NO_CONTRAINDICATION_FOR_PROCEDURE: "Zaświadczenie o braku przeciwwskazań do zabiegu",
        NO_CONTRAINDICATION_FOR_ACTIVITIES: "Zaświadczenie o braku przeciwwskazań do uczestnictwa w zajęciach",
        NO_CONTRAINDICATION_FOR_WORK: "Zaświadczenie o braku przeciwwskazań do wykonywania pracy fizycznej",
        OTHER: "Inne"
    },
    CertificateTypeDescriptionEnum: {
        DRUGS_INTAKE: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Proszę o zapisywanie leków w miejscu zamieszkania. Pacjent przyjmuje następujące leki",
        POSTPONE_VACCINATION: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Proszę o odroczenie szczepień do",
        NO_CONTRAINDICATION_FOR_VACCINE: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Nie ma przeciwskazań do obowiązkowych szczepień",
        NO_CONTRAINDICATION_FOR_PROCEDURE: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Nie ma przeciwskazań do znieczulenia ogólnego w związku z planowanym zabiegiem",
        NO_CONTRAINDICATION_FOR_ACTIVITIES: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Nie ma przeciwskazań do uczestnictwa w zajęciach",
        NO_CONTRAINDICATION_FOR_WORK: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera. Nie ma przeciwskazań do wykonywania pracy fizycznej.",
        OTHER: "Niniejszym zaświadczam, że pacjent jest pod opieką Centrum Neurologii, Padaczki i Psychiatrii Neurosphera."
    },
    StateCertificateTypeEnum: {
        DRIVING: "Zaświadczenie na prawo jazdy",
        HEALTH_STATE: "ZUS OL-9",
        DISABILITY: "Zaświadczenie lekarskie o stanie zdrowia"
    },

    MessageVisitSummaryStatusEnum: {
        NEW: "Niezrealizowana",
        PENDING: "Oczekuje na odpowiedź",
        RESPONDED: "Odpowiedź wysłana",
        DONE: "Zakończona",
        UNIDIRECTIONAL: "Jednokierunkowa"
    },

    SickLeaveTypeEnum: {
        DISEASE: "Choroba",
        CARE: "Opieka nad chorym"
    },

    SickLeaveIndicationEnum: {
        SHOULD_LIE: "Powinien leżeć",
        CAN_WALK: "Może chodzić"
    },

    SickLeaveInsuredInEnum: {
        ZUS: "ZUS",
        KRUS: "KRUS",
        OTHER_PL: "inne w Polsce",
        OTHER_COUNTRY: "w innym państwie"
    },

    SickLeaveStatusEnum: {
        ISSUED: "Wystawione",
        CANCELED: "Anulowane"
    },

    SurveyCompletedEnum: {
        YES: "Tak",
        NO: "Nie",
        PARTIALLY: "W połowie"
    },

    EegCompletedEnum: {
        YES: "Tak",
        NO: "Nie",
        BEFORE_NEXT: "Wykona przed wizytą 1"
    },

    DocplannerIssueTypeEnum: {
        DP_VISIT_NOT_BOOKED: "Umówienie wizyty w ZN",
        DP_VISIT_NOT_CANCELED: "Anulowanie wizyty w ZN",
        DP_VISIT_NOT_MOVED: "Zmiana terminu wizyty w ZN",
        DP_NEW_BOOKING_FAILED: "Nowa wizyta umówiona w ZN",
        DP_MOVE_BOOKING_FAILED: "Wizyta przeniesiona w ZN",
        DP_CANCEL_BOOKING_FAILED: "Wizyta anulowana w ZN",
        NOT_ELIGIBLE_FOR_REGULAR_VISIT: "Nowy pacjent - wizyta padaczkowa",
        NOT_ELIGIBLE_FOR_CONSULTATION: "Nowy pacjent - konsultacja",
        DP_SLOT_NOT_CANCELED: "Odwołanie terminu w znanym lekarzu"
    },

    DocplannerVisitStatusEnum: {
        NEW: "Nowa",
        CANCELED_BY_SCHEDULER: "Nieopłacona",
        CANCELED_BY_PERSON: "Odwołana",
        SOLVED: "Załatwiona",
        PAID_NOT_DONE: "Opłacona",
        DONE: "Wykonana"
    },

    ReferralStatusEnum: {
        NEW: "Nowe",
        CANCELLED: "Anulowane",
        DONE: "Zrealizowane"
    },

    VisitCaseSolvedEnum: {
        APPOINTMENT: "Wizyta umówiona",
        RESIGNATION: "Rezygnacja",
        OTHER: "Inny"
    },

    VisitPaymentMarkingEnum: {
        WAITING_FOR_PAYMENT: "Oznaczona do opłaty",
        PAYMENT_RECEIVED: "Oznaczona jako opłacona"
    },

    PaymentProviderEnum: {
        PAYU: 'PayU',
        PAYNOW: 'PayNow'
    },

    VisitPackageTemplateType: {
        EPILEPSY: 'Padaczkowy',
        CONSULTATION: 'Konsultacyjny'
    },

    EegEpilepsyRecommendationEnum: {
        ROUTINE_CHECKUP: "badanie kontrolne",
        SUSPECTED_EPILEPSY: "kliniczne podejrzenie padaczki lub napadu padaczkowego",
        EPILEPSY_VERIFICATION: "weryfikacja rozpoznania padaczki",
        EPILEPSY_CLASIFICATION: "klasyfikacja pacjenta z rozpoznaną padaczką",
        SEIZURE_PATTER_CHANGE: "zmiana wzorca napadów",
        NON_CONVULSIONS_EPILEPSY: "podejrzenie nie drgawkowego stanu padaczkowego",
        EPILEPSY_MONITORING: "monitorowanie stanu padaczkowego",
        SEIZURE_FREQUENCY_MONITORING: "monitorowanie częstości napadów",
        DRUGS_MONITORING: "monitorowanie działania leków",
        LPP_THERAPY_STOP: "rozważenie odstawiania terapii LPP",
        PRE_SURGERY: "ocena przedoperacyjna"
    },

    EegDifferentialDiagnosisEnum: {
        PSYCHOGENIC_SEIZURE: "podejrzenie psychogennych napadów nie padaczkowych",
        CONSCIOUSNESS_DISTURBANCES: "zaburzenia świadomości",
        ENCEPHALOPATHY: "encefalopatia",
        ENCEPHALITIS: "zapalenie mózgu",
        DEMENTIA: "demencja",
        TIA: "TIA",
        BEHAVIOR_CHANGE: "napadowe zmiany zachowania",
        OTHER: "inne objawy psychiatryczne lub behawioralne"
    },

    EegPediatricRecommendationEnum: {
        GENETIC_SYNDROME: "zespół genetyczny",
        METABOLIC_DISORDER: "zaburzenia metaboliczne",
        REGRESSION: "regresja rozwoju/ problemy rozwojowe"
    },

    EegTypeEnum: {
        STANDARD: "standardowe EEG",
        SHORT: "krótkoterminowe monitorowanie wideo-EEG",
        LONG: "długoterminowe monitorowanie wideo-EEG"
    },

    EegExaminationStateEnum: {
        AWAKEN: "Czuwanie",
        SLEEPINESS: "Senność",
        SLEEP: "Sen",
        AWAKENING: "Faza budzenia"
    },

    EegActivationMethodsEnum: {
        NONE: "Brak",
        PHOTOSTIMULATION: "Fotostymulacja",
        HYPERVENTILATION: "Hiperwentylacja",
        SLEEP: "Sen",
        SLEEP_DEPRIVATION: "Deprywacja snu",
        EYE_OPENING: "Ręczne otwieranie/zamykanie oczu",
        DRUGS: "Leki podawane podczas EEG",
        NO_DRUGS: "Odstawienie lub ograniczenie leków podczas EEG",
    },

    EegSymmetryEnum: {
        SYMMETRIC: "symetryczna",
        SLOWER_RIGHT: "wolniejsza po prawej",
        SLOWER_LEFT: "wolniejsza po lewej"
    },

    EegAmplitudeEnum: {
        LOW_VOLTAGE: "zapis niskonapięciowy (<20mV)",
        MEDIUM_VOLTAGE: "zapis średnionapięciowy (20–70 mV)",
        HIGH_VOLTAGE: "zapis wysokonapięciowy (>70 mV)"
    },

    EegAmplitudeSymmetryEnum: {
        SYMMETRIC: "symetryczna",
        LP: "L>P",
        PL: "P>L"
    },

    EegReactivityEnum: {
        PRESENT: "obecna",
        ABSENT: "nieobecna"
    },

    EegRhythmOrganizationEnum: {
        CORRECT: "prawidłowo zorganizowany",
        WEAKLY_ORGANIZED: "słabo zorganizowany",
        DISORGANIZED: "zdezorganizowany"
    },

    EegSymmetryPresentEnum: {
        ARTIFACTS: "artefakty",
        LOW_VOLTAGE_RECORD: "zapis niskonapięciowy",
        NO_CLOSED_EYES: "nie można było osiągnąć zamknięcia oczu",
        NO_WAKEFULNESS: "brak okresu czuwania"
    },

    EegBaseActivityMeaningEnum: {
        CORRECT_ACTIVITY: "prawidłowa czynność podstawowa",
        NO_IRREGULARITY: "bez ewidentnych nieprawidłowości",
        INCORRECT_ACTIVITY: "nieprawidłowa czynność podstawowa"
    },

    EegArtifactsEnum: {
        NONE: "nieobecne",
        MUSCLE: "mięśniowe",
        BLINKING: "mruganie",
        NYSTAGMUS: "oczopląs",
        EYE_MOVEMENT: "ruchy gałek ocznych pionowe/poziome",
        MOTOR: "ruchowe",
        CHEW: "żucia/ssania",
        GLOSSOKINETIC: "glossokinetyczny",
        ROCKING: "kołysania/ poklepywania",
        PULSE: "puls",
        EKG: "EKG",
        SWEAT: "pot",
        OTHER: "inny"
    },

    EegArtifactsMeaningEnum: {
        NOT_OBSTRUCTIVE: "nie utrudnia zapisu",
        EVALUATION_IMPOSSIBLE: "zapis nie do oceny",
        LIMITED_VALUE: "zapis o ograniczonej wartości"
    },

    EegNonBiologicalArtifactsEnum: {
        FREQUENCY: "50/60 Hz",
        ELECTRODE_POP: "electrode pop",
        SALT_BRIDGE: "mostek solny",
        OTHER: "inny",
        NONE: "nieobecne"
    },

    EegPhysiologicalVariantsEnum: {
        NONE: "nieobecne",
        SLOW_ALPHA_RHYTHM: "wolny wariant rytmu alfa",
        FAST_ALPHA_RHYTHM: "szybki wariant rytmu alfa",
        LAMBDA_WAVES: "fale lambda",
        YOUTH_WAVES: "fale młodzieńcze (posterior slow waves of youth)",
        HYPERVENTILATION: "fizjologiczna reakcja na hiperwentylacje",
        FS_RHYTHM: "wodzenie rytmów w czasie FS",
        PHOTOMYOGENIC_RESPONSE: "odpowiedź fotomiogenna",
        MICRORHYTHM: "rytm μ",
        OTHER: "inne"
    },

    EegMildVariantsEnum: {
        NONE: "nieobecne",
        GATED_SPIRES: "Iglice bramkowane",
        GRAPH_ELEMENTS: "chwilowe łagodne padaczkopodobne grafoelementy snu (BETS)/małe ostre iglice (SSS)",
        THETA_WAVES: "rytmiczne skroniowe fale theta/ Wariant psychomotoryczny (RMTD – rhythmic midtemporal theta of drowsiness)",
        CENTRAL_THETA: "rytm Ciganka (pośrodkowa centralna  theta)",
        SPIRE: "iglica - fala wolna o częstotliwości 6 Hz (WHAM/FOLD)",
        POSITIVE_SPIRES: "dodatnie iglice o częstotliwości 14 Hz i 6 Hz",
        EEG_DISCHARGE: "subkliniczne rytmiczne wyładowania EEG u dorosłych (SREDA)",
        ELDERLY_SLOWDOWN: "skroniowe zwolnienie u osób w podeszłym wieku",
        BREACH_RHYTHM: "rytm wyłomu (breach rhythm)",
        OTHER: "inne"
    },

    EegSleepEnum: {
        NONE: "nie zarejestrowano snu",
        REGISTERED_PHASES: "zarejestrowane fazy",
        SLEEP_SPINDLES: "wrzeciona snu",
        SHARP_WAVES: "ostre fale wierzchołkowe",
        K_COMPLEX: "kompleksy K",
        SAW_WAVES: "fale piłokształtne",
        POST: "odatnie ostre grafoelementy przelotne snu w okolicy potylicznej (POST)",
        SOREMP: "SOREMP"
    },

    EegSleepPhasesEnum: {
        N1: "N1",
        N2: "N2",
        N3: "N3",
        REM: "REM"
    },

    EegSleepMeaningEnum: {
        CORRECT: "prawidłowy",
        NO_IRREGULARITY: "bez ewidentnych nieprawidłowości",
        WRONG: "nieprawidłowy"
    },

    EegInterictalActivityEnum: {
        NONE: "nieobecna",
        SPIRE: "iglica",
        SPIRE_ASSEMBLY: "zespół iglica - fala wolna",
        MULTI_SPIRE: "wieloiglice",
        MULTI_SPIRE_ASSEMBLY: "zespół wieloiglica - fala wolna",
        SHARP_WAVE: "fala ostra",
        SHARP_WAVE_ASSEMBLY: "zespół fala ostra - fala wolna",
        OSCILLATIONS: "oscylacje o wysokiej częstotliwości (HFO)",
        HYPSARRHYTHMIA: "hipsarytmia",
        OTHER: "inne"
    },

    EegLateralizationEnum: {
        RIGHT_SIDE: "prawostronna",
        LEFT_SIDE: "lewostronna",
        BOTH_SIDE: "obustronna"
    },

    EegBrainRegionEnum: {
        FRONTAL: "czołowa",
        TEMPORAL: "skroniowa",
        CENTRAL: "centralna ",
        PARIETAL: "ciemieniowa",
        OCCIPITAL: "potyliczna",
        GENERAL: "uogólnione",
    },

    EegElectrodesEnum: {
        FP1: "Fp1",
        F3: "F3",
        C3: "C3",
        P3: "P3",
        F7: "F7",
        T7: "T7",
        P7: "P7",
        O1: "O1",
        F9: "F9",
        T9: "T9",
        P9: "P9",
        FZ: "Fz",
        CZ: "Cz",
        PZ: "Pz",
        FP2: "Fp2",
        F4: "F4",
        C4: "C4",
        P4: "P4",
        F8: "F8",
        T8: "T8",
        P8: "P8",
        O2: "O2",
        F10: "F10",
        T10: "T10",
        P10: "P10"
    },

    EegIncorrectInterictalActivityEnum: {
        NONE: "nieobecna",
        UNFOCUSED_ACTIVITY: "rozlana czynność wolna",
        FOCUSED_ACTIVITY: "ogniskowa czynność wolna",
        DELTA_ACTIVITY: "czynność delta",
        THETA_ACTIVITY: "czynność theta",
        ALPHA_ACTIVITY: "czynność alfa",
        BETA_ACTIVITY: "czynność beta",
        GAMMA_ACTIVITY: "czynność gamma",
        POLYMORPHIC_ACTIVITY: "polimorficzna delta",
        FRONTAL_DELTA: "czołowa rytmiczna przerywana czynność delta (FIRDA)",
        OCCIPITAL_DELTA: "potyliczna rytmiczna przerywana czynność delta (OIRDA)",
        TEMPORAL_DELTA: "skroniowa rytmiczna przerywana czynność delta (TIRDA)",
        OTHER: "inne"
    },

    EegSpecialPatternEnum: {
        NONE: "nieobecne",
        PERIODICAL_DISCHARGE: "okresowe wyładowania (PD)",
        GENERAL_DISCHARGE: "uogólnione okresowe wyładowania (GPD)",
        SEIZURE_LIKE_DISCHARGE: "okresowe zlateralizowane wyładowania padaczkopodobne (LPD/PLED)",
        INDEPENDENT_DISCHARGE: "obustronne niezależne okresowe wyładowania  (BIPD)",
        MULTIFOCAL_DISCHARGE: "wieloogniskowe wyładowania okresowe  (MfPD)",
        EXTREME_DELTA: "ekstremalna szczotka delta",
        BURST_SUPPRESSION: "burst suppression",
        BURST_ATTENUATION: "burst attenuation",
        OTHER: "inne"
    },

    EegSeizureChangesEnum: {
        ARTIFACTS: "artefakty uniemożliwiające ocenę",
        LOW_VOLTAGE_ACTIVITY: "niskonapięciowa szybka czynność",
        RHYTHMIC_ACTIVITY: "rytmiczna czynność",
        SLOW_WAVES: "wolne fale o dużej amplitudzie",
        IRREGULAR_ACTIVITY: "nieregularna aktywność delta lub theta",
        SPIRES: "iglice",
        MULTI_SPIRE: "wieloiglice",
        SHARP_WAVES: "fale ostre",
        SPIRE_ASSEMBLY: "zespoły iglica - fala wolna",
        MULTI_SPIRE_ASSEMBLY: "zespół wieloiglica - fala wolna",
        BURST_SUPPRESSION: "burst - suppression",
        ELECTRO_DECREMENT: "elektrodekrement",
        DC_SHIFT: "DC-shift",
        OSCILLATIONS: "oscylacje o wysokiej częstotliwości (HFO)",
        ACTIVITY_DISAPPEARANCE: "zanik bieżącej czynności",
        OTHER_PATTERN: "inny wzór napadowego EEG"
    },

    EegStartSeizureEnum: {
        WAKEFUL: "w czuwaniu",
        SLEEPING: "we śnie"
    },

    EegTimeCorrelationEnum: {
        CLINICAL_BEGINNING: "początek kliniczny, po którym zmiany w eeg",
        EEG_BEGINNING: "początek eeg, po którym początek kliniczny",
        SIMULTANEOUS: "jednoczesny"
    },

    EegSensorySemiologyEnum: {
        VISUAL: "wzrokowa",
        AUDITORY: "słuchowa",
        OLFACTORY: "węchowa",
        TASTE: "smakowa",
        SOMATOSENSORY: "somatosensoryczna",
        DEPERSONALIZATION: "depersonalizacja",
        VESTIBULAR: "przedsionkowa",
        COMPULSIVE_THINKING: "przymusowe myślenie",
        BODY_PERCEPTION_ILLUSIONS: "iluzje percepcji ciała",
        OTHER_SENSORY: "inne"
    },

    EegEmotionalSemiologyEnum: {
        ANXIETY: "niepokój",
        FEAR: "lęk",
        ECSTASY: "ekstaza",
        SADNESS: "smutek",
        ANGER: "gniew"
    },

    EegCognitiveSemiologyEnum: {
        APHASIA: "afazja",
        AMNESIA: "amnezja",
        DEJA_VU: "déjà vu/jamais vu"
    },

    EegMotorSemiologyEnum: {
        AKINETIC: "akinetyczny",
        ATONIC: "atoniczny",
        DYSTONIC: "dystoniczny",
        NYSTAGMUS: "oczopląs",
        BLINKING: "mruganie oczami",
        FLEXION: "zgięciowy",
        VERSE: "wersyjny",
        MYOCLONIC: "miokloniczny",
        CLONIC: "kloniczny",
        JACKSON_MARCH: "marsz jacksonowski",
        TONIC: "toniczny",
        TONIC_CLONIC: "toniczno-kloniczny",
        OTHER_MOTOR: "inny"
    },

    EegAutomatismSemiologyEnum: {
        OROALIMENTARY: "oroalimentarne",
        VERBAL: "werbalne",
        DACRIST: "dakrystyczne",
        GELASTIC: "gelastyczne",
        MANUAL: "manualne",
        GESTURE: "gestowe (dystalne/ proksymalne/ genitalne)",
        OTHER_AUTOMATISM: "inne"
    },

    EegAutonomicSemiologyEnum: {
        BRADYCARDIA: "bradykardia/asystolia",
        TACHYCARDIA: "tachykardia",
        SKIN_REDDENING: "zaczerwienienie skóry",
        SKIN_PALENESS: "zblędnięcie skóry",
        PILOERECTION: "piloerekcja",
        EPIGASTRIC: "epigastryczna",
        SPITTING: "plucie",
        VOMITING: "wymiotowanie",
        APNEA: "bezdech",
        URINARY_INCONTINENCE: "nietrzymanie moczu",
        OTHER_AUTONOMIC: "inne"
    },

    EegPostSeizureSemiologyEnum: {
        NONE: "brak  widocznych  objawów  klinicznych",
        UNCONSCIOUS: "nieprzytomny",
        CONSCIOUSNESS_REGAIN: "szybkie odzyskanie przytomności",
        TONGUE_BITE: "przygryzienie języka",
        APHASIA: "afazja lub dysfazja",
        PSYCHOSIS: "psychoza/pobudzenie",
        BLINDNESS: "hemianopsja/ślepota",
        NOSE_WIPE: "wycieranie nosa",
        HEADACHE: "ból głowy",
        AMNESIA: "amnezja",
        ONE_SIDED: "jednostronne klonie",
        PARESIS: "niedowład(porażenie todda)",
        SLEEP: "sen ponapadowy",
        OTHER: "inne"
    },

    EegLateralizingSymptomEnum: {
        NONE: "nie obserwowano",
        EARLY_DIZZINESS: "wczesny zwrot głowy",
        DYSTONIA: "dystonia w trakcie napadu",
        ONE_SIDED_AUTOMATISM: "jednostronne automatyzmy w trakcie napadu",
        ONE_SIDED_BLINKING: "jednostronne mruganie w trakcie napadu",
        APHASIA: "ponapadowa afazja",
        HEMIANOPSIA: "ponapadowa hemianopsja",
        NEGLECT: "ponapadowe zaniedbywanie",
        PARESIS: "ponapadowy niedowład",
        NOSE_WIPE: "ponapadowe wycieranie nosa",
        FENCER_FIGURE: "figura szermierza",
        OTHER: "inne"
    },

    EegIncorrectRecordEnum: {
        EPILEPSY: "Padaczka",
        PNES: "PNES",
        CLINICAL_EPISODE: "Inny niepadaczkowy epizod kliniczny",
        FOCUSED_DYSFUNCTION: "Ogniskowa dysfunkcja ośrodkowego układu nerwowego",
        UNFOCUSED_DYSFUNCTION: "Rozlana dysfunkcja ośrodkowego układu nerwowego",
        EPILEPSY_STATE: "Stan padaczkowy",
        CSWS: "Zespół ciągłego zapisu iglica-fala podczas wolnofalowego snu (CSWS)",
        ESES: "Elektryczny stan padaczkowy we śnie  (ESES)",
        COMA: "Śpiączka",
        BRAIN_DEATH: "Śmierć mózgu"
    },

    EegConsciousEpisodeEnum: {
      KEPT: "zachowana",
      DISTURBED: "zaburzona",
      NOT_EXAMINED: "niebadana"
    },

    EegParoxysmalEnum: {
        NONE: "brak widocznej manifestacji",
        SENSORY: "czuciowa",
        EMOTIONAL: "emocjonalna",
        COGNITIVE: "kognitywna",
        SIMPLE_MOTOR: "prosta ruchowa",
        AUTOMATISM: "automatyzmy",
        HYPERMOTOR: "hipermotoryczna",
        AUTONOMIC: "autonomiczna",
        OTHER: "inne"
    },

    TherapyTypeEnum: {
        POLI: "Politerapia",
        MONO: "Monoterapia",
        NO_ACTIVE: 'Brak aktualnej terapii'
    },
//
    ComorbidityTypeEnum: {
        HEADACHES: "Bóle głowy",
        PSYCHIATRIC: "Choroby psychiatryczne",
        NEUROLOGICAL: "Choroby neurologiczne",
        INFECTIOUS: "Choroby zapalne i zakaźne",
        CANCER: "Choroby nowotworowe",
        IMMUNE: "Choroby krwi i immunologiczne",
        ENDOCRINE: "Choroby endokrynologiczne i metaboliczne",
        EYE: "Choroby oka",
        LARYNGOLOGICAL: "Choroby laryngologiczne",
        HEART: "Choroby serca i układu krążenia",
        RESPIRATORY: "Choroby układu oddechowego",
        DIGESTIVE: "Choroby układu pokarmowego",
        SKIN: "Choroby skóry",
        BONE: "Choroby kości i tkanki łącznej",
        UROGENITAL: "Choroby moczowo-płciowe",
        DEVELOPMENTAL_DEFECTS: "Wady rozwojowe, wrodzone i abberacje chromosomowe",
        INJURIES_POISONING: "Urazy i zatrucia",
        SOMATIC: "Choroby wpływające na proces leczenia padaczki"
    },

    PatientAccountRemovalReason: {
        NO_MEDICAL_SERVICE: 'Nie znalazłem usługi, która mnie interesuje',
        NO_DATES: 'Nie odpowiadają mi terminy',
        PRICE: 'Nie odpowiada mi cena usługi',
        OTHER: 'Inne'
    },

    PrescriptionRejectedReasonEnum: {
        VISIT_ONLY: "Recepta na wybrany lek może zostać wystawiona tylko na wizycie",
        INQUIRY_REQUIRED: "Recepta na wybrany lek może zostać wystawiona po zapoznaniu się z aktualnym stanem zdrowia pacjenta",
        ALREADY_PRESCRIBED: "Poprzednio wystawiona recepta powinna spełniać oczekiwania pacjenta",
        OTHER: "Inne"
    },

    BirthTypeEnum: {
        PHYSIOLOGICAL: "Fizjoogiczny (siłami natury)",
        PLANNED_CESAREAN: "Cesarskie cięcie (planowane)",
        UNPLANNED_CESAREAN: "Cesarskie cięcie (nieplanowane)",
        VACUUM: "Kleszcze / vacuum"
    },

    PaymentRefundRestrictionsEnum: {
        REFUND_100: "powyżej 7 dni przed usługą zwrot 100% kwoty",
        REFUND_75: "6-5 dni przed usługą zwrot 75% kwoty",
        REFUND_50: "4-3 dni przed usługą zwrot 50% kwoty",
        REFUND_25: "2 dni przed usługą zwrot 25% kwoty",
        REFUND_0: "dzień przed usługą i w dzień usługi bez zwrotu"
    },

    NeuroscreenDiagnosisEnum: {
        HEADACHES: "Bóle głowy",
        MOVEMENT_ISSUES: "Zaburzenia ruchowe",
        MEMORY_ISSUES: "Zaburzenia pamięci",
        EATING_ISSUES: "Zaburzenia odżywiania",
        CONSCIOUSNESS_ISSUES: "Zaburzenia świadomości lub przytomności",
        PSYCHOMOTOR_DEVELOPMENT_ISSUES: "Zaburzenia rozwoju psychoruchowego",
        EPILEPSY: "Padaczka i napady",
        SPINE_ISSUES: "Problemy z kręgosłupem",
        MUTIPLE_SCLEROSIS: "Stwardnienie rozsiane",
        DIZZINESS: "Zawroty głowy",
        AUTISM: "Spektrum autyzmu",
        OTHER_SYMPTOMS: "Pozostałe objawy neurologiczne"
    },

    NeuroscreenFrequencyEnum: {
        ALWAYS: "Tak",
        SOMETIMES: "Czasem",
        RARELY: "Rzadko",
        NEVER: "Nigdy"
    }
}
